import { createBrowserRouter } from "react-router-dom";
import CustomerSupportScreen from "../views/pages/auth-pages/customer-support";
import GetNewPasswordScreen from "../views/pages/auth-pages/forgot-password/get-new";
import SetNewPasswordScreen from "../views/pages/auth-pages/forgot-password/set-new";
import SignInScreen from "../views/pages/auth-pages/signin";
import SignUpScreen from "../views/pages/auth-pages/signup";
import VerifyEmailScreen from "../views/pages/auth-pages/verification-email/verify-email";
import VerifyPasswordResetScreen from "../views/pages/auth-pages/verification-email/verify-password-reset";
import TermsAndConditionsPage from "../views/pages/auth-pages/customer-support/terms-conditions";
import { AuthUser } from "../middleware/auth-middleware";
import OraganisationOwner from "../views/app/dashboard/business-owner/organisation";
import OrganisationAdvisor from "../views/app/dashboard/business-advisor/organisation";
import LookerDashboardPage from "../views/app/looker";
import AppIntegrationScreen from "../views/app/app-integrations";
import Cin7AppPage from "../views/app/app-integrations/cin-7";
import XeroAppPage from "../views/app/app-integrations/xero";
import SettingsPage from "../views/app/settings";
import ReportsPage from "../views/app/reports";
import ErrorPage from "../views/pages/utilis-pages/404-page";
import XeroSuccessPage from "../views/app/app-integrations/xero-success";
import Cin7SuccessPage from "../views/app/app-integrations/cin7-success";
import Layout from "../layout/sidebar-layout";
// import LocalDashboard from "../views/app/looker/Xero";
import ConsolidateOrganisationsOwner from "../views/app/dashboard/business-owner/consolidated-organisation";
import XeroDisconnectPage from "../views/app/app-integrations/disconnect-xero";
import DashboardHomePage from "../views/app/looker/KPIs/index";
import DashboardProfitAndLossPage from "../views/app/looker/KPIs/profit-and-loss";
import DashboardCashFlowPage from "../views/app/looker/KPIs/cash-flow";
import DashboardRevenuePage from "../views/app/looker/KPIs/revenue";
import DashboardBalanceSheetPage from "../views/app/looker/KPIs/balance-sheet";
import DashboardInventoryPage from "../views/app/looker/KPIs/inventory";
import KPIDetailsPage from "../views/app/looker/kpi-visualisations/kpi-details-page";
import DearAppPage from "../views/app/app-integrations/dear";
import DearSuccessPage from "../views/app/app-integrations/dear-success";
import DashboardPurchasingPage from "../views/app/looker/KPIs/purchasing";
import DashboardValidationPage from "../views/app/looker/KPIs/validation";
import DashboardAIBetaPage from "../views/app/looker/ai-beta/ai-beta";
import DashboardWorkflowBetaPage from "../views/app/looker/workflows/workflow-beta";

const router = createBrowserRouter([
  {
    path: "/",
    element: <SignUpScreen></SignUpScreen>,
  },
  {
    path: "/signin",
    element: <SignInScreen></SignInScreen>,
  },
  {
    path: "/get-new-password",
    element: <GetNewPasswordScreen></GetNewPasswordScreen>,
  },
  {
    path: "/password-reset/:encoded_pk/:token/",
    element: <SetNewPasswordScreen></SetNewPasswordScreen>,
  },
  {
    path: "/terms-conditions",
    element: <TermsAndConditionsPage></TermsAndConditionsPage>,
  },
  {
    path: "/verify-email",
    element: <VerifyEmailScreen></VerifyEmailScreen>,
  },
  {
    path: "/verify-password-reset",
    element: <VerifyPasswordResetScreen></VerifyPasswordResetScreen>,
  },
  {
    path: "/customer-support",
    element: <CustomerSupportScreen></CustomerSupportScreen>,
  },
  {
    path: "/owner/organisation/single",
    element: (
      <AuthUser>
        <Layout>
          <OraganisationOwner />
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/owner/organisation/consolidation",
    element: (
      <AuthUser>
        <Layout>
          <ConsolidateOrganisationsOwner></ConsolidateOrganisationsOwner>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/organisation/advisor",
    element: (
      <AuthUser>
        <Layout></Layout>
        <OrganisationAdvisor></OrganisationAdvisor>
      </AuthUser>
    ),
  },
  {
    path: "/dashboard/main",
    element: (
      <AuthUser>
        <Layout>
          <LookerDashboardPage></LookerDashboardPage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/dashboard/home",
    element: (
      <AuthUser>
        <Layout>
          <DashboardHomePage></DashboardHomePage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/dashboard/profit-and-loss/",
    element: (
      <AuthUser>
        <Layout>
          <DashboardProfitAndLossPage></DashboardProfitAndLossPage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/dashboard/cash/",
    element: (
      <AuthUser>
        <Layout>
          <DashboardCashFlowPage></DashboardCashFlowPage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/dashboard/revenue-and-costs/",
    element: (
      <AuthUser>
        <Layout>
          <DashboardRevenuePage></DashboardRevenuePage>
        </Layout>
      </AuthUser>
    ),
  },
  
  {
    path: "/dashboard/balance-sheet/",
    element: (
      <AuthUser>
        <Layout>
<DashboardBalanceSheetPage></DashboardBalanceSheetPage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/dashboard/inventory/",
    element: (
      <AuthUser>
        <Layout>
          <DashboardInventoryPage></DashboardInventoryPage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/dashboard/purchasing/",
    element: (
      <AuthUser>
        <Layout>
          <DashboardPurchasingPage></DashboardPurchasingPage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/dashboard/validation/",
    element: (
      <AuthUser>
        <Layout>
          <DashboardValidationPage></DashboardValidationPage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/dashboard/ai-beta/",
    element: (
      <AuthUser>
        <Layout>
          <DashboardAIBetaPage></DashboardAIBetaPage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/dashboard/workflow-beta/",
    element: (
      <AuthUser>
        <Layout>
          <DashboardWorkflowBetaPage></DashboardWorkflowBetaPage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/details/:kpiName",
    element: (
      <AuthUser>
        <Layout>
          <KPIDetailsPage></KPIDetailsPage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/connected-apps/apps/",
    element: (
      <AuthUser>
        <Layout>
          <AppIntegrationScreen></AppIntegrationScreen>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/connected-apps/apps/disconnect/xero/:slug",
    element: (
      <AuthUser>
        <Layout>
          <XeroDisconnectPage />
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/reports/",
    element: (
      <AuthUser>
        <Layout>
          <ReportsPage></ReportsPage>
        </Layout>
      </AuthUser>
    ),
  },

  {
    path: "/connected-apps/apps/cin-7/",
    element: (
      <AuthUser>
        <Layout>
          <Cin7AppPage></Cin7AppPage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/connected-apps/apps/xero/",
    element: (
      <AuthUser>
        <Layout>
          <XeroAppPage></XeroAppPage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/connected-apps/apps/dear/",
    element: (
      <AuthUser>
        <Layout>
          <DearAppPage></DearAppPage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/owner/settings/",
    element: (
      <AuthUser>
        <Layout>
          <SettingsPage></SettingsPage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/connected-apps/apps/xero/success",
    element: 
          <XeroSuccessPage></XeroSuccessPage>
  },
  {
    path: "/connected-apps/apps/dear/success",
    element: (
      <AuthUser>
        <Layout>
          <DearSuccessPage></DearSuccessPage>
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "/connected-apps/apps/cin7/success",
    element: (
      <AuthUser>
        <Layout>
          <Cin7SuccessPage />
        </Layout>
      </AuthUser>
    ),
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
  // {
  //   path: "/dashboard/test",
  //   element: (
  //     <Layout>
  //       <LocalDashboard></LocalDashboard>
  //     </Layout>
  //   ),
  // },
]);
export default router