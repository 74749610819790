import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import FilterDropdown from "../../filters/date-selection-table-filter";
import LateSuppliersTable from "../../tables/kpis-tables/dear/top-table-kpis/late-suppliers";
import CustomerWithoutTagsTable from "../../tables/kpis-tables/dear/general-table-kpis/customer-without-tags";
import TopTenSuppliersTable from "../../tables/kpis-tables/dear/top-table-kpis/top-ten-suppliers";
import TotalBankTable from "../../tables/kpis-tables/xero/total-bank-table";
import countryToSymbolMap from "../../../../../constant/country-code-currency.json";
import Tippy from "@tippyjs/react"; // Default import
import { IoIosInformationCircle } from "react-icons/io";
import dear from "../../../../../assets/logos/cin7-core-teal-1.png";
import ProductsWithZeroSalesTable from "../../tables/kpis-tables/dear/top-table-kpis/product-with-zero-sales";
import ProductWithoutTagsTable from "../../tables/kpis-tables/dear/general-table-kpis/product-without-tags";
import { Switch } from "../../../../../shadcn-components/ui/switch";
const BoxTableCard01 = ({
  title,
  tableData,
  isLoading,
  tooltip,
  isWidthFull,
  isWidthThird,
  isDateFilters,
  isSwitch,
  handleSwitchToggle,
  isChecked,
  switchTitle,
}) => {
  const [selectedFilter, setSelectedFilter] = useState("Last Month");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { organisation } = useSelector((state) => state.organisation);
  const countryCode = organisation?.country_alpha3?.replace(/"/g, "") || null;
  const currencySymbol = countryToSymbolMap[countryCode];
  const getFilterValue = (row) => {
    switch (selectedFilter) {
      case "This Month":
        return row.this_month;
      case "Last Month":
        return row.last_month;
      case "This Quarter":
        return row.this_quarter;
      case "Last Quarter":
        return row.last_quarter;
      case "This Year":
        return row.this_year;
      case "Last Year":
        return row.last_year;
      default:
        return 0;
    }
  };
  const transformedData = Array.isArray(tableData)
    ? tableData.filter(
      (row) =>
        row.date_selection ===
        selectedFilter.toLowerCase().replace(/\s+/g, "_")
    )
    : [];
  const transformedDataBank = Array.isArray(tableData)
    ? tableData.map((row) => ({
      account: row.account,
      totalValue: getFilterValue(row),
    }))
    : [];
  const handleFilterChange = (value) => {
    setSelectedFilter(value);
    setIsDropdownOpen(false);
  };
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  const options = [
    { label: "This Week", value: "This Week" },
    { label: "Last Week", value: "Last Week" },
    { label: "This Month", value: "This Month" },
    { label: "Last Month", value: "Last Month" },
    { label: "This Quarter", value: "This Quarter" },
    { label: "Last Quarter", value: "Last Quarter" },
    { label: "This Year", value: "This Year" },
    { label: "Last Year", value: "Last Year" },
    { label: "Last 365 Days", value: "Last 365 Days" },
  ];
  return (
    <div
      className={`relative ${isWidthFull ? "w-full" : isWidthThird ? "w-1/3" : "w-1/2"
        } border-[0.5px] rounded-lg p-4 bg-white shadow-sm border-zinc-200 mt-5`}
    >
      <div className="flex flex-row justify-between items-center mb-4 gap-2">
        <div className="font-bold flex items-center justify-center gap-1">
          <div className="rounded-full flex items-center justify-center h-9 w-9 bg-slate-200">
            <Tippy
              theme="custom-white"
              size="small"
              placement="top"
              content="Dear"
            >
              <span>
                <img
                  className="rounded-full object-contain h-[26px] w-[26px]"
                  src={dear}
                />
              </span>
            </Tippy>{" "}
          </div>{" "}
          {title}{" "}
          <Tippy
            theme="custom-white"
            size="small"
            placement="top"
            content={tooltip}
          >
            <span>
              <IoIosInformationCircle className="text-grey" size={14} />
            </span>
          </Tippy>
        </div>
        {isDateFilters ? (
          <div className="flex flex-row gap-2 justify-center items-center">
            <div className="relative text-left">
              <button
                type="button"
                onClick={toggleDropdown}
                className="inline-flex items-center justify-between text-[12px] font-regular w-40 rounded-md border border-gray-300 bg-white px-3 py-1 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {selectedFilter || "All"}
                <svg
                  className={`-mr-1 ml-2 h-5 w-5 text-gray-400 ${isDropdownOpen ? "transform rotate-180" : ""
                    }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {isDropdownOpen && (
                <div
                  ref={dropdownRef}
                  className="absolute right-0 z-20 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-auto"
                >
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    {options.map((option) => (
                      <label
                        key={option.value}
                        className="block px-3 py-1 text-[12px] text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      >
                        <input
                          type="radio"
                          className="form-radio text-purple h-4 w-4 mr-2"
                          name="dateFilter"
                          value={option.value}
                          checked={selectedFilter === option.value}
                          onChange={() => setSelectedFilter(option.value)}
                        />
                        {option.label}
                      </label>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {title === "Top 10 Late Suppliers" && (
        <LateSuppliersTable
          isLoading={isLoading}
          data={transformedData}
          currencySymbol={currencySymbol}
        />
      )}
      {title === "Customers Without Tags" && (
        <CustomerWithoutTagsTable
          isLoading={isLoading}
          data={tableData}
          currencySymbol={currencySymbol}
        />
      )}
      {title === "Products Without Tags" && (
        <ProductWithoutTagsTable
          isLoading={isLoading}
          data={tableData}
          currencySymbol={currencySymbol}
        />
      )}
      {title === "Top 10 Suppliers By Cost" && (
        <TopTenSuppliersTable
          isLoading={isLoading}
          data={transformedData}
          currencySymbol={currencySymbol}
        />
      )}
      {title === "Top 10 Suppliers By Volume (Based on Invoice)" && (
        <TopTenSuppliersTable
          isLoading={isLoading}
          title={title}
          data={transformedData}
        />
      )}
      {title === "Top 10 Suppliers By Volume (Based on Shipment)" && (
        <TopTenSuppliersTable
          isLoading={isLoading}
          title={title}
          data={transformedData}
        />
      )}
      {title === "Bank Table" && (
        <TotalBankTable
          isLoading={isLoading}
          title={title}
          data={transformedDataBank}
          currencySymbol={currencySymbol}
        />
      )}
      {title === "Top 10 Carriers By Sales Orders" && (
        <TopTenSuppliersTable
          isLoading={isLoading}
          title={title}
          noDecimal={true}
          data={transformedData}
        />
      )}
      {title === "Products With 0 Sales" && (
        <ProductsWithZeroSalesTable
          isLoading={isLoading}
          data={transformedData}
          currencySymbol={currencySymbol}
        />
      )}
      {
        isSwitch &&(
          <div className="flex items-center gap-2 mt-5">
          <Switch checked={isChecked} onCheckedChange={handleSwitchToggle} />
            <span className="text-gray-500">Swtich to {switchTitle}</span>
        </div>
        )
      }
    </div>
    
  );
};
export default BoxTableCard01;