import React, { useState } from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import { IoIosInformationCircle } from "react-icons/io";
import countryToSymbolMap from "../../../../../../constant/country-code-currency.json";
import xero from "../../../../../../assets/logos/xero_logo.png";
import SpinnerKPI from "../../../utilis/spinner-kpi";
import { BsFillFilterSquareFill } from "react-icons/bs";
import { Expand } from "lucide-react";
import ProductBySalesByMonthModal from "../../../modals/kpis-modals/product-by-sales-by-month-modal";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger, DropdownMenuCheckboxItem } from "../../../../../../shadcn-components/ui/dropdown-menu";
import { ArrowUpDown } from "lucide-react";
import { ColumnFilterMenu } from "../dear/drill-down-1/data-table/column-filters";
import FilterDropdown from "../../../filters/date-selection-table-filter";
import BalanceSheetModal from "../../../modals/kpis-modals/balance-sheet-modal";

// Define expected sections with their order
const expectedSections = [
    { name: "Fixed Assets", order: 1 },
    { name: "Total Fixed Assets", order: 2 },
    { name: "Banks", order: 3 },
    { name: "Total Bank", order: 4 },
    { name: "Current Assets", order: 5 },
    { name: "Total Current Assets", order: 6 },
    { name: "Total Assets", order: 7 },
    { name: "Current Liabilities", order: 8 },
    { name: "Total Current Liabilities", order: 9 },
    { name: "Non-Current Liabilities", order: 10 },
    { name: "Total Non-Current Liabilities", order: 11 },
    { name: "Total Liabilities", order: 12 },
    { name: "Net Assets", order: 13 },
    { name: "Equity", order: 14 },
    { name: "Total Equity", order: 15 },
];

const BalanceSheetTable = ({ title, tableData, isLoading, tooltip, isWidthFull, opened }) => {
    const { organisation } = useSelector((state) => state.organisation);
    const organisationName = organisation?.name;
    const [selectedFilter, setSelectedFilter] = useState("Last Month");

    const countryCode = organisation?.country_alpha3?.replace(/"/g, "");
    const currencySymbol = countryToSymbolMap[countryCode] || "";
    const [isAdvancedDearFilters, setIsAdvancedDearFilters] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(opened);
    const [expandedSections, setExpandedSections] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // State to manage column visibility
    const [columns, setColumns] = useState([
        { key: "account", label: "", visible: true, isFirstColumn: true },
        { key: "current_period", label: "Current Period", visible: true },
        { key: "previous_period", label: "Previous Period", visible: true },
        { key: "previous_year", label: "Previous Year", visible: true },
    ]);

    const handleAdvanceDearFilters = () => {
        setIsAdvancedDearFilters(!isAdvancedDearFilters);
    };

    const handleModalToggle = () => {
        setIsModalOpen(!isModalOpen);
    };

    const toggleColumnVisibility = (key) => {
        if (key !== "account") {
            setColumns((prevColumns) =>
                prevColumns.map((col) =>
                    col.key === key ? { ...col, visible: !col.visible } : col
                )
            );
        }
    };

    const handleFilterChange = (value) => {
        setSelectedFilter(value);
        setIsDropdownOpen(false);
    };

    const toggleSectionVisibility = (section) => {
        setExpandedSections((prev) =>
            prev.includes(section)
                ? prev.filter((sec) => sec !== section)
                : [...prev, section]
        );
    };

    const filteredData = tableData
        ? tableData
            .filter((item) => item.date_selection === selectedFilter.toLowerCase().replace(/\s+/g, "_"))
            .map((item) => ({
                ...item,
                accounts: item.accounts.map(account => ({
                    ...account,
                    current_period: account.current_period.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    previous_period: account.previous_period.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    previous_year: account.previous_year.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                })),
            }))
        : [];
    // Transform the tableData to match expected sections
    const transformedData = expectedSections.map(({ name, order }) => {
        const sectionData = filteredData?.find(section => section.section === name);
        return {
            section: sectionData ? sectionData.section : name,
            accounts: sectionData ? sectionData.accounts : [],
            order,
        };
    }).sort((a, b) => a.order - b.order);

    return (
        <>
            <div className={`relative w-full border-[0.5px] rounded-lg p-4 bg-white shadow-sm border-zinc-200 mt-5`}>
                <div className="flex flex-row justify-between items-center mb-4 gap-2">
                    {!isExpanded ? (
                        <div className="font-bold flex items-center justify-center gap-1">
                            <div className="rounded-full flex items-center justify-center h-9 w-9 bg-slate-200">
                                <Tippy
                                    theme="custom-white"
                                    size="small"
                                    placement="top"
                                    content="Xero"
                                >
                                    <span>
                                        <img
                                            className="rounded-full object-contain h-full w-full"
                                            src={xero}
                                        />
                                    </span>
                                </Tippy>
                            </div>
                            <div className="flex gap-1">
                                <span>{title}</span>
                                <Tippy theme="custom-white" size="small" placement="top" content={tooltip}>
                                    <div className="mt-1.5">
                                        <IoIosInformationCircle className="text-grey" size={14} />
                                    </div>
                                </Tippy>
                            </div>

                        </div>
                    ) : null}

                    <div className={`flex gap-3 items-center ${isExpanded ? 'w-full justify-end' : ''}`}>
                        {!isExpanded ? (
                            <button onClick={handleModalToggle} className="text-sm text-gray-500">
                                <Expand size={20} />
                            </button>
                        ) : null}
                        <ColumnFilterMenu
                            columns={columns.filter(col => col.key !== "account")}
                            setColumns={setColumns}
                        />
                        <FilterDropdown
                            selectedFilter={selectedFilter}
                            onFilterChange={handleFilterChange}
                        />
                        {/* <div className="cursor-pointer" onClick={handleAdvanceDearFilters}>
                            <BsFillFilterSquareFill className="text-purple" size={26} />
                        </div> */}
                    </div>
                </div>

                {/* Horizontal and Vertical Scroll for Overflowing Table */}
                <div className={`overflow-auto ${isExpanded ? "max-h-[650px]" : "max-h-[500px]"} rounded-md border border-gray-300`}>
                    {isLoading ? (
                        <SpinnerKPI />
                    ) : (
                        <table className="w-full border-separate border-spacing-0 table-auto">
                            <thead className="bg-gray-200 sticky top-0">
                                <tr>
                                    {columns.map((col, columnIndex) =>
                                        col.visible && (
                                            <th
                                                key={col.key}
                                                className={`border border-gray-300 text-sm px-2 py-2 whitespace-nowrap ${columnIndex === 0 ? "border-b-0" : ""}`} // Remove bottom border for the first column
                                            >
                                                {col.isFirstColumn ? (
                                                    <span className="flex items-center justify-center">{col.label}</span>
                                                ) : (
                                                    <DropdownMenu>
                                                        <DropdownMenuTrigger asChild>
                                                            <span className="cursor-pointer flex items-center justify-center gap-1">
                                                                {col.label}
                                                                <ArrowUpDown className="text-purple flex-shrink-0" size={14} />
                                                            </span>
                                                        </DropdownMenuTrigger>
                                                        <DropdownMenuContent>
                                                            <DropdownMenuCheckboxItem
                                                                checked={col.visible}
                                                                onCheckedChange={() => toggleColumnVisibility(col.key)}
                                                            >
                                                                {col.visible ? "Show" : "Hide"} Column
                                                            </DropdownMenuCheckboxItem>
                                                        </DropdownMenuContent>
                                                    </DropdownMenu>
                                                )}
                                            </th>
                                        )
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {transformedData.map(({ section, accounts }, sectionIndex) => {
                                    // Check if the section has accounts (data)
                                    const hasAccounts = accounts.length > 0;

                                    // Condition to check if the section is a single row and the row name matches the section name
                                    const isSingleRowSection =
                                        hasAccounts && accounts.length === 1 && accounts[0].account === section;

                                    return (
                                        <React.Fragment key={sectionIndex}>
                                            {/* Section Row */}
                                            <tr className="cursor-pointer">
                                                {/* If it's a single-row section and name matches, render the data directly in the section row */}
                                                <td
                                                    colSpan={1} // Section name spans the first column
                                                    className={`border border-gray-300 text-sm px-4 py-2 ${
                                                        section.startsWith("Total") && section !== "Total Assets" && section !== "Total Liabilities"
                                                            ? "pl-8 font-normal"
                                                            : "font-bold"
                                                    }`}
                                                    onClick={() => hasAccounts && toggleSectionVisibility(section)} // Only toggle if there are accounts
                                                >
                                                    {isSingleRowSection ? (
                                                        <>{section}</>
                                                    ) : (
                                                        <div className="flex justify-between items-center">
                                                            <span>
                                                                {section}
                                                            </span>
                                                            {hasAccounts && (
                                                                <span className={`ml-2 font-semibold text-[16px]`}>
                                                                    {expandedSections.includes(section) ? '-' : '+'}
                                                                </span>
                                                            )}
                                                        </div>
                                                    )}
                                                </td>

                                                {/* Render data in the same row for single-row sections where name matches */}
                                                {isSingleRowSection
                                                    ? columns
                                                        .filter((col) => col.key !== "account" && col.visible) // Skip 'account' column and respect visibility
                                                        .map((col) => (
                                                            <td
                                                                key={col.key}
                                                                className="border border-gray-300 text-sm px-4 py-2"
                                                            >
                                                                {accounts[0][col.key] !== undefined
                                                                    ? `${currencySymbol} ${accounts[0][col.key].toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
                                                                    : "-"}
                                                            </td>
                                                        ))
                                                    : // For expandable sections, render empty cells after the section name
                                                    columns
                                                        .filter((col) => col.key !== "account" && col.visible) // Skip 'account' column and respect visibility
                                                        .map((col) => (
                                                            <td
                                                                key={col.key}
                                                                className="border border-gray-300 text-sm px-2 py-2"
                                                            >
                                                                {/* Empty cells for expandable sections */}
                                                            </td>
                                                        ))}
                                            </tr>

                                            {/* Account Rows for expandable sections */}
                                            {!isSingleRowSection && hasAccounts && expandedSections.includes(section) &&
                                                accounts.map((account, accountIndex) => (
                                                    <tr key={accountIndex} className="hover:bg-gray-100">
                                                        <td className="border border-gray-300 text-sm px-2 py-2 whitespace-nowrap pl-10">
                                                            {account.account}
                                                        </td>
                                                        {columns
                                                            .filter((col) => col.key !== "account" && col.visible) // Skip 'account' column and respect visibility
                                                            .map((col) => (
                                                                <td
                                                                    key={col.key}
                                                                    className="border border-gray-300 text-sm px-4 py-2 whitespace-nowrap"
                                                                >
                                                                    {account[col.key] !== undefined
                                                                        ? `${currencySymbol} ${account[col.key].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                                                        : "-"}
                                                                </td>
                                                            ))}
                                                    </tr>
                                                ))}
                                        </React.Fragment>
                                    );
                                })}
                            </tbody>


                        </table>
                    )}
                </div>
            </div >

            {/* KPIDrillDownModal to show expanded view */}
            < BalanceSheetModal
                toggle={isModalOpen}
                toggleModal={handleModalToggle}
                tableData={tableData}
                drilldownTitle={title}
                drilldownTooltip={tooltip}
                drilldownCurrencySymbol={currencySymbol}
            />
        </>
    );
};

export default BalanceSheetTable;
