import React, { useEffect, Suspense, lazy, useState } from "react";
import { IoIosClose, IoIosInformationCircle } from "react-icons/io";
import Spinner2 from "../../utilis/spinner-2";
import { Expand } from "lucide-react";
import Tippy from "@tippyjs/react";
import xero from "../../../../../assets/logos/xero_logo.png";
// Lazy-loaded table components (no changes here)
const ProfitAndLossKPITable = lazy(() =>
    import(
        "../../tables/kpis-tables/xero/profit-and-loss-table.js"
    )
);
function ProfitAndLossModal(props) {
    const {
        drilldownTitle,
        drilldownTooltip,
        toggle,
        toggleModal,
        tableData,
        currencySymbol
    } = props;
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        toggleModal();
    };

    useEffect(() => {
        if (toggle) {
            document.body.style.overflow = "hidden";

            setTimeout(() => {
                setIsLoading(false);
            }, 100);
        } else {
            document.body.style.overflow = "";
            setIsLoading(true);
        }

        return () => {
            document.body.style.overflow = "";
            setIsLoading(true);
        };
    }, [toggle]);

    return (
        <div className="z-100">
            {toggle && (
                <div className="fixed z-30 inset-0 overflow-hidden no-scrollbar">
                    <div className="flex items-center justify-center bg-black bg-opacity-50 min-h-screen p-8">
                        <div
                            className={`bg-white rounded-lg py-2 px-5 overflow-hidden no-scrollbar 
                                     w-full h-full min-h-[850px] max-h-[1000px]`}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="flex flex-row mb-4 justify-between items-start">
                                <h2 className="text-lg font-bold mt-4 flex gap-1 items-center">
                                    <div className="rounded-full flex items-center justify-center h-9 w-9 bg-slate-200">
                                        <Tippy
                                            theme="custom-white"
                                            size="small"
                                            placement="top"
                                            content="Xero"
                                        >
                                            <span>
                                                <img
                                                    className="rounded-full object-contain h-full w-full"
                                                    src={xero}
                                                />
                                            </span>
                                        </Tippy>
                                    </div>
                                    {drilldownTitle}
                                    <Tippy
                                        theme="custom-white"
                                        size="small"
                                        placement="top"
                                        content={drilldownTooltip}
                                    >
                                        <div>
                                            <IoIosInformationCircle
                                                className="  text-grey"
                                                size={14}
                                            />
                                        </div>
                                    </Tippy>
                                    {/* <span className="font-medium text-purple">{title}</span> */}
                                </h2>
                                <div className="flex mt-4 items-center gap-2">
                                    <button
                                        onClick={handleClose}
                                        className="text-sm text-gray-500"
                                    >
                                        <Expand size={16} />
                                    </button>
                                    <IoIosClose
                                        onClick={handleClose}
                                        className="cursor-pointer"
                                        size={35}
                                    />
                                </div>
                            </div>

                            {isLoading ? (
                                <Spinner2 />
                            ) : (
                                <Suspense fallback={<Spinner2 />}>
                                    {tableData ? (
                                        // Fallback or default customer drill-down table
                                        <ProfitAndLossKPITable
                                            title={drilldownTitle}
                                            tableData={tableData}
                                            tooltip={drilldownTooltip}
                                            isWidthFull={true}
                                            opened={true}
                                        />
                                    ) : (
                                        // Fallback for when no conditions match
                                        <div>No data available for the selected criteria.</div>
                                    )}
                                </Suspense>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProfitAndLossModal;
