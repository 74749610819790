import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { BsFillFilterSquareFill } from "react-icons/bs";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import KPIDrillDownModal from "../../ui-elements/modals/kpis-modals/drill-down-modal";
import { selectOrganisation } from "../../../../redux-features/slices/organisation/orgSlice";
import MultiSelect from "../../ui-elements/utilis/multi-select";
import { Toggle } from "../../../../shadcn-components/ui/toggle";

import {
  getDearProductSalesLineGraphFilteredData,
  getDearProfitLineGraphFilteredData,
  getDearUnitsSoldLineGraphFilteredData,
  getDearUnitsShippedLineGraphFilteredData,
  updateAppliedAdvanceFilters,
} from "../../../../redux-features/slices/kpi/kpiSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const monthMap = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec'
};

function LineChartDear(props) {
  const { currencySymbol, companyData, company, title } = props;
  const [isAdvancedDearFilters, setIsAdvancedDearFilters] = useState(false)
  const { user } = useSelector((state) => state.auth);
  const token = user?.data?.api_token;

  const dispatch = useDispatch();
  const { organisation } = useSelector((state) => state.organisation);
  const {
    dearBrandFilters,
    dearCategoryFilters,
    dearContactFilters,
    dearCustomerTagsFilters,
    dearProductFilters,
    dearProductTagsFilters,
    dearProductTypeFilters,
    dearLocationFilters,
    dearAdvanceFilters
  } = useSelector((state) => state.kpi);

  const brandFilterOptions = Array.isArray(dearBrandFilters)
    ? dearBrandFilters.map((filter) => ({
      label: filter?.brand
        ? filter.brand
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.brand || "",
    }))
    : [];

  const contactFilterOptions = Array.isArray(dearContactFilters)
    ? dearContactFilters.map((filter) => ({
      label: filter?.contact_name
        ? filter.contact_name
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.contact_name || "",
    }))
    : [];

  const categoryFilterOptions = Array.isArray(dearCategoryFilters)
    ? dearCategoryFilters.map((filter) => ({
      label: filter?.category
        ? filter.category
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.category || "",
    }))
    : [];

  const customerTagsFilterOptions = Array.isArray(dearCustomerTagsFilters)
    ? dearCustomerTagsFilters.map((filter) => ({
      label: filter?.customer_tag
        ? filter.customer_tag
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.customer_tag || "",
    }))
    : [];

  const productFilterOptions = Array.isArray(dearProductFilters)
    ? dearProductFilters.map((filter) => ({
      label: filter?.product_name
        ? filter.product_name
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.product_name || "",
    }))
    : [];

  const productTagsFilterOptions = Array.isArray(dearProductTagsFilters)
    ? dearProductTagsFilters.map((filter) => ({
      label: filter?.product_tag
        ? filter.product_tag
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.product_tag || "",
    }))
    : [];

  const productTypeFilterOptions = Array.isArray(dearProductTypeFilters)
    ? dearProductTypeFilters.map((filter) => ({
      label: filter?.product_type
        ? filter.product_type
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.product_type || "",
    }))
    : [];
  const locationFilterOptions = Array.isArray(dearLocationFilters)
    ? dearLocationFilters.map((filter) => ({
      label: filter?.location
        ? filter.location
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.location || "",
    }))
    : [];
  const [brandFilterSelected, setBrandFilterSelected] = useState(null);
  const [categoryFilterSelected, setCategoryFilterSelected] = useState(null);
  const [contactFilterSelected, setContactFilterSelected] = useState(null);
  const [customerTagsFilterSelected, setCustomerTagsFilterSelected] = useState(null);
  const [productFilterSelected, setProductFilterSelected] = useState(null);
  const [productTagsFilterSelected, setProductTagsFilterSelected] = useState(null);
  const [productTypeFilterSelected, setProductTypeFilterSelected] = useState(null);
  const [locationFilterSelected, setLocationFilterSelected] = useState(null);


  const setDefaultFilterValues = (kpiType) => {
    switch (kpiType) {
      case 'Product Sales of':
        setBrandFilterSelected(dearAdvanceFilters.productSalesLineGraph?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.productSalesLineGraph?.category || []);
        setContactFilterSelected(dearAdvanceFilters.productSalesLineGraph?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.productSalesLineGraph?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.productSalesLineGraph?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.productSalesLineGraph?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.productSalesLineGraph?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.productSalesLineGraph?.location || []);
        break;

      case 'Profit Line Graph of ':
        setBrandFilterSelected(dearAdvanceFilters.grossProfitLineGraph?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.grossProfitLineGraph?.category || []);
        setContactFilterSelected(dearAdvanceFilters.grossProfitLineGraph?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.grossProfitLineGraph?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.grossProfitLineGraph?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.grossProfitLineGraph?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.grossProfitLineGraph?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.grossProfitLineGraph?.location || []);
        break;

      case 'Units Sold by ':
        setBrandFilterSelected(dearAdvanceFilters.unitsSoldLineGraph?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.unitsSoldLineGraph?.category || []);
        setContactFilterSelected(dearAdvanceFilters.unitsSoldLineGraph?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.unitsSoldLineGraph?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.unitsSoldLineGraph?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.unitsSoldLineGraph?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.unitsSoldLineGraph?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.unitsSoldLineGraph?.location || []);
        break;

      case 'Units Shipped by ':
        setBrandFilterSelected(dearAdvanceFilters.unitsShippedLineGraph?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.unitsShippedLineGraph?.category || []);
        setContactFilterSelected(dearAdvanceFilters.unitsShippedLineGraph?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.unitsShippedLineGraph?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.unitsShippedLineGraph?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.unitsShippedLineGraph?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.unitsShippedLineGraph?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.unitsShippedLineGraph?.location || []);
        break;
        setBrandFilterSelected(dearAdvanceFilters.incomingStock?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.incomingStock?.category || []);
        setContactFilterSelected(dearAdvanceFilters.incomingStock?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.incomingStock?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.incomingStock?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.incomingStock?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.incomingStock?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.incomingStock?.location || []);
        break;
      default:
        // Default case resets filters
        setBrandFilterSelected([]);
        setCategoryFilterSelected([]);
        setContactFilterSelected([]);
        setCustomerTagsFilterSelected([]);
        setProductFilterSelected([]);
        setProductTagsFilterSelected([]);
        setProductTypeFilterSelected([]);
        setLocationFilterSelected([]);
        break;
    }
  };
  useEffect(() => {
    setDefaultFilterValues(title);
  }, [title, dearAdvanceFilters]);


  const [showMore, setShowMore] = useState(false);

  // Combine all filters and add colorClass based on type
  const combinedFilters = [
    ...(brandFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-orange-200 text-orange-600' })),
    ...(categoryFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-neutral-200 text-neutral-600' })),
    ...(contactFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-emerald-100 text-emerald-500' })),
    ...(customerTagsFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-emerald-200 text-emerald-600' })),
    ...(productFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-sky-100 text-sky-500' })),
    ...(productTagsFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-sky-200 text-sky-600' })),
    ...(productTypeFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-violet-200 text-violet-600' })),
    ...(locationFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-[#ff3b7b] bg-opacity-80 text-white' })),
  ];

  // Determine which filters to show based on the showMore state
  const filtersToShow = showMore ? combinedFilters : combinedFilters.slice(0, 5);

  // Toggle function for show more/less
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };



  const handleContactChange = (selected) => {
    setContactFilterSelected(selected);
  };

  const handleCustomerTagsChange = (selected) => {
    setCustomerTagsFilterSelected(selected);
  };
  const handleBrandChange = (selected) => {
    setBrandFilterSelected(selected);
  };
  const handleProductChange = (selected) => {
    setProductFilterSelected(selected);
  };

  const handleProductTagsChange = (selected) => {
    setProductTagsFilterSelected(selected);
  };

  const handleProductTypeChange = (selected) => {
    setProductTypeFilterSelected(selected);
  };

  const handleCategoryChange = (selected) => {
    setCategoryFilterSelected(selected);
  };
  const handleLocationChange = (selected) => {
    setLocationFilterSelected(selected);
  };

  const saveSelectedFilters = async () => {
    const selectedBrands =
      brandFilterSelected?.map((selected) => selected.value) || [];
    const selectedCategories =
      categoryFilterSelected?.map((selected) => selected.value) || [];
    const selectedContacts =
      contactFilterSelected?.map((selected) => selected.value) || [];
    const selectedProducts =
      productFilterSelected?.map((selected) => selected.value) || [];
    const selectedProductTypes =
      productTypeFilterSelected?.map((selected) => selected.value) || [];
    const selectedProductTags =
      productTagsFilterSelected?.map((selected) => selected.value) || [];
    const selectedCustomerTags =
      customerTagsFilterSelected?.map((selected) => selected.value) || [];
    const selectedLocations =
      locationFilterSelected?.map((selected) => selected.value) || [];
    const dataset_id = organisation?.dataset_ids?.dear;
    const params = {
      brand: selectedBrands.join(", "),
      category: selectedCategories.join(", "),
      contact: selectedContacts.join(", "),
      product: selectedProducts.join(", "),
      productType: selectedProductTypes.join(", "),
      productTags: selectedProductTags.join(", "),
      customerTags: selectedCustomerTags.join(", "),
      location: selectedLocations.join(", "),
    };

    const appliedAdvanceFilters = {
      brand: brandFilterSelected,
      category: categoryFilterSelected,
      contact: contactFilterSelected,
      product: productFilterSelected,
      productType: productTypeFilterSelected,
      productTags: productTagsFilterSelected,
      customerTags: customerTagsFilterSelected,
      location: locationFilterSelected,
    };
    if (title === "Product Sales of") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'productSalesLineGraph', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearProductSalesLineGraphFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Profit Line Graph of ") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'grossProfitLineGraph', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearProfitLineGraphFilteredData({ dataset_id, token, params })
      );
    }

    if (title === "Units Sold by ") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'unitsSoldLineGraph', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearUnitsSoldLineGraphFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Units Shipped by ") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'unitsShippedLineGraph', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearUnitsShippedLineGraphFilteredData({ dataset_id, token, params })
      );
    }
    handleAdvanceDearFilters();
  };
  const handleAdvanceDearFiltersClose = () => {
    handleAdvanceDearFilters();
  };

  const handleAdvanceDearFilters = () => {
    setIsAdvancedDearFilters(!isAdvancedDearFilters);
  };

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 2, // You can adjust this value based on your preference
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: `${title} ${company?.name}`,
      },
      tooltip: {
        mode: 'index',
        callbacks: {
          label: function (tooltipItem) {
            const datasetLabel = tooltipItem.dataset.label || '';
            const value = tooltipItem.raw.toFixed(2);
            const formattedValue = new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 0,
              maximumFractionDigits: 2
            }).format(value);
            return ` ${datasetLabel}:  ${currencySymbol}${formattedValue}`;
          }
        }
      }
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        type: 'category',
        ticks: {
          callback: function (value, index, values) {
            const label = this.getLabelForValue(value)
            const month = label.split('-')[1]
            return monthMap[month] || month
          }
        }
      },
      y: {
        display: true,
        grid: {
          display: false,
        },
        // ticks: {
        //   callback: function (value) {
        //     if (value >= 1e9) {
        //       return (value / 1e9) + 'B'; // Billion
        //     } else if (value >= 1e6) {
        //       return (value / 1e6) + 'M'; // Million
        //     } else if (value >= 1e3) {
        //       return (value / 1e3) + 'K'; // Thousand
        //     }
        //     return value; // Less than thousand
        //   }
        // }
        ticks: {
          callback: function (value) {
            const isNegative = value < 0;
            const absValue = Math.abs(value);

            let formattedValue;

            if (absValue >= 1e9) {
              formattedValue = (absValue / 1e9) + 'B'; // Billion
            } else if (absValue >= 1e6) {
              formattedValue = (absValue / 1e6) + 'M'; // Million
            } else if (absValue >= 1e3) {
              formattedValue = (absValue / 1e3) + 'K'; // Thousand
            } else {
              formattedValue = absValue; // Less than thousand
            }

            return isNegative ? `-${formattedValue}` : formattedValue;
          }
        }
      },
    },
  };

  const chartContainerStyle = {
    minHeight: "300px", // Adjust this value as needed
  };
  const [toggleKPIDrillDownModal, setKPIDrillDownModal] = useState(false);
  const toggleDrillDownModalSheet = (title) => {
    setKPIDrillDownModal(!toggleKPIDrillDownModal);
  };
  return (
    <>
      <div className="relative mt-2">
        {isAdvancedDearFilters !== undefined ? (
          <div
            className="cursor-pointer flex justify-end"
            onClick={handleAdvanceDearFilters}
          >
            <BsFillFilterSquareFill className="text-purple" size={22} />
          </div>
        ) : null}
        {isAdvancedDearFilters ? (
          <div className="w-full mt-2 p-2 border rounded-lg border-light-grey bg-white">
            <div className="w-full flex flex-col gap-2">
              <div className="flex items-center flex-row">
                <p className="w-1/4 text-[10px]">Brands: </p>
                <MultiSelect
                  color="#fdba74"
                  className="w-full"
                  key="brand_id"
                  options={brandFilterOptions}
                  onChange={handleBrandChange}
                  value={brandFilterSelected}
                  isSelectAll={true}
                  menuPlacement={"bottom"}
                />
              </div>
              <div className="flex items-center flex-row">
                <p className="w-1/4 text-[10px]">Product: </p>
                <MultiSelect
                  className="w-full"
                  key="product_tags_id"
                  // color="#69b6ff"
                  color="#addff7"
                  options={productFilterOptions}
                  onChange={handleProductChange}
                  value={productFilterSelected}
                  isSelectAll={true}
                  menuPlacement={"bottom"}
                />
              </div>
              <div className="flex items-center flex-row">
                <p className="w-1/4 text-[10px]">Product Tag: </p>
                <MultiSelect
                  className="w-full"
                  key="product_tags_id"
                  // color="#69b6ff"
                  color="#7dd3fc"
                  options={productTagsFilterOptions}
                  onChange={handleProductTagsChange}
                  value={productTagsFilterSelected}
                  isSelectAll={true}
                  menuPlacement={"bottom"}
                />
              </div>
              <div className="flex items-center flex-row">
                <p className="w-1/4 text-[10px]">Customer: </p>
                <MultiSelect
                  className="w-full"
                  key="customer_tag_id"
                  color="#9ae6c7"
                  options={contactFilterOptions}
                  onChange={handleContactChange}
                  value={contactFilterSelected}
                  isSelectAll={true}
                  menuPlacement={"bottom"}
                />
              </div>
              <div className="flex items-center flex-row">
                <p className="w-1/4 text-[10px]">Customer Tag: </p>
                <MultiSelect
                  className="w-full"
                  key="customer_tag_id"
                  color="#6ee7b7"
                  options={customerTagsFilterOptions}
                  onChange={handleCustomerTagsChange}
                  value={customerTagsFilterSelected}
                  isSelectAll={true}
                  menuPlacement={"bottom"}
                />
              </div>
              <div className="flex items-center flex-row">
                <p className="w-1/4 text-[10px]">Category: </p>
                <MultiSelect
                  className="w-full"
                  key="category_id"
                  color="#d4d4d4"
                  options={categoryFilterOptions}
                  onChange={handleCategoryChange}
                  value={categoryFilterSelected}
                  isSelectAll={true}
                  menuPlacement={"bottom"}
                />
              </div>
              <div className="flex items-center flex-row">
                <p className="w-1/4 text-[10px]">Type: </p>
                <MultiSelect
                  className="w-full"
                  key="product_type_id"
                  color="#d8b4fe"
                  options={productTypeFilterOptions}
                  onChange={handleProductTypeChange}
                  value={productTypeFilterSelected}
                  isSelectAll={true}
                  menuPlacement={"bottom"}
                />
              </div>
              <div className="flex items-center flex-row">
                <p className="w-1/4 text-[10px]">Location: </p>
                <MultiSelect
                  className="w-full"
                  key="product_type_id"
                  color="#ff3b7b"
                  options={locationFilterOptions}
                  onChange={handleLocationChange}
                  value={locationFilterSelected}
                  isSelectAll={true}
                  menuPlacement={"bottom"}
                />
              </div>
              <div className="flex items-center mt-3 flex-row gap-2 ">
                <button
                  onClick={saveSelectedFilters}
                  className="w-full inline-flex justify-center rounded-md border border-transparent text-xs shadow-sm py-2 bg-purple  font-medium text-white hover:bg-purple/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save Filters
                </button>
                <button
                  type="button"
                  onClick={handleAdvanceDearFiltersClose}
                  className="w-full inline-flex justify-center rounded-md border border-slate-200 text-xs shadow-sm py-2  font-medium text-grey hover:bg-dark-purple hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        ) :
          <div className="mt-3 border rounded-lg border-light-grey ">
            <div
              className="p-2 bg-white"
              style={chartContainerStyle}
            >
              <Line options={options} data={companyData} />
            </div>
            <div className="flex flex-wrap gap-1 p-2">
              {/* Render the filters */}
              {filtersToShow.map((selected, index) => (
                <div
                  onClick={handleAdvanceDearFilters}
                  key={index}
                  className={`flex-none text-[10px] px-3 py-1 cursor-pointer rounded-md font-semibold
                  ${selected.colorClass || 'bg-gray-200 text-gray-600'}`}
                >
                  {selected.value}
                </div>
              ))}

              {/* Show More/Show Less button */}
              {combinedFilters.length > 5 && (
                <Toggle
                  onClick={() => setShowMore(prev => !prev)}
                  variant="outline"
                  className="flex-none text-[10px] h-6 cursor-pointer rounded-md"
                >
                  {showMore ? 'Show Less' : `Show More (${combinedFilters.length - 5})`}
                </Toggle>
              )}
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default LineChartDear;
