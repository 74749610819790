// KPIDetailsPage.js
import React, { useState } from "react";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import LineChart from "../../../components/graphs/react-chartjs-two/line-chart";
import DashboardBanner from "../../../components/ui-elements/banner/dashbaord-banner";
import { useSelector } from "react-redux";
import { AiFillFile } from "react-icons/ai";
import StackedBarChart from "../../../components/graphs/react-chartjs-two/stacked-bar-chart";
import GraphData from "../KPIs/graphs";
import BoxCard03 from "../../../components/ui-elements/cards/box-card-03";
import { FaArrowLeftLong } from "react-icons/fa6";
import HorizontalBarChart from "../../../components/graphs/react-chartjs-two/horizontal-bar-chart-dear";
import LineChartDear from "../../../components/graphs/react-chartjs-two/line-chart-dear";
import BoxCard02 from "../../../components/ui-elements/cards/box-card-02";
import BoxTableCard01 from "../../../components/ui-elements/cards/table-cards/box-table-card-01";
// import BoxTableCard02 from "../../../components/ui-elements/cards/table-cards/box-table-card-02";
import SalesByMonthByProduct from "../../../components/ui-elements/tables/kpis-tables/dear/general-table-kpis/sales-by-month-by-product";
import countryToSymbolMap from "../../../../constant/country-code-currency.json";
import BoxCard01 from "../../../components/ui-elements/cards/box-card-01";
import KPIChatComponent from "./kpi-chat";

function KPIDetailsPage() {
  const { kpiName } = useParams();
  const location = useLocation();
  const {
    xeroKPIFigures,
    xeroBankTable,
    isLoadingKPI,
    dearProfitDrilldownTableByCustomer,
    dearProfitDrilldownTableByProduct,
    dearSalesDrilldownTableByCustomer,
    dearSalesDrilldownTableByProduct,
    dearUnitsShippedDrilldownTableByCustomer,
    dearUnitsShippedDrilldownTableByProduct,
    dearUnitsSoldDrilldownTableByCustomer,
    dearUnitsSoldDrilldownTableByProduct,
    dearSalesByMonthByProduct,
    dearLoadingStatuses,

    dearProductSalesByBrand,
    dearProductSalesByCategory,
    dearProductSalesByCustomer,
    dearProductSalesByCustomerTag,
    dearProductSalesByProduct,
    dearProductSalesByProductTag,
    dearProductSalesByProductType,
    dearProfitByBrand,
    dearProfitByCategory,
    dearProfitByCustomer,
    dearProfitByCustomerTag,
    dearProfitByProduct,
    dearProfitByProductTag,
    dearProfitByProductType,
    dearUnitsSoldByBrand,
    dearUnitsSoldByCategory,
    dearUnitsSoldByCustomer,
    dearUnitsSoldByCustomerTag,
    dearUnitsSoldByProduct,
    dearUnitsSoldByProductTag,
    dearUnitsSoldByProductType,
    // Units Shipped
    dearUnitsShippedByBrand,
    dearUnitsShippedByCategory,
    dearUnitsShippedByCustomer,
    dearUnitsShippedByCustomerTag,
    dearUnitsShippedByProduct,
    dearUnitsShippedByProductTag,
    dearUnitsShippedByProductType,
    dearSampleShipped,
  } = useSelector((state) => state.kpi);
  const { organisation } = useSelector((state) => state.organisation);
  const countryCode =
    organisation && organisation.country_alpha3
      ? organisation.country_alpha3.replace(/"/g, "")
      : null;
  const currencySymbol = countryCode ? countryToSymbolMap[countryCode] : null;
  const navigate = useNavigate();
  const [openKPIAdvancedFilters, setOpenKPIAdvancedFilters] = useState({});

  const toggleAdvancedFilters = (title) => {
    setOpenKPIAdvancedFilters((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };
  // BOX KPIs
  const getKPIFigure = (kpiName) => {
    if (Array.isArray(xeroKPIFigures)) {
      const figure = xeroKPIFigures.find((item) => item.KPI === kpiName);
      return figure || null;
    } else {
      return null;
    }
  };
  const extractFilters = (kpiTitle) => {
    const savedFilters = organisation?.saved_filters[organisation?.name];
    const filter = savedFilters?.find((filter) => filter.kpi === kpiTitle);
    if (filter) {
      return {
        firstFilter: filter.first_filter,
        comparedTo: filter.compared_to,
      };
    }
    return { firstFilter: null, comparedTo: null };
  };
  const [toggleKPISheet, setKPISheetToggle] = useState(false);
  const [kpiSheetTitle, setKPISheetTitle] = useState("");

  const toggleKPI = (title) => {
    setKPISheetTitle(title);
    setKPISheetToggle(!toggleKPISheet);
  };
  const totalUnreconciledItemsFigureData = getKPIFigure(
    "Total unreconciled items"
  );
  const totalUnreconciledItemsFilters = extractFilters(
    "Total unreconciled items"
  );

  const numberOutstandingBillOverpaymentsFigureData = getKPIFigure(
    "Number of outstanding bill overpayments"
  );
  const numberOutstandingBillCreditNotesFigureData = getKPIFigure(
    "Number of outstanding bill credit notes"
  );
  const numberOutstandingBillPrepaymentsFigureData = getKPIFigure(
    "Number of outstanding bill prepayments"
  );
  const numberOutstandingBillsFigureData = getKPIFigure(
    "Number of outstanding bills"
  );

  const outstandingBillPrepaymentValueFigureData = getKPIFigure(
    "Outstanding bill prepayment value"
  );
  const numOutstandingOverdueBillsFigureData = getKPIFigure(
    "Number of outstanding overdue bills"
  );
  const outstandingBillCreditNoteValueFigureData = getKPIFigure(
    "Outstanding bill credit note value"
  );
  const outstandingBillOverpaymentValueFigureData = getKPIFigure(
    "Outstanding bill overpayment value"
  );
  const outstandingOverdueBillValueFigureData = getKPIFigure(
    "Outstanding overdue bill value"
  );
  const numOutstandingSalesInvoicesFigureData = getKPIFigure(
    "Number of outstanding sales invoices"
  );
  const numOutstandingSalesCreditNotesFigureData = getKPIFigure(
    "Number of outstanding sales credit notes"
  );
  const numOutstandingSalesOverpaymentsFigureData = getKPIFigure(
    "Number of outstanding sales overpayments"
  );

  const numOutstandingOverdueSalesInvoicesFigureData = getKPIFigure(
    "Number of outstanding overdue sales invoices"
  );
  const outstandingSalesCreditNoteValueFigureData = getKPIFigure(
    "Outstanding sales credit note value"
  );

  const outstandingSalesOverpaymentValueFigureData = getKPIFigure(
    "Outstanding sales overpayment value"
  );
  const outstandingSalesPrepaymentValueFigureData = getKPIFigure(
    "Outstanding sales prepayment value"
  );
  const outstandingSalesInvoiceValueFigureData = getKPIFigure(
    "Outstanding sales invoice value"
  );
  const numOutstandingSalesPrepaymentsFigureData = getKPIFigure(
    "Number of outstanding sales prepayments"
  );
  const outstandingOverdueSalesInvoiceValueFigureData = getKPIFigure(
    "Outstanding overdue sales invoice value"
  );
  // GRAPHS
  const {
    grossProfitLineData,
    netProfitLineData,
    netProfitMarginLineData,
    netProfitMarginLineGraphData,
    grossProfitLineGraphData,
    netProfitLineGraphData,
    totalCostOfSalesData,
    totalCostOfSalesLineGraphData,
    totalCostsData,
    totalCostsLineGraphData,
    operatingCostPercentageRevenueData,
    operatingCostPercentageRevenueLineGraphData,
    totalOperatingExpensesData,
    totalOperatingExpensesLineGraphData,
    grossProfitMarginData,
    grossProfitMarginLineGraphData,
    currentAssetsData,
    currentAssetsLineGraphData,
    currentLiabilitiesData,
    currentLiabilitiesLineGraphData,
    averageCreditorDaysData,
    averageCreditorDaysLineGraphData,
    totalBankData,
    totalBankLineGraphData,
    moneyYouOweLineData,
    moneyYouOweLineGraphData,
    moneyOwedToYouLineData,
    moneyOwedToYouLineGraphData,
    totalIncomeData,
    totalIncomeLineGraphData,
    otherRevenueData,
    otherRevenueLineGraphData,
    averageDebtorDaysData,
    averageDebtorDaysLineGraphData,
    totalIncomeBarChartData,
    totalIncomeStackedBarChartData,
    totalIncomeData02,
    totalIncomeStackedBarChartData02,
    salesByBrandsChartData,
    salesByProductChartData,
    salesByCategoryChartData,
    salesByCustomerChartData,
    salesByProductTagChartData,
    salesByProductTypeChartData,
    salesByCustomerTagChartData,
    profitByCategoryChartData,
    profitByProductChartData,
    profitByBrandChartData,
    profitByCustomerChartData,
    profitByCustomerTagChartData,
    profitByProductTagChartData,
    profitByProductTypeChartData,
    unitsSoldByCategoryChartData,
    unitsSoldByBrandsChartData,
    unitsSoldByCustomerChartData,
    unitsSoldByCustomerTagChartData,
    unitsSoldByProductChartData,
    unitsSoldByProductTypeChartData,
    unitsSoldByProductTagChartData,
    unitsSoldLineGraphData,
    productSalesLineGraphData,
    profitLineGraphData,
    cashReceivedLineGraphData,
    cashSpentLineGraphData,
    unitsShippedLineGraphData,
    unitsShippedByCategoryChartData,
    unitsShippedByBrandsChartData,
    unitsShippedByCustomerChartData,
    unitsShippedByCustomerTagChartData,
    unitsShippedByProductChartData,
    unitsShippedByProductTypeChartData,
    unitsShippedByProductTagChartData,
    combinedAssetsLiabilitiesLineGraphData,
    inventoryLineGraphData,
  } = GraphData();
  // Dear Graphs

  // Import the HorizontalBarChart component

  // // Inside your KPIDetailsPage component where you render the HorizontalBarChart component
  const currentPeriod = "This Quarter";
  const comparisonPeriod = "Previous Period";
  // function calculateChartData(data, currentPeriod, comparisonPeriod) {
  //   const filteredData = data.filter(item => item.date_selection === currentPeriod || item.date_selection === comparisonPeriod);
  //   const datasets = [];
  //   const breakdowns = [];
  //   filteredData.forEach(item => {
  //     if (!breakdowns.includes(item.breakdown)) {
  //       breakdowns.push(item.breakdown);
  //     }
  //   });
  //   breakdowns.forEach(breakdown => {
  //     const currentPeriodData = filteredData.find(item => item.breakdown === breakdown && item.date_selection === currentPeriod);
  //     const comparisonPeriodData = filteredData.find(item => item.breakdown === breakdown && item.date_selection === comparisonPeriod);
  //     if (currentPeriodData && comparisonPeriodData) {
  //       datasets.push({
  //         label: breakdown,
  //         data: [parseFloat(currentPeriodData.current_period), parseFloat(comparisonPeriodData.current_period)],
  //         backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)'],
  //       });
  //     }
  //   });
  //   return { datasets, breakdowns };
  // }

  // const breakdownSet = new Set();
  // const datasets = [];

  // // Extract unique breakdown values
  // salesByBrand.forEach(item => {
  //   if (item.breakdown && item.breakdown !== null) {
  //     breakdownSet.add(item.breakdown);
  //   }
  // });

  // // Create datasets using unique breakdown values
  // breakdownSet.forEach(breakdown => {
  //   const filteredItems = salesByBrand.filter(item => item.breakdown === breakdown);
  //   const currentPeriodData = filteredItems.find(item => item.date_selection === currentPeriod);
  //   const previousPeriodData = filteredItems.find(item => item.date_selection === comparisonPeriod);

  //   if (currentPeriodData && previousPeriodData) {
  //     const currentPeriod = parseFloat(currentPeriodData.current_period);
  //     const previousPeriod = parseFloat(previousPeriodData.current_period);

  //     datasets.push({
  //       label: breakdown,
  //       data: [currentPeriod, previousPeriod],
  //       backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)'],
  //     });
  //   }
  // });

  // // Convert Set to array to maintain order

  // // Convert Set to array to maintain order
  // const labels = Array.from(breakdownSet);
  // console.log('labels', labels)

  let dynamicFirstComponent;
  let dynamicSecondComponent;
  let trailLink1;
  let trailLink2;
  let trailPath1;
  switch (kpiName) {
    case "Revenue":
      dynamicFirstComponent = (
        <LineChart
          currencySymbol={currencySymbol}
          companyData={totalIncomeLineGraphData}
          company={organisation}
          title={"Total Revenue of "}
        />
      );
      trailLink1 = "Revenue";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/revenue-and-costs/";
      break;
    case "Inventory":
      dynamicFirstComponent = (
        <LineChart
          currencySymbol={currencySymbol}
          companyData={inventoryLineGraphData}
          company={organisation}
          title={"Inventory of "}
        />
      );
      trailLink1 = "Inventory";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/inventory/";
      break;
    case "Average Debtor Days":
      trailLink1 = "Cash Flow";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/cash/";
      dynamicFirstComponent = (
        <LineChart
          currencySymbol={""}
          companyData={averageDebtorDaysLineGraphData}
          company={organisation}
          title={"Average Debtor Days"}
        />
      );
      break;
    case "Operating Cost Expenses":
      dynamicFirstComponent = (
        <LineChart
          currencySymbol={currencySymbol}
          companyData={operatingCostPercentageRevenueLineGraphData}
          company={organisation}
          title={"Operating Cost Percentage of Revenue of"}
        />
      );
      break;
    case "Other Revenues":
      dynamicFirstComponent = (
        <LineChart
          currencySymbol={currencySymbol}
          companyData={otherRevenueLineGraphData}
          company={organisation}
          title={"Other Revenue of"}
        />
      );
      break;
    case "Income Tracking Category One":
      dynamicFirstComponent = (
        <StackedBarChart
          companyData={totalIncomeStackedBarChartData}
          company={organisation}
          title={"Total Income Tracking 01 "}
        />
      );
      break;
    case "Income Tracking Category Two":
      dynamicFirstComponent = (
        <StackedBarChart
          companyData={totalIncomeStackedBarChartData02}
          company={organisation}
          title={"Total Income Tracking 02 "}
        />
      );
      break;
    case "Product Sales":
      trailLink1 = "Revenue";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/revenue-and-costs/";
      dynamicFirstComponent = (
        <>
          <LineChartDear
            currencySymbol={currencySymbol}
            companyData={productSalesLineGraphData}
            company={organisation}
            title={"Product Sales of"}
          />

          <div className="flex flex-row  gap-2">
            <HorizontalBarChart
              currencySymbol={currencySymbol}
              data={salesByBrandsChartData}
              title={dearProductSalesByBrand?.results[0]?.kpi || "Top 10 Brands By Sales"}
              advanceFilterTitle={"Net Sales - Products"}

              drilldownTitle={
                dearSalesDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Sales drilldown by Product"
              }
              drilldownTooltip={
                dearSalesDrilldownTableByProduct?.results?.[0]?.description ||
                "Sum of net sales for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearSalesDrilldownTableByProduct?.results?.[0]?.data || []
              }
            />
            <HorizontalBarChart
              currencySymbol={currencySymbol}
              data={salesByCategoryChartData}
              title={dearProductSalesByCategory?.results[0]?.kpi || "Top 10 Categories By Sales"}
              advanceFilterTitle={"Net Sales - Products"}

              drilldownTitle={
                dearSalesDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Sales drilldown by Product"
              }
              drilldownTooltip={
                dearSalesDrilldownTableByProduct?.results?.[0]?.description ||
                "Sum of net sales for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearSalesDrilldownTableByProduct?.results?.[0]?.data || []
              }
            />
          </div>
          <div className="flex flex-row  gap-2">
            <HorizontalBarChart
              currencySymbol={currencySymbol}
              data={salesByCustomerChartData}
              title={dearProductSalesByCustomer?.results[0]?.kpi || "Top 10 Customers By Sales"}
              advanceFilterTitle={"Net Sales - Customers"}
              drilldownTitle={
                dearSalesDrilldownTableByCustomer?.results?.[0]?.kpi ||
                "Sales drilldown By Customer"
              }
              drilldownTooltip={
                dearSalesDrilldownTableByCustomer?.results?.[0]?.description ||
                "Sum of net sales for each customer in Cin7 Core"
              }
              drilldownType={"Customer"}
              drilldownData={
                dearSalesDrilldownTableByCustomer?.results?.[0]?.data || []
              }
            />
            <HorizontalBarChart
              currencySymbol={currencySymbol}
              data={salesByCustomerTagChartData}
              title={dearProductSalesByCustomerTag?.results[0]?.kpi || "Top 10 Customer Tags By Sales"}
              advanceFilterTitle={"Net Sales - Customers"}
              drilldownTitle={
                dearSalesDrilldownTableByCustomer?.results?.[0]?.kpi ||
                "Sales drilldown By Customer"
              }
              drilldownTooltip={
                dearSalesDrilldownTableByCustomer?.results?.[0]?.description ||
                "Sum of net sales for each customer in Cin7 Core"
              }
              drilldownType={"Customer"}
              drilldownData={
                dearSalesDrilldownTableByCustomer?.results?.[0]?.data || []
              }
            />
          </div>
          <div className="flex flex-row  gap-2">
            <HorizontalBarChart
              currencySymbol={currencySymbol}
              data={salesByProductChartData}
              title={dearProductSalesByProduct?.results[0]?.kpi || "Top 10 Products By Sales"}
              advanceFilterTitle={"Net Sales - Products"}
              drilldownTitle={
                dearSalesDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Sales drilldown by Product"
              }
              drilldownTooltip={
                dearSalesDrilldownTableByProduct?.results?.[0]?.description ||
                "Sum of net sales for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearSalesDrilldownTableByProduct?.results?.[0]?.data || []
              }
            />
            <HorizontalBarChart
              currencySymbol={currencySymbol}
              data={salesByProductTagChartData}
              title={dearProductSalesByProductTag?.results[0]?.kpi || "Top 10 Product Tags By Sales"}
              advanceFilterTitle={"Net Sales - Products"}

              drilldownTitle={
                dearSalesDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Sales drilldown by Product"
              }
              drilldownTooltip={
                dearSalesDrilldownTableByProduct?.results?.[0]?.description ||
                "Sum of net sales for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearSalesDrilldownTableByProduct?.results?.[0]?.data || []
              }
            />
          </div>
          <div class="mx-0 mt-5">
            <SalesByMonthByProduct
              title={
                dearSalesByMonthByProduct?.results?.[0]?.kpi ||
                "Sales By Product By Month"
              }
              tooltip={
                dearSalesByMonthByProduct?.results?.[0]?.description ||
                "Sum of net sales for each product by month in Cin7 Core"
              }
              isLoading={dearLoadingStatuses.dearOtherKPIs}
              tableData={dearSalesByMonthByProduct?.results?.[0]?.data}
              isWidthFull={true}
              opened={false}
            />
          </div>

          {/* <HorizontalBarChart data={salesByProductTypeChartData} title={"Sales by Product Type"} /> */}
        </>
      );
      break;
    case "Gross Profit":
      trailLink1 = "Revenue";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/revenue-and-costs/";
      dynamicFirstComponent = (
        <>
          <LineChartDear
            currencySymbol={currencySymbol}
            companyData={profitLineGraphData}
            company={organisation}
            title={"Profit Line Graph of "}
          />

          <div className="flex flex-row gap-2">
            <HorizontalBarChart
              currencySymbol={currencySymbol}
              data={profitByBrandChartData}
              title={dearProfitByBrand?.results[0]?.kpi || "Top 10 Brands By Gross Profit"}
              advanceFilterTitle={"Gross Profit - Products"}

              drilldownTitle={
                dearProfitDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Gross Profit Drilldown By Product"
              }
              drilldownTooltip={
                dearProfitDrilldownTableByProduct?.results?.[0]?.description ||
                "Sum of net sales minus average costs for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearProfitDrilldownTableByProduct?.results?.[0]?.data || []
              }
            />
            <HorizontalBarChart
              currencySymbol={currencySymbol}
              data={profitByCategoryChartData}
              title={dearProfitByCategory?.results[0]?.kpi || "Top 10 Categories By Gross Profit"}
              advanceFilterTitle={"Gross Profit - Products"}

              drilldownTitle={
                dearProfitDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Gross Profit Drilldown By Product"
              }
              drilldownTooltip={
                dearProfitDrilldownTableByProduct?.results?.[0]?.description ||
                "Sum of net sales minus average costs for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearProfitDrilldownTableByProduct?.results?.[0]?.data || []
              }
            />
          </div>
          <div className="flex flex-row  gap-2">
            <HorizontalBarChart
              currencySymbol={currencySymbol}
              data={profitByCustomerChartData}
              title={dearProfitByCustomer?.results[0]?.kpi || "Top 10 Customers By Gross Profit"}
              advanceFilterTitle={"Gross Profit - Customers"}

              drilldownTitle={
                dearProfitDrilldownTableByCustomer?.results?.[0]?.kpi ||
                "Gross Profit Drilldown By Customer"
              }
              drilldownTooltip={
                dearProfitDrilldownTableByCustomer?.results?.[0]?.description ||
                "Sum of net sales minus average costs for each customer in Cin7 Core"
              }
              drilldownType={"Customer"}
              drilldownData={
                dearProfitDrilldownTableByCustomer?.results?.[0]?.data || []
              }
            />
            <HorizontalBarChart
              currencySymbol={currencySymbol}
              data={profitByCustomerTagChartData}
              drilldownTitle={
                dearProfitDrilldownTableByCustomer?.results?.[0]?.kpi ||
                "Gross Profit Drilldown By Customer"
              }
              drilldownTooltip={
                dearProfitDrilldownTableByCustomer?.results?.[0]?.description ||
                "Sum of net sales minus average costs for each customer in Cin7 Core"
              }
              title={dearProfitByCustomerTag?.results[0]?.kpi || "Top 10 Customer Tags By Gross Profit"}
              advanceFilterTitle={"Gross Profit - Customers"}

              drilldownType={"Customer"}
              drilldownData={
                dearProfitDrilldownTableByCustomer?.results?.[0]?.data || []
              }
            />
          </div>
          <div className="flex flex-row  gap-2">
            <HorizontalBarChart
              currencySymbol={currencySymbol}
              data={profitByProductChartData}
              title={dearProfitByProduct?.results[0]?.kpi || "Top 10 Products By Gross Profit"}
              advanceFilterTitle={"Gross Profit - Products"}

              drilldownTitle={
                dearProfitDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Gross Profit Drilldown By Product"
              }
              drilldownTooltip={
                dearProfitDrilldownTableByProduct?.results?.[0]?.description ||
                "Sum of net sales minus average costs for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearProfitDrilldownTableByProduct?.results?.[0]?.data || []
              }
            />
            <HorizontalBarChart
              currencySymbol={currencySymbol}
              data={profitByProductTagChartData}
              title={dearProfitByProductTag?.results[0]?.kpi || "Top 10 Product Tags By Gross Profit"}
              advanceFilterTitle={"Gross Profit - Products"}

              drilldownTitle={
                dearProfitDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Gross Profit Drilldown By Product"
              }
              drilldownTooltip={
                dearProfitDrilldownTableByProduct?.results?.[0]
                  ?.descriptdescription ||
                "Sum of net sales minus average costs for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearProfitDrilldownTableByProduct?.results?.[0]?.data || []
              }
            />
          </div>

          {/* <HorizontalBarChart data={profitByProductTypeChartData} title={"Profit By Product Type"} /> */}
        </>
      );
      break;
    case "Units Sold":
      trailLink1 = "Revenue";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/revenue-and-costs/";
      dynamicFirstComponent = (
        <>
          <LineChartDear
            currencySymbol={""}
            companyData={unitsSoldLineGraphData}
            company={organisation}
            title={"Units Sold by "}
          />
          <div className="flex flex-row  gap-2">
            <HorizontalBarChart
              currencySymbol={""}
              data={unitsSoldByBrandsChartData}
              title={dearUnitsSoldByBrand?.results[0]?.kpi || "Top 10 Brands By Units Sold"}
              advanceFilterTitle={"Units Sold - Products"}

              drilldownTitle={
                dearUnitsSoldDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Units Sold Drilldown Product"
              }
              drilldownTooltip={
                dearUnitsSoldDrilldownTableByProduct?.results?.[0]
                  ?.description ||
                "Sum of units sold for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearUnitsSoldDrilldownTableByProduct?.results?.[0]?.data || []
              }
            />
            <HorizontalBarChart
              currencySymbol={""}
              data={unitsSoldByCategoryChartData}
              title={dearUnitsSoldByCategory?.results[0]?.kpi || "Top 10 Categories By Units Sold"}
              advanceFilterTitle={"Units Sold - Products"}
              drilldownTitle={
                dearUnitsSoldDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Units Sold Drilldown Product"
              }
              drilldownTooltip={
                dearUnitsSoldDrilldownTableByProduct?.results?.[0]
                  ?.description ||
                "Sum of units sold for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearUnitsSoldDrilldownTableByProduct?.results?.[0]?.data || []
              }
            />
          </div>
          <div className="flex flex-row  gap-2">
            <HorizontalBarChart
              currencySymbol={""}
              data={unitsSoldByCustomerChartData}
              title={dearUnitsSoldByCustomer?.results[0]?.kpi || "Top 10 Customers By Units Sold"}
              advanceFilterTitle={"Units Sold - Customers"}

              drilldownTitle={
                dearUnitsSoldDrilldownTableByCustomer?.results?.[0]?.kpi ||
                "Units Sold Drilldown Customer"
              }
              drilldownTooltip={
                dearUnitsSoldDrilldownTableByCustomer?.results?.[0]
                  ?.description ||
                "Sum of units sold for each customer in Cin7 Core"
              }
              drilldownType={"Customer"}
              drilldownData={
                dearUnitsSoldDrilldownTableByCustomer?.results?.[0]?.data || []
              }
            />
            <HorizontalBarChart
              currencySymbol={""}
              data={unitsSoldByCustomerTagChartData}
              title={dearUnitsSoldByCustomerTag?.results[0]?.kpi || "Top 10 Customer Tags By Units Sold"}
              advanceFilterTitle={"Units Sold - Customers"}

              drilldownTitle={
                dearUnitsSoldDrilldownTableByCustomer?.results?.[0]?.kpi ||
                "Units Sold Drilldown Customer"
              }
              drilldownTooltip={
                dearUnitsSoldDrilldownTableByCustomer?.results?.[0]
                  ?.description ||
                "Sum of units sold for each customer in Cin7 Core"
              }
              drilldownType={"Customer"}
              drilldownData={
                dearUnitsSoldDrilldownTableByCustomer?.results?.[0]?.data || []
              }
            />
          </div>
          <div className="flex flex-row  gap-2">
            <HorizontalBarChart
              currencySymbol={""}
              data={unitsSoldByProductChartData}
              title={dearUnitsSoldByProduct?.results[0]?.kpi || "Top 10 Products By Units Sold"}
              advanceFilterTitle={"Units Sold - Products"}

              drilldownTitle={
                dearUnitsSoldDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Units Sold Drilldown Product"
              }
              drilldownTooltip={
                dearUnitsSoldDrilldownTableByProduct?.results?.[0]
                  ?.description ||
                "Sum of units sold for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearUnitsSoldDrilldownTableByProduct?.results?.[0]?.data || []
              }
            />
            <HorizontalBarChart
              currencySymbol={""}
              data={unitsSoldByProductTagChartData}
              title={dearUnitsSoldByProductTag?.results[0]?.kpi || "Top 10 Product Tags By Units Sold"}
              advanceFilterTitle={"Units Sold - Products"}

              drilldownTitle={
                dearUnitsSoldDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Units Sold Drilldown Product"
              }
              drilldownTooltip={
                dearUnitsSoldDrilldownTableByProduct?.results?.[0]
                  ?.description ||
                "Sum of units sold for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearUnitsSoldDrilldownTableByProduct?.results?.[0]?.data || []
              }
            />
          </div>

          {/* <HorizontalBarChart data={unitsSoldByProductTypeChartData} title={"Units Sold by Product Type"} /> */}
        </>
      );
      break;
    case "Sample Conversion Rate":
      trailLink1 = "Revenue";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/revenue-and-costs/";
      dynamicFirstComponent = (
        <>
          <div class=" rounded-md mt-5 w-1/2">
            <BoxCard02
              isLoadingDearKPI={dearLoadingStatuses.dearOtherKPIs}
              isAdvancedDearFilters={openKPIAdvancedFilters[dearSampleShipped?.results?.[0]?.kpi || 'Number of Shipments of Samples to Customers']}
              setIsAdvancedDearFilters={(value) =>
                toggleAdvancedFilters(dearSampleShipped?.results?.[0]?.kpi || 'Number of Shipments of Samples to Customers', value)
              }
              isCustomSymbol={true}
              customSymbol={''}
              title={dearSampleShipped?.results?.[0]?.kpi || 'Number of Shipments of Samples to Customers'}
              tooltip={dearSampleShipped?.results?.[0]?.description || 'Number of customers who have been shipped a product with sample in the name in Cin7 Core'}
              data={
                dearSampleShipped &&
                  dearSampleShipped.results &&
                  dearSampleShipped.results?.[0]?.data?.length > 0
                  ? dearSampleShipped.results?.[0]?.data[0]
                  : null
              }
              savedFilterA={dearSampleShipped.firstFilter}
              savedFilterB={dearSampleShipped.comparedTo}
              sparkLine01Data={{}}
              sparkLine02Data={{}}
            />
          </div>
        </>
      );
      break;
    case "Units Shipped":
      trailLink1 = "Inventory";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/inventory/";
      dynamicFirstComponent = (
        <>
          <LineChartDear
            currencySymbol={""}
            companyData={unitsShippedLineGraphData}
            company={organisation}
            title={"Units Shipped by "}
          />
          <div className="flex flex-row  gap-2">
            <HorizontalBarChart
              currencySymbol={""}
              data={unitsShippedByBrandsChartData}
              title={dearUnitsShippedByBrand?.results[0]?.kpi || "Top 10 Brands By Units Shipped"}
              advanceFilterTitle={"Units Shipped - Products"}

              drilldownTitle={
                dearUnitsShippedDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Units Shipped Drilldown Product"
              }
              drilldownTooltip={
                dearUnitsShippedDrilldownTableByProduct?.results?.[0]
                  ?.description ||
                "Sum of units shipped for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearUnitsShippedDrilldownTableByProduct?.results?.[0]?.data ||
                []
              }
            />
            <HorizontalBarChart
              currencySymbol={""}
              data={unitsShippedByCategoryChartData}
              title={dearUnitsShippedByCategory?.results[0]?.kpi || "Top 10 Categories By Units Shipped"}
              advanceFilterTitle={"Units Shipped - Products"}

              drilldownTitle={
                dearUnitsShippedDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Units Shipped Drilldown Product"
              }
              drilldownTooltip={
                dearUnitsShippedDrilldownTableByProduct?.results?.[0]
                  ?.description ||
                "Sum of units shipped for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearUnitsShippedDrilldownTableByProduct?.results?.[0]?.data ||
                []
              }
            />
          </div>
          <div className="flex flex-row  gap-2">
            <HorizontalBarChart
              currencySymbol={""}
              data={unitsShippedByCustomerChartData}
              title={dearUnitsShippedByCustomer?.results[0]?.kpi || "Top 10 Customers By Units Shipped"}
              advanceFilterTitle={"Units Shipped - Customers"}

              drilldownTitle={
                dearUnitsShippedDrilldownTableByCustomer?.results?.[0]?.kpi ||
                "Units Shipped Drilldown Customer"
              }
              drilldownTooltip={
                dearUnitsShippedDrilldownTableByCustomer?.results?.[0]
                  ?.description ||
                "Sum of units shipped for each customer in Cin7 Core"
              }
              drilldownType={"Customer"}
              drilldownData={
                dearUnitsShippedDrilldownTableByCustomer?.results?.[0]?.data ||
                []
              }
            />
            <HorizontalBarChart
              currencySymbol={""}
              data={unitsShippedByCustomerTagChartData}
              title={dearUnitsShippedByCustomerTag?.results[0]?.kpi || "Top 10 Customer Tags By Units Shipped"}
              advanceFilterTitle={"Units Shipped - Customers"}

              drilldownTitle={
                dearUnitsShippedDrilldownTableByCustomer?.results?.[0]?.kpi ||
                "Units Shipped Drilldown Customer"
              }
              drilldownTooltip={
                dearUnitsShippedDrilldownTableByCustomer?.results?.[0]
                  ?.description ||
                "Sum of units shipped for each customer in Cin7 Core"
              }
              drilldownType={"Customer"}
              drilldownData={
                dearUnitsShippedDrilldownTableByCustomer?.results?.[0]?.data ||
                []
              }
            />
          </div>
          <div className="flex flex-row  gap-2">
            <HorizontalBarChart
              currencySymbol={""}
              data={unitsShippedByProductChartData}
              title={dearUnitsShippedByProduct?.results[0]?.kpi || "Top 10 Products By Units Shipped"}
              advanceFilterTitle={"Units Shipped - Products"}

              drilldownTitle={
                dearUnitsShippedDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Units Shipped Drilldown Product"
              }
              drilldownTooltip={
                dearUnitsShippedDrilldownTableByProduct?.results?.[0]
                  ?.description ||
                "Sum of units shipped for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearUnitsShippedDrilldownTableByProduct?.results?.[0]?.data ||
                []
              }
            />
            <HorizontalBarChart
              currencySymbol={""}
              data={unitsShippedByProductTagChartData}
              title={dearUnitsShippedByProductTag?.results[0]?.kpi || "Top 10 Product Tags By Units Shipped"}
              advanceFilterTitle={"Units Shipped - Products"}

              drilldownTitle={
                dearUnitsShippedDrilldownTableByProduct?.results?.[0]?.kpi ||
                "Units Shipped Drilldown Product"
              }
              drilldownTooltip={
                dearUnitsShippedDrilldownTableByProduct?.results?.[0]
                  ?.description ||
                "Sum of units shipped for each product in Cin7 Core"
              }
              drilldownType={"Product"}
              drilldownData={
                dearUnitsShippedDrilldownTableByProduct?.results?.[0]?.data ||
                []
              }
            />
          </div>

          {/* <HorizontalBarChart data={unitsSoldByProductTypeChartData} title={"Units Sold by Product Type"} /> */}
        </>
      );
      break;
    case "Money You Owe":
      trailLink1 = "Cash Flow";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/cash/";
      dynamicFirstComponent = (
        <LineChart
          currencySymbol={currencySymbol}
          companyData={moneyYouOweLineGraphData}
          company={organisation}
          title={"Money You Owe"}
        />
      );
      dynamicSecondComponent = (
        <>
          <p className="font-medium text-md mt-5 text-gray-600">
            Bills and Invoices{" "}
          </p>
          <div class="mx-0 mt-5">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() => toggleKPI("Outstanding overdue bill value")}
                  customSymbol={"£ "}
                  title="Outstanding  overdue bill value"
                  isCustomSymbol={false}
                  data={outstandingOverdueBillValueFigureData}
                  value={outstandingOverdueBillValueFigureData?.this_month}
                />
              </div>
              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() =>
                    toggleKPI("Outstanding bill credit note value")
                  }
                  customSymbol={"£ "}
                  title="Outstanding bill credit note value"
                  isCustomSymbol={false}
                  data={outstandingBillCreditNoteValueFigureData}
                  value={outstandingBillCreditNoteValueFigureData?.this_month}
                />
              </div>
              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() =>
                    toggleKPI("Outstanding bill overpayment value")
                  }
                  customSymbol={"£ "}
                  title="Outstanding bill overpayment value"
                  isCustomSymbol={false}
                  data={outstandingBillOverpaymentValueFigureData}
                  value={outstandingBillOverpaymentValueFigureData?.this_month}
                />
              </div>
              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() =>
                    toggleKPI("Outstanding bill prepayment value")
                  }
                  customSymbol={"£ "}
                  title="Outstanding bill prepayment value"
                  isCustomSymbol={false}
                  data={outstandingBillPrepaymentValueFigureData}
                  value={outstandingBillPrepaymentValueFigureData?.this_month}
                />
              </div>
              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() => toggleKPI("Number Bill Credit Notes")}
                  customSymbol={""}
                  title="Number of Bill Credit Notes"
                  isCustomSymbol={false}
                  data={numberOutstandingBillCreditNotesFigureData}
                  value={numberOutstandingBillCreditNotesFigureData?.this_month}
                />
              </div>
              <div class=" flex rounded-md">
                <BoxCard03
                  toggleKPI={() =>
                    toggleKPI("Number of outstanding bill overpayments")
                  }
                  customSymbol={""}
                  title="Number of outstanding bill overpayments"
                  isCustomSymbol={false}
                  data={numberOutstandingBillOverpaymentsFigureData}
                  value={
                    numberOutstandingBillOverpaymentsFigureData?.this_month
                  }
                />
              </div>
              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() =>
                    toggleKPI("Number of outstanding bill prepayments")
                  }
                  customSymbol={""}
                  title="Number of outstanding bill prepayments"
                  isCustomSymbol={false}
                  data={numberOutstandingBillPrepaymentsFigureData}
                  value={numberOutstandingBillPrepaymentsFigureData?.this_month}
                />
              </div>

              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() => toggleKPI("Number of outstanding bills")}
                  customSymbol={""}
                  title="Number of outstanding bills"
                  isCustomSymbol={false}
                  data={numberOutstandingBillsFigureData}
                  value={numberOutstandingBillPrepaymentsFigureData?.this_month}
                />
              </div>
              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() =>
                    toggleKPI("Number of outstanding overdue bills")
                  }
                  customSymbol={""}
                  title="Number of outstanding overdue bills"
                  isCustomSymbol={false}
                  data={numOutstandingOverdueBillsFigureData}
                  value={numOutstandingOverdueBillsFigureData?.this_month}
                />
              </div>
            </div>
          </div>
        </>
      );
      break;
    case "Money Owed To You":
      trailLink1 = "Cash Flow";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/cash/";
      dynamicFirstComponent = (
        <LineChart
          currencySymbol={currencySymbol}
          companyData={moneyOwedToYouLineGraphData}
          company={organisation}
          title={"Money Owed To You"}
        />
      );
      dynamicSecondComponent = (
        <>
          <p className="font-medium text-md mt-5 text-gray-600">
            Accounts Payable/Accounts Receivable{" "}
          </p>
          <div class="mx-0 ">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() =>
                    toggleKPI("Number of outstanding sales invoices")
                  }
                  isCustomSymbol={false}
                  title="Number of outstanding sales invoices"
                  data={numOutstandingSalesInvoicesFigureData}
                  value={numOutstandingSalesInvoicesFigureData?.this_month}
                />
              </div>
              <div class=" flex rounded-md">
                <BoxCard03
                  toggleKPI={() =>
                    toggleKPI("Number of outstanding sales credit notes")
                  }
                  isCustomSymbol={false}
                  title="Number of outstanding sales credit notes"
                  data={numOutstandingSalesCreditNotesFigureData}
                  value={numOutstandingSalesCreditNotesFigureData?.this_month}
                />
              </div>
              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() =>
                    toggleKPI("Number of outstanding overdue sales invoices")
                  }
                  isCustomSymbol={false}
                  title="Number of outstanding overdue sales invoices"
                  data={numOutstandingOverdueSalesInvoicesFigureData}
                  value={
                    numOutstandingOverdueSalesInvoicesFigureData?.this_month
                  }
                />
              </div>
              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() =>
                    toggleKPI("Number of outstanding sales overpayments")
                  }
                  isCustomSymbol={false}
                  title="Number of outstanding sales overpayments"
                  data={numOutstandingSalesOverpaymentsFigureData}
                  value={numOutstandingSalesOverpaymentsFigureData?.this_month}
                />
              </div>
              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() =>
                    toggleKPI("Number of outstanding sales prepayments")
                  }
                  isCustomSymbol={false}
                  title="Number of outstanding sales prepayments"
                  data={numOutstandingSalesPrepaymentsFigureData}
                  value={numOutstandingSalesPrepaymentsFigureData?.this_month}
                />
              </div>
              <div class=" flex rounded-md">
                <BoxCard03
                  toggleKPI={() =>
                    toggleKPI("Outstanding sales credit note value")
                  }
                  isCustomSymbol={false}
                  title="Outstanding sales credit note value"
                  data={outstandingSalesCreditNoteValueFigureData}
                  value={outstandingSalesCreditNoteValueFigureData?.this_month}
                />
              </div>
              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() =>
                    toggleKPI("Outstanding sales prepayment value")
                  }
                  isCustomSymbol={false}
                  title="Outstanding sales prepayment value"
                  data={outstandingSalesPrepaymentValueFigureData}
                  value={outstandingSalesPrepaymentValueFigureData?.this_month}
                />
              </div>
              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() =>
                    toggleKPI("Outstanding overdue sales invoice value")
                  }
                  isCustomSymbol={false}
                  title="Outstanding overdue sales invoice value"
                  data={outstandingOverdueSalesInvoiceValueFigureData}
                  value={
                    outstandingOverdueSalesInvoiceValueFigureData?.this_month
                  }
                />
              </div>
              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() =>
                    toggleKPI("Outstanding sales overpayment value")
                  }
                  isCustomSymbol={false}
                  title="Outstanding sales overpayment value"
                  data={outstandingSalesOverpaymentValueFigureData}
                  value={outstandingSalesOverpaymentValueFigureData?.this_month}
                />
              </div>
              <div class="flex rounded-md">
                <BoxCard03
                  toggleKPI={() => toggleKPI("Outstanding sales invoice value")}
                  isCustomSymbol={false}
                  title="Outstanding sales invoice value"
                  data={outstandingSalesInvoiceValueFigureData}
                  value={outstandingSalesInvoiceValueFigureData?.this_month}
                />
              </div>
            </div>
          </div>
        </>
      );
      break;
    case "Gross Profit":
      trailLink1 = "Profit & Loss";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/profit-and-loss/";
      dynamicFirstComponent = (
        <>
          <LineChart
            currencySymbol={currencySymbol}
            companyData={grossProfitLineGraphData}
            company={organisation}
            title={"Gross Profit and Previous Year"}
          />
        </>
      );
      break;
    case "Cash Spent":
      trailLink1 = "Cash Flow";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/cash/";
      dynamicFirstComponent = (
        <>
          <LineChart
            currencySymbol={currencySymbol}
            companyData={cashSpentLineGraphData}
            company={organisation}
            title={"Cash Spent of"}
          />
        </>
      );
      break;
    case "Cash Received":
      trailLink1 = "Cash Flow";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/cash/";
      dynamicFirstComponent = (
        <>
          <LineChart
            currencySymbol={currencySymbol}
            companyData={cashReceivedLineGraphData}
            company={organisation}
            title={"Cash Received of"}
          />
        </>
      );
      break;
    case "Gross Profit Margin":
      trailLink1 = "Profit & Loss";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/profit-and-loss/";
      dynamicFirstComponent = (
        <>
          <LineChart
            currencySymbol={"%"}
            companyData={grossProfitMarginLineGraphData}
            company={organisation}
            title={"Gross Profit Margin of"}
          />
        </>
      );
      break;
    case "Operating Margin":
      trailLink1 = "Profit & Loss";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/profit-and-loss/";
      dynamicFirstComponent = (
        <>
          <LineChart
            currencySymbol={"%"}
            companyData={operatingCostPercentageRevenueLineGraphData}
            company={organisation}
            title={"Operating Cost Percentage Revenue Data of "}
          />
        </>
      );
      break;
    case "Cost of Sales":
      trailLink1 = "Profit & Loss";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/profit-and-loss/";
      dynamicFirstComponent = (
        <LineChart
          currencySymbol={currencySymbol}
          companyData={totalCostOfSalesLineGraphData}
          company={organisation}
          title={"Total Cost of Sales"}
        />
      );
      break;
    case "Net Profit":
      trailLink1 = "Profit & Loss";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/profit-and-loss/";
      dynamicFirstComponent = (
        <LineChart
          currencySymbol={currencySymbol}
          companyData={netProfitLineGraphData}
          company={organisation}
          title={"Net Profit of"}
        />
      );
      break;
    case "Net Profit Margin":
      trailLink1 = "Profit & Loss";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/profit-and-loss/";
      dynamicFirstComponent = (
        <LineChart
          currencySymbol={"%"}
          companyData={netProfitMarginLineGraphData}
          company={organisation}
          title={"Net Profit Margin of"}
        />
      );
      break;
    case "Total Operating Expenses":
      trailLink1 = "Profit & Loss";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/profit-and-loss/";
      dynamicFirstComponent = (
        <LineChart
          currencySymbol={currencySymbol}
          companyData={totalOperatingExpensesLineGraphData}
          company={organisation}
          title={"Total Operating Expenses of "}
        />
      );
      break;
    case "Current Assets Less Liabilities":
      trailLink1 = "Balance Sheet";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/balance-sheet/";
      dynamicFirstComponent = (
        <div className="w-full gap-2 flex flex-col">
          <LineChart
            currencySymbol={currencySymbol}
            companyData={combinedAssetsLiabilitiesLineGraphData}
            company={organisation}
            title={"Current Assets Less Current Liabilities of"}
          />
          <LineChart
            currencySymbol={currencySymbol}
            companyData={currentAssetsLineGraphData}
            company={organisation}
            title={"Current Assets of"}
          />
          <LineChart
            currencySymbol={currencySymbol}
            companyData={currentLiabilitiesLineGraphData}
            company={organisation}
            title={"Current Liabilities of"}
          />
        </div>
      );
      break;
    case "Current Liabilities":
      trailLink1 = "Balance Sheet";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/balance-sheet/";
      dynamicFirstComponent = (
        <div className="w-full gap-2 flex flex-col">
          <LineChart
            currencySymbol={currencySymbol}
            companyData={currentLiabilitiesLineGraphData}
            company={organisation}
            title={"Current Liabilities of"}
          />
        </div>
      );
      break;
    case "Current Assets":
      trailLink1 = "Balance Sheet";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/balance-sheet/";
      dynamicFirstComponent = (
        <div className="w-full gap-2 flex flex-col">
          <LineChart
            currencySymbol={currencySymbol}
            companyData={currentAssetsLineGraphData}
            company={organisation}
            title={"Current Assets of"}
          />
        </div>
      );
      break;
    case "Operating Cost Expenses":
      dynamicFirstComponent = (
        <LineChart
          currencySymbol={currencySymbol}
          companyData={operatingCostPercentageRevenueLineGraphData}
          company={organisation}
          title={"Operating Cost Percentage of Revenue"}
        />
      );
      break;

    case "Total Bank":
      trailLink1 = "Cash Flow";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/cash/";
      dynamicFirstComponent = (
        <>
          <LineChart
            currencySymbol={currencySymbol}
            companyData={totalBankLineGraphData}
            company={organisation}
            title={"Total Bank of"}
          />

          <BoxTableCard01
            isDateFilters={true}
            title={xeroBankTable?.[0]?.kpi || "Bank Table"}
            tooltip={
              xeroBankTable?.[0]?.description || "List of Bank Balances in Xero"
            }
            isWidthFull={true}
            isLoading={isLoadingKPI}
            tableData={xeroBankTable?.[0]?.data}
          />

          <div className="mx-0 mt-5 ">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
              <div className="rounded-md">
                <BoxCard01
                  currencySymbol={''}
                  isCustomSymbol={true}
                  toggleKPI={() =>
                    toggleKPI(
                      totalUnreconciledItemsFigureData?.KPI ||
                      "Total unreconciled items"
                    )
                  }
                  title={
                    totalUnreconciledItemsFigureData?.KPI ||
                    "Total unreconciled items"
                  }
                  data={totalUnreconciledItemsFigureData}
                  savedFilterA={totalUnreconciledItemsFilters.firstFilter}
                  savedFilterB={totalUnreconciledItemsFilters.comparedTo}
                  sparkLine01Data={{}}
                  sparkLine02Data={{}}
                />
              </div>
            </div>
          </div>
        </>
      );
      break;
    case "Average Creditor Days":
      trailLink1 = "Balance Sheet";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/balance-sheet/";
      dynamicFirstComponent = (
        <LineChart
          currencySymbol={""}
          companyData={averageCreditorDaysLineGraphData}
          company={organisation}
          title={"Average Creditor Days"}
        />
      );
      break;
    case "Total Costs":
      trailLink1 = "Profit & Loss";
      trailLink2 = kpiName;
      trailPath1 = "/dashboard/profit-and-loss/";
      dynamicFirstComponent = (
        <LineChart
          currencySymbol={currencySymbol}
          companyData={totalCostsLineGraphData}
          company={organisation}
          title={"Total Costs"}
        />
      )
      break;
    default:
      dynamicFirstComponent = null;
  }
  return (
    <div className="w-full h-full px-5 py-5 bg-light-white flex flex-col">
      <div className="bg-white flex-1 p-5 border-2 border-light-grey mt-5 justify-center items-start">
        <button
          className="flex items-center justify-center hover:underline gap-2 my-3 text-sm text-grey"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeftLong />
          Go back
        </button>
        <DashboardBanner
          title={`Details of ${kpiName}`}
        // isGlobalFilterRequired={false}
        // isDearBrandFilters={kpiName=="Product Sales"? true:false}
        />
        <div className="flex flex-row gap-2 text-sm text-grey">
          <Link className="hover:underline" to={trailPath1}>
            <p>{trailLink1}</p>
          </Link>
          {`>`}
          <Link className="hover:underline">{trailLink2}</Link>
        </div>
        <div className="relative min-h-[500px] flex gap-8">
          <div className="flex flex-col w-2/3">
            <div className="">{dynamicFirstComponent}</div>
            {/* <div>
              <div className=" flex flex-col gap-2   w-full h-24">
                <p className="font-bold text-md ">Comments/Notes</p>
                <div className="flex items-center  justify-between gap-2">
                  <input
                    type="text"
                    className="w-11/12 bg-light-white border-0 p-2 font-light text-[12px] text-grey"
                    placeholder="Add Comments"
                  />

                  <div className="w-1/12 h-full flex items-center justify-center border-[0.3px] border-light-white cursor-pointer">
                    <AiFillFile />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="">{dynamicSecondComponent}</div>
          </div>
          <div className="sticky max-h-[660px] right-10 top-0 w-1/3 mb-3  ">
            <KPIChatComponent />
          </div>
        </div>
      </div>
    </div>
  );
}

export default KPIDetailsPage;