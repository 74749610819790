import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import BoxCard01 from "../../../components/ui-elements/cards/box-card-01";
import BoxCard03 from "../../../components/ui-elements/cards/box-card-03";
import LineChart from "../../../components/graphs/react-chartjs-two/line-chart";
import DashboardBanner from "../../../components/ui-elements/banner/dashbaord-banner";
import KPISheetModal from "../../../components/ui-elements/modals/kpis-modals/kpi-sheet-modal";
import StackedBarChart from "../../../components/graphs/react-chartjs-two/stacked-bar-chart";
import GraphData from "./graphs";
import BoxCard02 from "../../../components/ui-elements/cards/box-card-02";
import HorizontalBarChart from "../../../components/graphs/react-chartjs-two/horizontal-bar-chart-dear";
import { Link } from "react-router-dom";
import LineChartDear from "../../../components/graphs/react-chartjs-two/line-chart-dear";
import SpinnerKPI from "../../../components/ui-elements/utilis/spinner-kpi";
import countryToSymbolMap from "../../../../constant/country-code-currency.json";
import KPIDrillDownModal from "../../../components/ui-elements/modals/kpis-modals/drill-down-modal";
import BoxTableCard01 from "../../../components/ui-elements/cards/table-cards/box-table-card-01";

function DashboardRevenuePage(props) {
  const {
    xeroKPIFigures,
    dearProductSales,
    dearProfit,
    dearUnitsSold,
    dearUnitsShipped,
    dearPercentageCustomer80Sales,
    dearPercentageCustomer80SalesDrilldown,
    dearPercentageOrderShippedLate,
    dearGrossProfitMargin,
    dearPercentageProduct80Sales,
    dearPercentageProduct80SalesDrilldown,
    dearSampleConversionRate,
    dearLoadingStatuses,
    dearProductsWithZeroSales,
    dearCostOfGoodsSold,
    dearSampleShipped,
  } = useSelector((state) => state.kpi);
  const { organisation } = useSelector((state) => state.organisation);
  const countryCode =
    organisation && organisation.country_alpha3
      ? organisation.country_alpha3.replace(/"/g, "")
      : null;
  const currencySymbol = countryCode ? countryToSymbolMap[countryCode] : null;
  // GRAPHS
  const {
    totalIncomeData,
    totalIncomeLineGraphData,
    otherRevenueData,
    dearUnitsSoldLineData,
    otherRevenueLineGraphData,
    totalIncomeBarChartData,
    totalIncomeStackedBarChartData,
    totalIncomeData02,
    totalIncomeStackedBarChartData02,
    profitLineGraphData,
    productSalesLineGraphData,
    dearProfitLineData,
    dearProductSalesLineData,
    salesByBrandsChartData,
    salesByBrand,
    salesByProductChartData,
    salesByCategoryChartData,
    salesByCustomerChartData,
    salesByProductTagChartData,
    salesByProductTypeChartData,
    salesByCustomerTagChartData,
    profitByCategoryChartData,
    profitByProductChartData,
    profitByBrandChartData,
    profitByCustomerChartData,
    unitsSoldByCategoryChartData,
    unitsSoldByBrandsChartData,
    unitsSoldByCustomerChartData,
    unitsSoldLineGraphData,
    dearUnitsShippedLineData,
  } = GraphData();
  // BOX KPIs


  const getKPIFigure = (kpiName) => {
    if (Array.isArray(xeroKPIFigures)) {
      const figure = xeroKPIFigures.find((item) => item.KPI === kpiName);
      return figure || null; // Return null if the figure is not found
    } else {
      return null; // Return null if xeroKPIFigures is not an array
    }
  };
  const revenueFigureData = getKPIFigure("Revenue");
  const otherIncomeFigureData = getKPIFigure("Other Income");
  const totalCustomersFigureData = getKPIFigure("Total Customers");


  // Spark Lines
  function filterLastMonthsData(data, months = 6) {
    if (Array.isArray(data)) {
      return data.slice(0, months);
    } else {
      return [];
    }
  }

  const extractSparkLineData = (data, key) =>
    data.map((entry) => entry[key] || 0);
  function generateSparkLineData(rawData, key) {
    if (!Array.isArray(rawData) || rawData.length === 0) {
      return [];
    }
    // Sort the data by month in descending order
    const sortedData = rawData.slice().sort((a, b) => {
      if (a.month < b.month) return 1;
      if (a.month > b.month) return -1;
      return 0;
    });

    // Extract the last 6 months' data and reverse it
    const last6MonthsData = filterLastMonthsData(sortedData).reverse();
    const sparkLine = extractSparkLineData(last6MonthsData, key);
    return sparkLine; // Return sparkLine array directly without adding 0
  }

  // Spark Lines for Revenue
  const revenueSpark = totalIncomeData?.total_income || [];
  const sparkLine01RevenueData = generateSparkLineData(
    revenueSpark,
    "total_income"
  );
  const sparkLine02RevenueData = generateSparkLineData(
    revenueSpark,
    "previous_year_total_income"
  );
  const dearProfitSpark = dearProfitLineData || [];
  const sparkLine01DearProfitData = generateSparkLineData(
    dearProfitSpark,
    "current_year_profit"
  );
  const sparkLine02DearProfitData = generateSparkLineData(
    dearProfitSpark,
    "previous_year_profit"
  );
  const dearProductSalesSpark = dearProductSalesLineData || [];
  const sparkLine01DearProductSalesData = generateSparkLineData(
    dearProductSalesSpark,
    "current_year_sales"
  );
  const sparkLine02DearProductSalesData = generateSparkLineData(
    dearProductSalesSpark,
    "previous_year_sales"
  );

  const dearUnitsSoldSpark = dearUnitsSoldLineData || [];

  const sparkLine01DearUnitsSoldData = generateSparkLineData(
    dearUnitsSoldSpark,
    "current_year_units_sold"
  );
  const sparkLine02DearUnitsSoldData = generateSparkLineData(
    dearUnitsSoldSpark,
    "previous_year_sales"
  );


  const extractFilters = (kpiTitle) => {
    const savedFilters = organisation?.saved_filters[organisation?.name];
    const filter = savedFilters?.find((filter) => filter.kpi === kpiTitle);
    if (filter) {
      return {
        firstFilter: filter.first_filter,
        comparedTo: filter.compared_to,
      };
    }
    return { firstFilter: null, comparedTo: null };
  };
  const revenueFilters = extractFilters("Revenue");
  const otherIncomeFilters = extractFilters("Other Income");
  const productSalesFilters = extractFilters("Product Sales");
  const profitFilters = extractFilters("Gross Profit");
  const unitsSoldFilters = extractFilters("Units Sold");
  const grossProfitMarginFilters = extractFilters("Gross Profit Margin");
  const sampleConversionFilters = extractFilters("Sample Conversion Rate");
  const percentageCustomer80SalesFilters = extractFilters(
    "% of Customers Who Make Up 80% of Sales"
  );
  const percentageProduct80SalesFilters = extractFilters(
    "% of Products That Make Up 80% of Sales"
  );
  const totalCustomersSavedFilters = extractFilters("Total Customers");
  const dearCostOfGoodsSoldFilters = extractFilters("Cost of Goods Sold");

  // KPI Sheet

  const [toggleKPISheet, setKPISheetToggle] = useState(false);
  const [kpiSheetTitle, setKPISheetTitle] = useState("");

  const toggleKPI = (title) => {
    setKPISheetTitle(title);
    setKPISheetToggle(!toggleKPISheet);
  };

  const [toggleKPIDrillDownModal, setKPIDrillDownModal] = useState(false);
  const [drilldownTitle, setDrilldownTitle] = useState("");
  const [drilldownTooltip, setDrilldownTooltip] = useState("");
  const [drilldownTableData, setDrilldownTableData] = useState([]);

  const toggleDrillDownModalSheet = (title, tooltip, data) => {
    setKPIDrillDownModal(!toggleKPIDrillDownModal);
    setDrilldownTitle(title);
    setDrilldownTooltip(tooltip);
    setDrilldownTableData(data);
  };

  // Advanced Filters
  const [isAdvancedDearFilters, setIsAdvancedDearFilters] = useState(false);
  const [openKPIAdvancedFilters, setOpenKPIAdvancedFilters] = useState({
    "Product Sales": false,
    'Gross Profit': false,
    'Gross Profit Margin': false,
    "Units Sold": false,
    "% of Customers Who Make Up 80% of Sales": false,
    "% of Products That Make Up 80% of Sales": false,
    "Cost of Goods Sold": false,
  });

  const toggleAdvancedFilters = (title) => {
    setOpenKPIAdvancedFilters((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  const xeroKPIComponent = (
    <div className="mx-0 ">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <div className="rounded-md">
          <BoxCard01
            isCustomSymbol={false}
            toggleKPI={() => toggleKPI(revenueFigureData?.KPI || "Revenue")}
            title={revenueFigureData?.KPI || "Revenue"}
            data={revenueFigureData}
            savedFilterA={revenueFilters.firstFilter}
            savedFilterB={revenueFilters.comparedTo}
            sparkLine01Data={sparkLine01RevenueData}
            sparkLine02Data={sparkLine02RevenueData}
          />
        </div>
        <div className="rounded-md">
          <BoxCard01
            isCustomSymbol={false}
            toggleKPI={() => toggleKPI(otherIncomeFigureData?.KPI || "Other Income")}
            title={otherIncomeFigureData?.KPI || "Other Income"}
            data={otherIncomeFigureData}
            savedFilterA={otherIncomeFilters.firstFilter}
            savedFilterB={otherIncomeFilters.comparedTo}
            sparkLine01Data={{}}
            sparkLine02Data={{}}
          />
        </div>

      </div>
    </div>
  );
  const dearKPIComponent = (
    <div class="mx-0 mt-5 ">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <div className=" rounded-md">
          <BoxCard02
            isLoadingDearKPI={dearLoadingStatuses.dearProductSales}
            isAdvancedDearFilters={
              openKPIAdvancedFilters[
              dearProductSales?.results?.[0]?.kpi || "Product Sales"
              ]
            }
            setIsAdvancedDearFilters={(value) =>
              toggleAdvancedFilters(
                dearProductSales?.results?.[0]?.kpi || "Product Sales",
                value
              )
            }
            isCustomSymbol={false}
            toggleKPI={() =>
              toggleKPI(dearProductSales?.results?.[0]?.kpi || "Product Sales")
            }
            title={dearProductSales?.results?.[0]?.kpi || "Product Sales"}
            tooltip={
              dearProductSales?.results?.[0]?.description ||
              "The total sum of product lines in sales orders minus credit notes, not including tax in Cin7 Core"
            }
            data={
              dearProductSales &&
                dearProductSales.results &&
                dearProductSales.results?.[0]?.data?.length > 0
                ? dearProductSales.results?.[0]?.data[0]
                : null
            }
            savedFilterA={productSalesFilters.firstFilter}
            savedFilterB={productSalesFilters.comparedTo}
            sparkLine01Data={sparkLine01DearProductSalesData}
            sparkLine02Data={sparkLine02DearProductSalesData}
          />
        </div>
        <div className=" rounded-md">
          <BoxCard02
            isLoadingDearKPI={dearLoadingStatuses.dearProfit}
            isAdvancedDearFilters={
              openKPIAdvancedFilters[
              dearProfit?.results?.[0]?.kpi || "Gross Profit"
              ]
            }
            setIsAdvancedDearFilters={(value) =>
              toggleAdvancedFilters(
                dearProfit?.results?.[0]?.kpi || "Gross Profit",
                value
              )
            }
            isCustomSymbol={false}
            toggleKPI={() =>
              toggleKPI(dearProfit?.results?.[0]?.kpi || "Gross Profit")
            }
            title={dearProfit?.results?.[0]?.kpi || "Gross Profit"}
            tooltip={
              dearProfit?.results?.[0]?.description ||
              "Sum of net sales minus average costs for products in Cin7 Core"
            }
            data={
              dearProfit &&
                dearProfit.results &&
                dearProfit.results?.[0]?.data?.length > 0
                ? dearProfit.results?.[0]?.data[0]
                : null
            }
            savedFilterA={profitFilters.firstFilter}
            savedFilterB={profitFilters.comparedTo}
            sparkLine01Data={sparkLine01DearProfitData}
            sparkLine02Data={sparkLine02DearProfitData}
          />
        </div>
        <div className=" rounded-md">
          <BoxCard02
            isLoadingDearKPI={dearLoadingStatuses.dearOtherKPIs}
            isAdvancedDearFilters={
              openKPIAdvancedFilters[
              dearGrossProfitMargin?.results?.[0]?.kpi ||
              "Gross Profit Margin"
              ]
            }
            setIsAdvancedDearFilters={(value) =>
              toggleAdvancedFilters(
                dearGrossProfitMargin?.results?.[0]?.kpi ||
                "Gross Profit Margin",
                value
              )
            }
            isCustomSymbol={true}
            customSymbol={"%"}
            title={
              dearGrossProfitMargin?.results?.[0]?.kpi || "Gross Profit Margin"
            }
            tooltip={
              dearGrossProfitMargin?.results?.[0]?.description ||
              "Gross profit divided by total revenue in Cin7 Core"
            }
            data={
              dearGrossProfitMargin &&
                dearGrossProfitMargin.results &&
                dearGrossProfitMargin.results?.[0]?.data?.length > 0
                ? dearGrossProfitMargin.results?.[0]?.data[0]
                : null
            }
            savedFilterA={grossProfitMarginFilters.firstFilter}
            savedFilterB={grossProfitMarginFilters.comparedTo}
            sparkLine01Data={""}
            sparkLine02Data={""}
          />
        </div>
        <div className=" rounded-md">
          <BoxCard02
            isLoadingDearKPI={dearLoadingStatuses.dearUnitsSold}
            isAdvancedDearFilters={
              openKPIAdvancedFilters[
              dearUnitsSold?.results?.[0]?.kpi || "Units Sold"
              ]
            }
            setIsAdvancedDearFilters={(value) =>
              toggleAdvancedFilters(
                dearUnitsSold?.results?.[0]?.kpi || "Units Sold",
                value
              )
            }
            isCustomSymbol={true}
            customSymbol={" "}
            toggleKPI={() =>
              toggleKPI(dearUnitsSold?.results?.[0]?.kpi || "Units Sold")
            }
            title={dearUnitsSold?.results?.[0]?.kpi || "Units Sold"}
            tooltip={
              dearUnitsSold?.results?.[0]?.description ||
              "Number of units of products sold in Cin7 Core"
            }
            data={
              dearUnitsSold &&
                dearUnitsSold.results &&
                dearUnitsSold.results?.[0]?.data?.length > 0
                ? dearUnitsSold.results?.[0]?.data[0]
                : null
            }
            savedFilterA={unitsSoldFilters.firstFilter}
            savedFilterB={unitsSoldFilters.comparedTo}
            sparkLine01Data={sparkLine01DearUnitsSoldData}
            sparkLine02Data={sparkLine02DearUnitsSoldData}
          />
        </div>
        <div class=" rounded-md">
          <BoxCard02
            isLoadingDearKPI={dearLoadingStatuses.dearOtherKPIs}
            isAdvancedDearFilters={
              openKPIAdvancedFilters[
              dearCostOfGoodsSold?.results?.[0]?.kpi || "Cost of Goods Sold"
              ]
            }
            setIsAdvancedDearFilters={(value) =>
              toggleAdvancedFilters(
                dearCostOfGoodsSold?.results?.[0]?.kpi || "Cost of Goods Sold",
                value
              )
            }
            isCustomSymbol={false}
            title={
              dearCostOfGoodsSold?.results?.[0]?.kpi || "Cost of Goods Sold"
            }
            tooltip={
              dearCostOfGoodsSold?.results?.[0]?.description ||
              "Sum of inventory movements in Cin7 Core"
            }
            data={
              dearCostOfGoodsSold &&
                dearCostOfGoodsSold.results &&
                dearCostOfGoodsSold.results?.[0]?.data?.length > 0
                ? dearCostOfGoodsSold.results?.[0]?.data[0]
                : null
            }
            savedFilterA={dearCostOfGoodsSoldFilters.firstFilter}
            savedFilterB={dearCostOfGoodsSoldFilters.comparedTo}
            sparkLine01Data={{}}
            sparkLine02Data={{}}
          />
        </div>
        <div className=" rounded-md">
          <BoxCard02
            isLoadingDearKPI={dearLoadingStatuses.dearOtherKPIs}
            isCustomSymbol={true}
            customSymbol={"%"}
            toggleKPI={() =>
              toggleKPI(dearSampleConversionRate?.results?.[0]?.kpi || "Sample Conversion Rate")
            }
            title={
              dearSampleConversionRate?.results?.[0]?.kpi ||
              "Sample Conversion Rate"
            }
            tooltip={
              dearSampleConversionRate?.results?.[0]?.description ||
              "Percentage of customers who were shipped a sample and subsequently made a purchase in Cin7 Core"
            }
            data={
              dearSampleConversionRate &&
                dearSampleConversionRate.results &&
                dearSampleConversionRate.results?.[0]?.data?.length > 0
                ? dearSampleConversionRate.results?.[0]?.data[0]
                : null
            }
            savedFilterA={sampleConversionFilters.firstFilter}
            savedFilterB={sampleConversionFilters.comparedTo}
            sparkLine01Data={""}
            sparkLine02Data={""}
          />
        </div>
        <div class=" rounded-md">
          <BoxCard02
            isLoadingDearKPI={dearLoadingStatuses.dearOtherKPIs}
            isAdvancedDearFilters={
              openKPIAdvancedFilters[
              dearPercentageProduct80Sales?.results?.[0]?.kpi ||
              "% of Products That Make Up 80% of Sales"
              ]
            }
            setIsAdvancedDearFilters={(value) =>
              toggleAdvancedFilters(
                dearPercentageProduct80Sales?.results?.[0]?.kpi ||
                "% of Products That Make Up 80% of Sales",
                value
              )
            }
            customSymbol={"% "}
            isCustomSymbol={true}
            toggleKPI={() =>
              toggleDrillDownModalSheet(
                dearPercentageProduct80SalesDrilldown?.results?.[0]?.kpi ||
                "% of Products That Make Up 80% of Sales Drilldown",
                dearPercentageProduct80SalesDrilldown?.results?.[0]
                  ?.description ||
                "The % of total sales by product in Cin7 Core",
                dearPercentageProduct80SalesDrilldown?.results?.[0]?.data
              )
            }
            title={
              dearPercentageProduct80Sales?.results?.[0]?.kpi ||
              "% of Products That Make Up 80% of Sales"
            }
            tooltip={
              dearPercentageProduct80Sales?.results?.[0]?.description ||
              "Percentage of products necessary to reach 80% of the total sales in Cin7 Core"
            }
            data={
              dearPercentageProduct80Sales &&
                dearPercentageProduct80Sales.results &&
                dearPercentageProduct80Sales.results?.[0]?.data?.length > 0
                ? dearPercentageProduct80Sales.results?.[0]?.data[0]
                : null
            }
            savedFilterA={percentageProduct80SalesFilters.firstFilter}
            savedFilterB={percentageProduct80SalesFilters.comparedTo}
            sparkLine01Data={{}}
            sparkLine02Data={{}}
          />
        </div>
        <div class=" rounded-md">
          {/* {JSON.stringify(dearPercentageCustomer80SalesDrilldown)} */}
          <BoxCard02
            isLoadingDearKPI={dearLoadingStatuses.dearOtherKPIs}
            isAdvancedDearFilters={
              openKPIAdvancedFilters[
              dearPercentageCustomer80Sales?.results?.[0]?.kpi ||
              "% of Customers Who Make Up 80% of Sales"
              ]
            }
            setIsAdvancedDearFilters={(value) =>
              toggleAdvancedFilters(
                dearPercentageCustomer80Sales?.results?.[0]?.kpi ||
                "% of Customers Who Make Up 80% of Sales",
                value
              )
            }
            customSymbol={"% "}
            isCustomSymbol={true}
            toggleKPI={() =>
              toggleDrillDownModalSheet(
                dearPercentageCustomer80SalesDrilldown?.results?.[0]?.kpi ||
                "% of Customers Who Make Up 80% of Sales Drilldown",
                dearPercentageCustomer80SalesDrilldown?.results?.[0]
                  ?.description ||
                "The % of total sales by customer in Cin7 Core",
                dearPercentageCustomer80SalesDrilldown?.results?.[0]?.data
              )
            }
            title={
              dearPercentageCustomer80Sales?.results?.[0]?.kpi ||
              "% of Customers Who Make Up 80% of Sales"
            }
            tooltip={
              dearPercentageCustomer80Sales?.results?.[0]?.description ||
              "Percentage of customers necessary to reach 80% of the total sales in Cin7 Core"
            }
            data={
              dearPercentageCustomer80Sales &&
                dearPercentageCustomer80Sales.results &&
                dearPercentageCustomer80Sales.results?.[0]?.data?.length > 0
                ? dearPercentageCustomer80Sales.results?.[0]?.data[0]
                : null
            }
            savedFilterA={percentageCustomer80SalesFilters.firstFilter}
            savedFilterB={percentageCustomer80SalesFilters.comparedTo}
            sparkLine01Data={{}}
            sparkLine02Data={{}}
          />
        </div>
        <div class="rounded-md">
          <BoxCard01
            toggleKPI={false}
            customSymbol={""}
            isCustomSymbol={true}
            title="Total Customers"
            data={totalCustomersFigureData}
            savedFilterA={totalCustomersSavedFilters.firstFilter}
            savedFilterB={totalCustomersSavedFilters.comparedTo}
            sparkLine01Data={""}
            sparkLine02Data={""}
          />
        </div>
      </div>
      <div class="mx-0 mt-5 flex flex-row gap-2">
        <BoxTableCard01
          isDateFilters={true}
          title={
            dearProductsWithZeroSales?.results?.[0]?.kpi ||
            "Products With 0 Sales"
          }
          tooltip={
            dearProductsWithZeroSales?.results?.[0]?.description ||
            "List of products with 0 sales in Cin7 Core"
          }
          isLoading={dearLoadingStatuses.dearOtherKPIs}

          tableData={dearProductsWithZeroSales?.results?.[0]?.data}
          isWidthThird={true}
        />
      </div>
    </div>
  );

  const displayKPIComponent = (item) => {
    switch (item) {
      case "XERO":
        return xeroKPIComponent;
      case "DEAR":
        return dearKPIComponent;
      default:
        return "Unknown";
    }
  };

  return (
    <div className="w-full h-full px-5 py-5 bg-light-white flex flex-col">
      <div
        className={
          isAdvancedDearFilters
            ? ` flex-1  p-5 border-2 border-light-grey mt-5 justify-center items-start`
            : `bg-white flex-1  p-5 border-2 border-light-grey mt-5 justify-center items-start`
        }
      >
        <DashboardBanner title="Revenue" isGlobalFilterRequired={false} />
        <KPISheetModal
          toggle={toggleKPISheet}
          toggleModal={toggleKPI}
          kpiName={kpiSheetTitle}
        >
          <p className="font-bold text-lg">{kpiSheetTitle}</p>
          {kpiSheetTitle === "Revenue" && (
            <LineChart
              currencySymbol={currencySymbol}
              companyData={totalIncomeLineGraphData}
              company={organisation}
              title={"Total Revenue of "}
            />
          )}

          {kpiSheetTitle === "Other Revenues" && (
            <LineChart
              currencySymbol={currencySymbol}
              companyData={otherRevenueLineGraphData}
              company={organisation}
              title={"Other Revenue of"}
            />
          )}
          {kpiSheetTitle === "Sample Conversion Rate" && (
            <div class=" rounded-md mt-5">
              <BoxCard02
                isLoadingDearKPI={dearLoadingStatuses.dearOtherKPIs}
                isAdvancedDearFilters={openKPIAdvancedFilters[dearSampleShipped?.results?.[0]?.kpi || 'Number of Shipments of Samples to Customers']}
                setIsAdvancedDearFilters={(value) =>
                  toggleAdvancedFilters(dearSampleShipped?.results?.[0]?.kpi || 'Number of Shipments of Samples to Customers', value)
                }
                isCustomSymbol={true}
                customSymbol={''}
                title={dearSampleShipped?.results?.[0]?.kpi || 'Number of Shipments of Samples to Customers'}
                tooltip={dearSampleShipped?.results?.[0]?.description || 'Number of customers who have been shipped a product with sample in the name in Cin7 Core'}
                data={
                  dearSampleShipped &&
                    dearSampleShipped.results &&
                    dearSampleShipped.results?.[0]?.data?.length > 0
                    ? dearSampleShipped.results?.[0]?.data[0]
                    : null
                }
                savedFilterA={dearSampleShipped.firstFilter}
                savedFilterB={dearSampleShipped.comparedTo}
                sparkLine01Data={{}}
                sparkLine02Data={{}}
              />
            </div>
          )}
          {kpiSheetTitle === "Product Sales" && (
            <>
              <LineChartDear
                currencySymbol={currencySymbol}
                companyData={productSalesLineGraphData}
                company={organisation}
                title={"Product Sales of"}
              />
              <HorizontalBarChart
                currencySymbol={currencySymbol}
                data={salesByBrandsChartData}
                title={"Top 10 Brands By Sales"}
              />
              <HorizontalBarChart
                currencySymbol={currencySymbol}
                data={salesByCategoryChartData}
                title={"Top 10 Categories By Sales"}
              />
              <div className=" relative">
                <HorizontalBarChart
                  currencySymbol={currencySymbol}
                  data={salesByCustomerChartData}
                  title={"Top 10 Customers By Sales"}
                />
                <div className="absolute inset-0 bg-opacity-50 blur-sm bg-gray-100  backdrop-filter backdrop-blur-xm"></div>
                <div className="absolute inset-0 flex items-center justify-center text-gray-600">
                  <Link
                    to={{
                      pathname: `/details/${kpiSheetTitle}`,
                    }}
                    className="text-white text-sm bg-gray-500 rounded-sm py-2 px-6"
                  >
                    + Load More
                  </Link>
                </div>
              </div>
            </>
          )}
          {kpiSheetTitle === "Gross Profit" && (
            <>
              <LineChartDear
                currencySymbol={currencySymbol}
                companyData={profitLineGraphData}
                company={organisation}
                title={"Profit Line Graph of "}
              />
              <HorizontalBarChart
                currencySymbol={currencySymbol}
                data={profitByBrandChartData}
                title={"Top 10 Brands By Gross Profit"}
              />
              <HorizontalBarChart
                currencySymbol={currencySymbol}
                data={profitByCategoryChartData}
                title={"Top 10 Categories By Gross Profit"}
              />
              <div className=" relative">
                <HorizontalBarChart
                  currencySymbol={currencySymbol}
                  data={profitByCustomerChartData}
                  title={"Top 10 Customers By Gross Profit"}
                />
                <div className="absolute inset-0 bg-opacity-50 blur-sm bg-gray-100  backdrop-filter backdrop-blur-xm"></div>
                <div className="absolute inset-0 flex items-center justify-center text-gray-600">
                  <Link
                    to={{
                      pathname: `/details/${kpiSheetTitle}`,
                    }}
                    className="text-white text-sm bg-gray-500 rounded-sm py-2 px-6"
                  >
                    + Load More
                  </Link>
                </div>
              </div>
            </>
          )}
          {kpiSheetTitle === "Units Sold" && (
            <>
              <LineChartDear
                currencySymbol={""}
                companyData={unitsSoldLineGraphData}
                company={organisation}
                title={"Units Sold by "}
              />
              <HorizontalBarChart
                currencySymbol={""}
                data={unitsSoldByBrandsChartData}
                title={"Top 10 Brands By Units Sold"}
              />
              <HorizontalBarChart
                currencySymbol={""}
                data={unitsSoldByCategoryChartData}
                title={"Top 10 Categories By Units Sold"}
              />
              <div className=" relative">
                <HorizontalBarChart
                  currencySymbol={""}
                  data={unitsSoldByCustomerChartData}
                  title={"Top 10 Customers By Units Sold"}
                />
                <div className="absolute inset-0 bg-opacity-50 blur-sm bg-gray-100  backdrop-filter backdrop-blur-xm"></div>
                <div className="absolute inset-0 flex items-center justify-center text-gray-600">
                  <Link
                    to={{
                      pathname: `/details/${kpiSheetTitle}`,
                    }}
                    className="text-white text-sm bg-gray-500 rounded-sm py-2 px-6"
                  >
                    + Load More
                  </Link>
                </div>
              </div>
            </>
          )}

          {kpiSheetTitle === "Income Tracking Category One" && <></>}
          {kpiSheetTitle === "Income Tracking Category Two" && <></>}
        </KPISheetModal>
        {/* Drilldown */}
        <KPIDrillDownModal
          tableData={drilldownTableData}
          drilldownTitle={drilldownTitle}
          drilldownTooltip={drilldownTooltip}
          toggle={toggleKPIDrillDownModal}
          toggleModal={toggleDrillDownModalSheet}
        />
        {organisation?.connected_apps &&
          organisation.connected_apps.map((item, index) => (
            <div key={index}>{displayKPIComponent(item)}</div>
          ))}
      </div>
    </div>
  );
}

export default DashboardRevenuePage;
