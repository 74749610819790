import { TrendingUp } from "lucide-react";
import { Bar, BarChart, XAxis, YAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../../shadcn-components/ui/card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../shadcn-components/ui/chart";

export const description = "A profit bar chart over time";

const result = [
  { month: "2023-10-01", profit: 140000.88 },
  { month: "2023-11-01", profit: 57934.67 },
  { month: "2023-12-01", profit: 31030.82 },
  { month: "2024-01-01", profit: 85309.86 },
  { month: "2024-02-01", profit: 107906.25 },
  { month: "2024-03-01", profit: 99183.33 },
  { month: "2024-04-01", profit: 128526.66 },
  { month: "2024-05-01", profit: 179110.09 },
  { month: "2024-06-01", profit: 179104.36 },
  { month: "2024-07-01", profit: 195379.81 },
  { month: "2024-08-01", profit: 206321.65 },
  { month: "2024-09-01", profit: 107295.61 },
];

const chartConfig = {
  profit: {
    label: "Profit",
    color: "#FAC850",
  },
};

export function BarChartAI() {
  return (
    <Card className="h-[450px] w-[500px]">
      <CardHeader>
        <CardTitle>Bar Chart - Profit Over Time</CardTitle>
        <CardDescription>October 2023 - September 2024</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart
            data={result}
            layout="horizontal"
            margin={{ left: 0 }}
            width={500}
            height={300}
          >
            <XAxis
              dataKey="month"
              tickFormatter={(value) =>
                new Date(value).toLocaleString("en-US", { month: "short" })
              }
              tickLine={false}
              axisLine={false}
              tickMargin={10}
            />
            <YAxis
              dataKey="profit"
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => `$${(value / 1000).toFixed(0)}k`}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            {/* Change the bar color here */}
            <Bar dataKey="profit" fill="#FAC850" radius={5} />
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        <div className="flex gap-2 font-medium leading-none">
          Trending up by 5.2% this year <TrendingUp className="h-4 w-4" />
        </div>
        <div className="leading-none text-muted-foreground">
          Showing profit data for the last 12 months
        </div>
      </CardFooter>
    </Card>
  );
}
