import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Toggle } from "../../../../shadcn-components/ui/toggle";
import {
  updateDearProfitByBrandComparisonFilter,
  updateDearProfitByBrandDateFilter,
  updateDearProfitByCategoryComparisonFilter,
  updateDearProfitByCategoryDateFilter,
  updateDearProfitByCustomerComparisonFilter,
  updateDearProfitByCustomerDateFilter,
  updateDearProfitByCustomerTagComparisonFilter,
  updateDearProfitByCustomerTagDateFilter,
  updateDearProfitByProductComparisonFilter,
  updateDearProfitByProductDateFilter,
  updateDearProfitByProductTagComparisonFilter,
  updateDearProfitByProductTagDateFilter,
  updateDearProfitByProductTypeComparisonFilter,
  updateDearProfitByProductTypeDateFilter,
  updateDearSalesByBrandComparisonFilter,
  updateDearSalesByBrandDateFilter,
  updateDearSalesByCategoryComparisonFilter,
  updateDearSalesByCategoryDateFilter,
  updateDearSalesByCustomerComparisonFilter,
  updateDearSalesByCustomerDateFilter,
  updateDearSalesByCustomerTagComparisonFilter,
  updateDearSalesByCustomerTagDateFilter,
  updateDearSalesByProductComparisonFilter,
  updateDearSalesByProductDateFilter,
  updateDearSalesByProductTagComparisonFilter,
  updateDearSalesByProductTagDateFilter,
  updateDearSalesByProductTypeComparisonFilter,
  updateDearSalesByProductTypeDateFilter,
  updateDearUnitsSoldByBrandComparisonFilter,
  updateDearUnitsSoldByBrandDateFilter,
  updateDearUnitsSoldByCategoryComparisonFilter,
  updateDearUnitsSoldByCategoryDateFilter,
  updateDearUnitsSoldByCustomerComparisonFilter,
  updateDearUnitsSoldByCustomerDateFilter,
  updateDearUnitsSoldByCustomerTagComparisonFilter,
  updateDearUnitsSoldByCustomerTagDateFilter,
  updateDearUnitsSoldByProductComparisonFilter,
  updateDearUnitsSoldByProductDateFilter,
  updateDearUnitsSoldByProductTagComparisonFilter,
  updateDearUnitsSoldByProductTagDateFilter,
  updateDearUnitsSoldByProductTypeComparisonFilter,
  updateDearUnitsSoldByProductTypeDateFilter,
  updateDearUnitsShippedByBrandDateFilter,
  updateDearUnitsShippedByBrandComparisonFilter,
  updateDearUnitsShippedByProductDateFilter,
  updateDearUnitsShippedByProductComparisonFilter,
  updateDearUnitsShippedByCategoryDateFilter,
  updateDearUnitsShippedByCategoryComparisonFilter,
  updateDearUnitsShippedByCustomerDateFilter,
  updateDearUnitsShippedByCustomerComparisonFilter,
  updateDearUnitsShippedByProductTagDateFilter,
  updateDearUnitsShippedByProductTagComparisonFilter,
  updateDearUnitsShippedByProductTypeDateFilter,
  updateDearUnitsShippedByProductTypeComparisonFilter,
  updateDearUnitsShippedByCustomerTagDateFilter,
  updateDearUnitsShippedByCustomerTagComparisonFilter,
  updateAppliedAdvanceFilters,
  getDearSalesByBrandFilteredData,
  getDearSalesByProductsFilteredData,
  getDearSalesByCustomersFilteredData,
  getDearSalesByCustomersTagFilteredData,
  getDearSalesByProductsTagFilteredData,
  getDearSalesByCategoriesFilteredData,
  getDearGrossProfitByBrandFilteredData,
  getDearGrossProfitByProductsFilteredData,
  getDearGrossProfitByCustomersFilteredData,
  getDearGrossProfitByCustomersTagFilteredData,
  getDearGrossProfitByProductsTagFilteredData,
  getDearGrossProfitByCategoriesFilteredData,
  getDearUnitsSoldByBrandFilteredData,
  getDearUnitsSoldByProductsFilteredData,
  getDearUnitsSoldByCustomersFilteredData,
  getDearUnitsSoldByCustomersTagFilteredData,
  getDearUnitsSoldByProductsTagFilteredData,
  getDearUnitsSoldByCategoriesFilteredData,
  getDearUnitsShippedByBrandFilteredData,
  getDearUnitsShippedByProductsFilteredData,
  getDearUnitsShippedByCustomersFilteredData,
  getDearUnitsShippedByCustomersTagFilteredData,
  getDearUnitsShippedByProductsTagFilteredData,
  getDearUnitsShippedByCategoriesFilteredData,
} from "../../../../redux-features/slices/kpi/kpiSlice";
import { useDispatch, useSelector } from "react-redux";
import KPIDrillDownModal from "../../ui-elements/modals/kpis-modals/drill-down-modal";
import MultiSelect from "../../ui-elements/utilis/multi-select";
import { BsFillFilterSquareFill } from "react-icons/bs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function HorizontalBarChart(props) {
  const { currencySymbol, data, title, advanceFilterTitle, drilldownTitle, drilldownTooltip, drilldownData, drilldownType } = props;
  const {
    // Dear Sales By Filters
    salesByBrandDateFilter,
    salesByBrandCompareFilter,
    salesByProductDateFilter,
    salesByProductCompareFilter,
    salesByCategoryDateFilter,
    salesByCategoryCompareFilter,
    salesByCustomerDateFilter,
    salesByCustomerCompareFilter,
    salesByProductTagDateFilter,
    salesByProductTagCompareFilter,
    salesByProductTypeDateFilter,
    salesByProductTypeCompareFilter,
    salesByCustomerTagDateFilter,
    salesByCustomerTagCompareFilter,

    // Dear Profit By Filters
    profitByCategoryDateFilter,
    profitByCategoryCompareFilter,
    profitByProductDateFilter,
    profitByProductCompareFilter,
    profitByBrandDateFilter,
    profitByBrandCompareFilter,
    profitByCustomerDateFilter,
    profitByCustomerCompareFilter,
    profitByCustomerTagDateFilter,
    profitByCustomerTagCompareFilter,
    profitByProductTagDateFilter,
    profitByProductTagCompareFilter,
    profitByProductTypeDateFilter,
    profitByProductTypeCompareFilter,

    // Dear Units Sold By Filters
    unitsSoldByBrandDateFilter,
    unitsSoldByBrandCompareFilter,
    unitsSoldByProductDateFilter,
    unitsSoldByProductCompareFilter,
    unitsSoldByCategoryDateFilter,
    unitsSoldByCategoryCompareFilter,
    unitsSoldByCustomerDateFilter,
    unitsSoldByCustomerCompareFilter,
    unitsSoldByProductTagDateFilter,
    unitsSoldByProductTagCompareFilter,
    unitsSoldByProductTypeDateFilter,
    unitsSoldByProductTypeCompareFilter,
    unitsSoldByCustomerTagDateFilter,
    unitsSoldByCustomerTagCompareFilter,

    // Dear Units Shipped By Filters
    unitsShippedByBrandDateFilter,
    unitsShippedByBrandCompareFilter,
    unitsShippedByProductDateFilter,
    unitsShippedByProductCompareFilter,
    unitsShippedByCategoryDateFilter,
    unitsShippedByCategoryCompareFilter,
    unitsShippedByCustomerDateFilter,
    unitsShippedByCustomerCompareFilter,
    unitsShippedByProductTagDateFilter,
    unitsShippedByProductTagCompareFilter,
    unitsShippedByProductTypeDateFilter,
    unitsShippedByProductTypeCompareFilter,
    unitsShippedByCustomerTagDateFilter,
    unitsShippedByCustomerTagCompareFilter,
  } = useSelector((state) => state.kpi);
  let initialFilterA = "This Month";
  let initialFilterB = "Previous Period";

  const [isAdvancedDearFilters, setIsAdvancedDearFilters] = useState(false)
  const { user } = useSelector((state) => state.auth);
  const token = user?.data?.api_token;

  const dispatch = useDispatch();
  const { organisation } = useSelector((state) => state.organisation);
  const {
    dearBrandFilters,
    dearCategoryFilters,
    dearContactFilters,
    dearCustomerTagsFilters,
    dearProductFilters,
    dearProductTagsFilters,
    dearProductTypeFilters,
    dearLocationFilters,
    dearAdvanceFilters
  } = useSelector((state) => state.kpi);

  const brandFilterOptions = Array.isArray(dearBrandFilters)
    ? dearBrandFilters.map((filter) => ({
      label: filter?.brand
        ? filter.brand
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.brand || "",
    }))
    : [];

  const contactFilterOptions = Array.isArray(dearContactFilters)
    ? dearContactFilters.map((filter) => ({
      label: filter?.contact_name
        ? filter.contact_name
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.contact_name || "",
    }))
    : [];

  const categoryFilterOptions = Array.isArray(dearCategoryFilters)
    ? dearCategoryFilters.map((filter) => ({
      label: filter?.category
        ? filter.category
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.category || "",
    }))
    : [];

  const customerTagsFilterOptions = Array.isArray(dearCustomerTagsFilters)
    ? dearCustomerTagsFilters.map((filter) => ({
      label: filter?.customer_tag
        ? filter.customer_tag
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.customer_tag || "",
    }))
    : [];

  const productFilterOptions = Array.isArray(dearProductFilters)
    ? dearProductFilters.map((filter) => ({
      label: filter?.product_name
        ? filter.product_name
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.product_name || "",
    }))
    : [];

  const productTagsFilterOptions = Array.isArray(dearProductTagsFilters)
    ? dearProductTagsFilters.map((filter) => ({
      label: filter?.product_tag
        ? filter.product_tag
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.product_tag || "",
    }))
    : [];

  const productTypeFilterOptions = Array.isArray(dearProductTypeFilters)
    ? dearProductTypeFilters.map((filter) => ({
      label: filter?.product_type
        ? filter.product_type
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.product_type || "",
    }))
    : [];
  const locationFilterOptions = Array.isArray(dearLocationFilters)
    ? dearLocationFilters.map((filter) => ({
      label: filter?.location
        ? filter.location
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.location || "",
    }))
    : [];
  const [brandFilterSelected, setBrandFilterSelected] = useState(null);
  const [categoryFilterSelected, setCategoryFilterSelected] = useState(null);
  const [contactFilterSelected, setContactFilterSelected] = useState(null);
  const [customerTagsFilterSelected, setCustomerTagsFilterSelected] = useState(null);
  const [productFilterSelected, setProductFilterSelected] = useState(null);
  const [productTagsFilterSelected, setProductTagsFilterSelected] = useState(null);
  const [productTypeFilterSelected, setProductTypeFilterSelected] = useState(null);
  const [locationFilterSelected, setLocationFilterSelected] = useState(null);


  const setDefaultFilterValues = (kpiType) => {
    switch (kpiType) {
      case 'Top 10 Brands By Sales':
        setBrandFilterSelected(dearAdvanceFilters.salesByTop10Brands?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.salesByTop10Brands?.category || []);
        setContactFilterSelected(dearAdvanceFilters.salesByTop10Brands?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.salesByTop10Brands?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.salesByTop10Brands?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.salesByTop10Brands?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.salesByTop10Brands?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.salesByTop10Brands?.location || []);
        break;

      case 'Top 10 Products By Sales':
        setBrandFilterSelected(dearAdvanceFilters.salesByTop10Products?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.salesByTop10Products?.category || []);
        setContactFilterSelected(dearAdvanceFilters.salesByTop10Products?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.salesByTop10Products?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.salesByTop10Products?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.salesByTop10Products?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.salesByTop10Products?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.salesByTop10Products?.location || []);
        break;

      case 'Top 10 Categories By Sales':
        setBrandFilterSelected(dearAdvanceFilters.salesByTop10Categories?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.salesByTop10Categories?.category || []);
        setContactFilterSelected(dearAdvanceFilters.salesByTop10Categories?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.salesByTop10Categories?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.salesByTop10Categories?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.salesByTop10Categories?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.salesByTop10Categories?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.salesByTop10Categories?.location || []);
        break;

      case 'Top 10 Customers By Sales':
        setBrandFilterSelected(dearAdvanceFilters.salesByTop10Customers?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.salesByTop10Customers?.category || []);
        setContactFilterSelected(dearAdvanceFilters.salesByTop10Customers?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.salesByTop10Customers?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.salesByTop10Customers?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.salesByTop10Customers?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.salesByTop10Customers?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.salesByTop10Customers?.location || []);
        break;

      case 'Top 10 Product Tags By Sales':
        setBrandFilterSelected(dearAdvanceFilters.salesByTop10ProductsTag?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.salesByTop10ProductsTag?.category || []);
        setContactFilterSelected(dearAdvanceFilters.salesByTop10ProductsTag?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.salesByTop10ProductsTag?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.salesByTop10ProductsTag?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.salesByTop10ProductsTag?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.salesByTop10ProductsTag?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.salesByTop10ProductsTag?.location || []);
        break;

      case 'Top 10 Customer Tags By Sales':
        setBrandFilterSelected(dearAdvanceFilters.salesByTop10CustomersTag?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.salesByTop10CustomersTag?.category || []);
        setContactFilterSelected(dearAdvanceFilters.salesByTop10CustomersTag?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.salesByTop10CustomersTag?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.salesByTop10CustomersTag?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.salesByTop10CustomersTag?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.salesByTop10CustomersTag?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.salesByTop10CustomersTag?.location || []);
        break;

      case 'Top 10 Brands By Gross Profit':
        setBrandFilterSelected(dearAdvanceFilters.grossProfitByTop10Brands?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.grossProfitByTop10Brands?.category || []);
        setContactFilterSelected(dearAdvanceFilters.grossProfitByTop10Brands?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.grossProfitByTop10Brands?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.grossProfitByTop10Brands?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.grossProfitByTop10Brands?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.grossProfitByTop10Brands?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.grossProfitByTop10Brands?.location || []);
        break;

      case 'Top 10 Products By Gross Profit':
        setBrandFilterSelected(dearAdvanceFilters.grossProfitByTop10Products?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.grossProfitByTop10Products?.category || []);
        setContactFilterSelected(dearAdvanceFilters.grossProfitByTop10Products?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.grossProfitByTop10Products?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.grossProfitByTop10Products?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.grossProfitByTop10Products?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.grossProfitByTop10Products?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.grossProfitByTop10Products?.location || []);
        break;

      case 'Top 10 Categories By Gross Profit':
        setBrandFilterSelected(dearAdvanceFilters.grossProfitByTop10Categories?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.grossProfitByTop10Categories?.category || []);
        setContactFilterSelected(dearAdvanceFilters.grossProfitByTop10Categories?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.grossProfitByTop10Categories?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.grossProfitByTop10Categories?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.grossProfitByTop10Categories?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.grossProfitByTop10Categories?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.grossProfitByTop10Categories?.location || []);
        break;

      case 'Top 10 Customers By Gross Profit':
        setBrandFilterSelected(dearAdvanceFilters.grossProfitByTop10Customers?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.grossProfitByTop10Customers?.category || []);
        setContactFilterSelected(dearAdvanceFilters.grossProfitByTop10Customers?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.grossProfitByTop10Customers?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.grossProfitByTop10Customers?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.grossProfitByTop10Customers?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.grossProfitByTop10Customers?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.grossProfitByTop10Customers?.location || []);
        break;

      case 'Top 10 Product Tags By Gross Profit':
        setBrandFilterSelected(dearAdvanceFilters.grossProfitByTop10ProductsTag?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.grossProfitByTop10ProductsTag?.category || []);
        setContactFilterSelected(dearAdvanceFilters.grossProfitByTop10ProductsTag?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.grossProfitByTop10ProductsTag?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.grossProfitByTop10ProductsTag?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.grossProfitByTop10ProductsTag?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.grossProfitByTop10ProductsTag?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.grossProfitByTop10ProductsTag?.location || []);
        break;
      case 'Top 10 Customer Tags By Gross Profit':
        setBrandFilterSelected(dearAdvanceFilters.grossProfitByTop10CustomersTag?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.grossProfitByTop10CustomersTag?.category || []);
        setContactFilterSelected(dearAdvanceFilters.grossProfitByTop10CustomersTag?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.grossProfitByTop10CustomersTag?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.grossProfitByTop10CustomersTag?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.grossProfitByTop10CustomersTag?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.grossProfitByTop10CustomersTag?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.grossProfitByTop10CustomersTag?.location || []);
        break;
      case 'Top 10 Brands By Units Sold':
        setBrandFilterSelected(dearAdvanceFilters.unitsSoldByTop10Brands?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.unitsSoldByTop10Brands?.category || []);
        setContactFilterSelected(dearAdvanceFilters.unitsSoldByTop10Brands?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.unitsSoldByTop10Brands?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.unitsSoldByTop10Brands?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.unitsSoldByTop10Brands?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.unitsSoldByTop10Brands?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.unitsSoldByTop10Brands?.location || []);
        break;

      case 'Top 10 Products By Units Sold':
        setBrandFilterSelected(dearAdvanceFilters.unitsSoldByTop10Products?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.unitsSoldByTop10Products?.category || []);
        setContactFilterSelected(dearAdvanceFilters.unitsSoldByTop10Products?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.unitsSoldByTop10Products?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.unitsSoldByTop10Products?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.unitsSoldByTop10Products?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.unitsSoldByTop10Products?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.unitsSoldByTop10Products?.location || []);
        break;

      case 'Top 10 Categories By Units Sold':
        setBrandFilterSelected(dearAdvanceFilters.unitsSoldByTop10Categories?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.unitsSoldByTop10Categories?.category || []);
        setContactFilterSelected(dearAdvanceFilters.unitsSoldByTop10Categories?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.unitsSoldByTop10Categories?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.unitsSoldByTop10Categories?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.unitsSoldByTop10Categories?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.unitsSoldByTop10Categories?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.unitsSoldByTop10Categories?.location || []);
        break;

      case 'Top 10 Customers By Units Sold':
        setBrandFilterSelected(dearAdvanceFilters.unitsSoldByTop10Customers?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.unitsSoldByTop10Customers?.category || []);
        setContactFilterSelected(dearAdvanceFilters.unitsSoldByTop10Customers?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.unitsSoldByTop10Customers?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.unitsSoldByTop10Customers?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.unitsSoldByTop10Customers?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.unitsSoldByTop10Customers?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.unitsSoldByTop10Customers?.location || []);
        break;

      case 'Top 10 Product Tags By Units Sold':
        setBrandFilterSelected(dearAdvanceFilters.unitsSoldByTop10ProductsTag?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.unitsSoldByTop10ProductsTag?.category || []);
        setContactFilterSelected(dearAdvanceFilters.unitsSoldByTop10ProductsTag?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.unitsSoldByTop10ProductsTag?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.unitsSoldByTop10ProductsTag?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.unitsSoldByTop10ProductsTag?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.unitsSoldByTop10ProductsTag?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.unitsSoldByTop10ProductsTag?.location || []);
        break;
      case 'Top 10 Customer Tags By Units Sold':
        setBrandFilterSelected(dearAdvanceFilters.unitsSoldByTop10CustomersTag?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.unitsSoldByTop10CustomersTag?.category || []);
        setContactFilterSelected(dearAdvanceFilters.unitsSoldByTop10CustomersTag?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.unitsSoldByTop10CustomersTag?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.unitsSoldByTop10CustomersTag?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.unitsSoldByTop10CustomersTag?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.unitsSoldByTop10CustomersTag?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.unitsSoldByTop10CustomersTag?.location || []);
        break;
      case 'Top 10 Brands By Units Shipped':
        setBrandFilterSelected(dearAdvanceFilters.unitsShippedByTop10Brands?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.unitsShippedByTop10Brands?.category || []);
        setContactFilterSelected(dearAdvanceFilters.unitsShippedByTop10Brands?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.unitsShippedByTop10Brands?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.unitsShippedByTop10Brands?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.unitsShippedByTop10Brands?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.unitsShippedByTop10Brands?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.unitsShippedByTop10Brands?.location || []);
        break;

      case 'Top 10 Products By Units Shipped':
        setBrandFilterSelected(dearAdvanceFilters.unitsShippedByTop10Products?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.unitsShippedByTop10Products?.category || []);
        setContactFilterSelected(dearAdvanceFilters.unitsShippedByTop10Products?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.unitsShippedByTop10Products?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.unitsShippedByTop10Products?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.unitsShippedByTop10Products?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.unitsShippedByTop10Products?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.unitsShippedByTop10Products?.location || []);
        break;

      case 'Top 10 Categories By Units Shipped':
        setBrandFilterSelected(dearAdvanceFilters.unitsShippedByTop10Categories?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.unitsShippedByTop10Categories?.category || []);
        setContactFilterSelected(dearAdvanceFilters.unitsShippedByTop10Categories?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.unitsShippedByTop10Categories?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.unitsShippedByTop10Categories?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.unitsShippedByTop10Categories?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.unitsShippedByTop10Categories?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.unitsShippedByTop10Categories?.location || []);
        break;

      case 'Top 10 Customers By Units Shipped':
        setBrandFilterSelected(dearAdvanceFilters.unitsShippedByTop10Customers?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.unitsShippedByTop10Customers?.category || []);
        setContactFilterSelected(dearAdvanceFilters.unitsShippedByTop10Customers?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.unitsShippedByTop10Customers?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.unitsShippedByTop10Customers?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.unitsShippedByTop10Customers?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.unitsShippedByTop10Customers?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.unitsShippedByTop10Customers?.location || []);
        break;

      case 'Top 10 Product Tags By Units Shipped':
        setBrandFilterSelected(dearAdvanceFilters.unitsShippedByTop10ProductsTag?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.unitsShippedByTop10ProductsTag?.category || []);
        setContactFilterSelected(dearAdvanceFilters.unitsShippedByTop10ProductsTag?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.unitsShippedByTop10ProductsTag?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.unitsShippedByTop10ProductsTag?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.unitsShippedByTop10ProductsTag?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.unitsShippedByTop10ProductsTag?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.unitsShippedByTop10ProductsTag?.location || []);
        break;
      case 'Top 10 Customer Tags By Units Shipped':
        setBrandFilterSelected(dearAdvanceFilters.unitsShippedByTop10CustomersTag?.brand || []);
        setCategoryFilterSelected(dearAdvanceFilters.unitsShippedByTop10CustomersTag?.category || []);
        setContactFilterSelected(dearAdvanceFilters.unitsShippedByTop10CustomersTag?.contact || []);
        setCustomerTagsFilterSelected(dearAdvanceFilters.unitsShippedByTop10CustomersTag?.customerTags || []);
        setProductFilterSelected(dearAdvanceFilters.unitsShippedByTop10CustomersTag?.product || []);
        setProductTagsFilterSelected(dearAdvanceFilters.unitsShippedByTop10CustomersTag?.productTags || []);
        setProductTypeFilterSelected(dearAdvanceFilters.unitsShippedByTop10CustomersTag?.productType || []);
        setLocationFilterSelected(dearAdvanceFilters.unitsShippedByTop10CustomersTag?.location || []);
        break;
      default:
        // Default case resets filters
        setBrandFilterSelected([]);
        setCategoryFilterSelected([]);
        setContactFilterSelected([]);
        setCustomerTagsFilterSelected([]);
        setProductFilterSelected([]);
        setProductTagsFilterSelected([]);
        setProductTypeFilterSelected([]);
        setLocationFilterSelected([]);
        break;
    }
  };
  useEffect(() => {
    setDefaultFilterValues(title);
  }, [title, dearAdvanceFilters]);


  const [showMore, setShowMore] = useState(false);

  // Combine all filters and add colorClass based on type
  const combinedFilters = [
    ...(brandFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-orange-200 text-orange-600' })),
    ...(categoryFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-neutral-200 text-neutral-600' })),
    ...(contactFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-emerald-100 text-emerald-500' })),
    ...(customerTagsFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-emerald-200 text-emerald-600' })),
    ...(productFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-sky-100 text-sky-500' })),
    ...(productTagsFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-sky-200 text-sky-600' })),
    ...(productTypeFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-violet-200 text-violet-600' })),
    ...(locationFilterSelected || []).map(item => ({ ...item, colorClass: 'bg-[#ff3b7b] bg-opacity-80 text-white' })),
  ];

  // Determine which filters to show based on the showMore state
  const filtersToShow = showMore ? combinedFilters : combinedFilters.slice(0, 5);

  // Toggle function for show more/less
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };



  const handleContactChange = (selected) => {
    setContactFilterSelected(selected);
  };

  const handleCustomerTagsChange = (selected) => {
    setCustomerTagsFilterSelected(selected);
  };
  const handleBrandChange = (selected) => {
    setBrandFilterSelected(selected);
  };
  const handleProductChange = (selected) => {
    setProductFilterSelected(selected);
  };

  const handleProductTagsChange = (selected) => {
    setProductTagsFilterSelected(selected);
  };

  const handleProductTypeChange = (selected) => {
    setProductTypeFilterSelected(selected);
  };

  const handleCategoryChange = (selected) => {
    setCategoryFilterSelected(selected);
  };
  const handleLocationChange = (selected) => {
    setLocationFilterSelected(selected);
  };

  const saveSelectedFilters = async () => {
    const selectedBrands =
      brandFilterSelected?.map((selected) => selected.value) || [];
    const selectedCategories =
      categoryFilterSelected?.map((selected) => selected.value) || [];
    const selectedContacts =
      contactFilterSelected?.map((selected) => selected.value) || [];
    const selectedProducts =
      productFilterSelected?.map((selected) => selected.value) || [];
    const selectedProductTypes =
      productTypeFilterSelected?.map((selected) => selected.value) || [];
    const selectedProductTags =
      productTagsFilterSelected?.map((selected) => selected.value) || [];
    const selectedCustomerTags =
      customerTagsFilterSelected?.map((selected) => selected.value) || [];
    const selectedLocations =
      locationFilterSelected?.map((selected) => selected.value) || [];
    const dataset_id = organisation?.dataset_ids?.dear;
    const params = {
      brand: selectedBrands.join(", "),
      category: selectedCategories.join(", "),
      contact: selectedContacts.join(", "),
      product: selectedProducts.join(", "),
      productType: selectedProductTypes.join(", "),
      productTags: selectedProductTags.join(", "),
      customerTags: selectedCustomerTags.join(", "),
      location: selectedLocations.join(", "),
    };

    const appliedAdvanceFilters = {
      brand: brandFilterSelected,
      category: categoryFilterSelected,
      contact: contactFilterSelected,
      product: productFilterSelected,
      productType: productTypeFilterSelected,
      productTags: productTagsFilterSelected,
      customerTags: customerTagsFilterSelected,
      location: locationFilterSelected,
    };
    if (title === "Top 10 Brands By Sales") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'salesByTop10Brands', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearSalesByBrandFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Products By Sales") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'salesByTop10Products', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearSalesByProductsFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Categories By Sales") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'salesByTop10Categories', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearSalesByCategoriesFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Customers By Sales") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'salesByTop10Customers', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearSalesByCustomersFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Product Tags By Sales") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'salesByTop10ProductsTag', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearSalesByProductsTagFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Customer Tags By Sales") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'salesByTop10CustomersTag', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearSalesByCustomersTagFilteredData({ dataset_id, token, params })
      );
    }


    if (title === "Top 10 Categories By Gross Profit") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'grossProfitByTop10Categories', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearGrossProfitByCategoriesFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Products By Gross Profit") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'grossProfitByTop10Products', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearGrossProfitByProductsFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Brands By Gross Profit") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'grossProfitByTop10Brands', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearGrossProfitByBrandFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Customers By Gross Profit") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'grossProfitByTop10Customers', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearGrossProfitByCustomersFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Customer Tags By Gross Profit") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'grossProfitByTop10CustomersTag', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearGrossProfitByCustomersTagFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Product Tags By Gross Profit") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'grossProfitByTop10ProductsTag', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearGrossProfitByProductsTagFilteredData({ dataset_id, token, params })
      );
    }

    if (title === "Top 10 Categories By Units Sold") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'unitsSoldByTop10Categories', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearUnitsSoldByCategoriesFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Products By Units Sold") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'unitsSoldByTop10Products', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearUnitsSoldByProductsFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Brands By Units Sold") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'unitsSoldByTop10Brands', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearUnitsSoldByBrandFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Customers By Units Sold") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'unitsSoldByTop10Customers', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearUnitsSoldByCustomersFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Customer Tags By Units Sold") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'unitsSoldByTop10CustomersTag', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearUnitsSoldByCustomersTagFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Product Tags By Units Sold") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'unitsSoldByTop10ProductsTag', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearUnitsSoldByProductsTagFilteredData({ dataset_id, token, params })
      );
    }

    if (title === "Top 10 Categories By Units Shipped") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'unitsShippedByTop10Categories', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearUnitsShippedByCategoriesFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Products By Units Shipped") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'unitsShippedByTop10Products', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearUnitsShippedByProductsFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Brands By Units Shipped") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'unitsShippedByTop10Brands', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearUnitsShippedByBrandFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Customers By Units Shipped") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'unitsShippedByTop10Customers', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearUnitsShippedByCustomersFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Customer Tags By Units Shipped") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'unitsShippedByTop10CustomersTag', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearUnitsShippedByCustomersTagFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Top 10 Product Tags By Units Shipped") {
      dispatch(updateAppliedAdvanceFilters({ kpiType: 'unitsShippedByTop10ProductsTag', filters: appliedAdvanceFilters }));
      await dispatch(
        getDearUnitsShippedByProductsTagFilteredData({ dataset_id, token, params })
      );
    }
    handleAdvanceDearFilters();
  };

  const handleAdvanceDearFiltersClose = () => {
    handleAdvanceDearFilters();
  };

  const handleAdvanceDearFilters = () => {
    setIsAdvancedDearFilters(!isAdvancedDearFilters);
  };

  switch (title) {
    case "Top 10 Brands By Sales":
      initialFilterA =
        salesByBrandDateFilter ||
        initialFilterA;
      initialFilterB =
        salesByBrandCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Products By Sales":
      initialFilterA =
        salesByProductDateFilter ||
        initialFilterA;
      initialFilterB =
        salesByProductCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Categories By Sales":
      initialFilterA =
        salesByCategoryDateFilter ||
        initialFilterA;
      initialFilterB =
        salesByCategoryCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Customers By Sales":
      initialFilterA =
        salesByCustomerDateFilter ||
        initialFilterA;
      initialFilterB =
        salesByCustomerCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Product Tags By Sales":
      initialFilterA =
        salesByProductTagDateFilter ||
        initialFilterA;
      initialFilterB =
        salesByProductTagCompareFilter ||
        initialFilterB;
      break;
    case "Sales by Product Type":
      initialFilterA =
        salesByProductTypeDateFilter?.toLowerCase().replace(" ", "_") ||
        initialFilterA;
      initialFilterB =
        salesByProductTypeCompareFilter?.toLowerCase().replace(" ", "_") ||
        initialFilterB;
      break;
    case "Top 10 Customer Tags By Sales":
      initialFilterA =
        salesByCustomerTagDateFilter ||
        initialFilterA;
      initialFilterB =
        salesByCustomerTagCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Categories By Gross Profit":
      initialFilterA =
        profitByCategoryDateFilter ||
        initialFilterA;
      initialFilterB =
        profitByCategoryCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Products By Gross Profit":
      initialFilterA =
        profitByProductDateFilter ||
        initialFilterA;
      initialFilterB =
        profitByProductCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Brands By Gross Profit":
      initialFilterA =
        profitByBrandDateFilter ||
        initialFilterA;
      initialFilterB =
        profitByBrandCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Customers By Gross Profit":
      initialFilterA =
        profitByCustomerDateFilter ||
        initialFilterA;
      initialFilterB =
        profitByCustomerCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Customer Tags By Gross Profit":
      initialFilterA =
        profitByCustomerTagDateFilter ||
        initialFilterA;
      initialFilterB =
        profitByCustomerTagCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Product Tags By Gross Profit":
      initialFilterA =
        profitByProductTagDateFilter ||
        initialFilterA;
      initialFilterB =
        profitByProductTagCompareFilter ||
        initialFilterB;
      break;
    case "Profit By Product Type":
      initialFilterA =
        profitByProductTypeDateFilter?.toLowerCase().replace(" ", "_") ||
        initialFilterA;
      initialFilterB =
        profitByProductTypeCompareFilter?.toLowerCase().replace(" ", "_") ||
        initialFilterB;
      break;
    case "Top 10 Brands By Units Sold":
      initialFilterA =
        unitsSoldByBrandDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsSoldByBrandCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Products By Units Sold":
      initialFilterA =
        unitsSoldByProductDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsSoldByProductCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Categories By Units Sold":
      initialFilterA =
        unitsSoldByCategoryDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsSoldByCategoryCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Customers By Units Sold":
      initialFilterA =
        unitsSoldByCustomerDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsSoldByCustomerCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Product Tags By Units Sold":
      initialFilterA =
        unitsSoldByProductTagDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsSoldByProductTagCompareFilter ||
        initialFilterB;
      break;
    case "Units Sold by Product Type":
      initialFilterA =
        unitsSoldByProductTypeDateFilter?.toLowerCase().replace(" ", "_") ||
        initialFilterA;
      initialFilterB =
        unitsSoldByProductTypeCompareFilter?.toLowerCase().replace(" ", "_") ||
        initialFilterB;
      break;
    case "Top 10 Customer Tags By Units Sold":
      initialFilterA =
        unitsSoldByCustomerTagDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsSoldByCustomerTagCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Brands By Units Shipped":
      initialFilterA =
        unitsShippedByBrandDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsShippedByBrandCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Products By Units Shipped":
      initialFilterA =
        unitsShippedByProductDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsShippedByProductCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Categories By Units Shipped":
      initialFilterA =
        unitsShippedByCategoryDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsShippedByCategoryCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Customers By Units Shipped":
      initialFilterA =
        unitsShippedByCustomerDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsShippedByCustomerCompareFilter ||
        initialFilterB;
      break;
    case "Top 10 Product Tags By Units Shipped":
      initialFilterA =
        unitsShippedByProductTagDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsShippedByProductTagCompareFilter || initialFilterB;
      break;
    case "Units Shipped by Product Type":
      initialFilterA =
        unitsShippedByProductTypeDateFilter?.toLowerCase().replace(" ", "_") ||
        initialFilterA;
      initialFilterB =
        unitsShippedByProductTypeCompareFilter?.toLowerCase().replace(" ", "_") || initialFilterB;
      break;
    case "Top 10 Customer Tags By Units Shipped":
      initialFilterA =
        unitsShippedByCustomerTagDateFilter ||
        initialFilterA;
      initialFilterB =
        unitsShippedByCustomerTagCompareFilter || initialFilterB;
      break;
    default:
      break;
  }

  const [filterA, setFilterA] = useState(initialFilterA);
  const [filterB, setFilterB] = useState(initialFilterB);

  const [filteredText, setFilteredText] = useState("Select Filters");

  useEffect(() => {
    let newFilteredText = "";
    switch (`${filterA}_${filterB}`) {
      case "This Week_Previous Period":
        newFilteredText = formatDate(thisWeekStartDate);
        break;
      case "Last Week_Previous Period":
        newFilteredText = formatDate(lastWeekStartDate);
        break;
      case "This Month_Previous Period":
        newFilteredText = formatDate(thisMonthStartDate);
        break;
      case "Last Month_Previous Period":
        newFilteredText = formatDate(lastMonthStartDate);
        break;
      case "This Quarter_Previous Period":
        newFilteredText =
          formatDate(thisQuarterStartDate) +
          "-" +
          formatDate(thisQuarterEndDate);
        break;
      case "Last Quarter_Previous Period":
        newFilteredText =
          formatDate(lastQuarterStartDate) +
          "-" +
          formatDate(lastQuarterEndDate);
        break;
      case "This Year_Previous Period":
        newFilteredText =
          formatDate(thisYearStartDate) + "-" + formatDate(thisYearEndDate);
        break;
      case "Last Year_Previous Period":
        newFilteredText =
          formatDate(lastYearStartDate) + "-" + formatDate(lastYearEndDate);
        break;
      case "This Week_Previous Year":
        newFilteredText =
          formatDate(thisWeekStartDate) + "-" + formatDate(thisWeekEndDate);
        break;
      case "Last Week_Previous Year":
        // text = `Filtered text based on ${filterA} and ${filterB}`;
        newFilteredText =
          formatDate(lastWeekStartDate) + "-" + formatDate(lastWeekEndDate);
        break;
      case "This Month_Previous Year":
        newFilteredText =
          formatDate(thisMonthStartDate) + " - " + formatDate(thisMonthEndDate);
        break;
      case "Last Month_Previous Year":
        newFilteredText =
          formatDate(lastMonthStartDate) + " - " + formatDate(lastMonthEndDate);
        break;
      case "This Quarter_Previous Year":
        newFilteredText =
          formatDate(thisQuarterStartDate) +
          "-" +
          formatDate(thisQuarterEndDate);
        break;
      case "Last Quarter_Previous Year":
        newFilteredText =
          formatDate(lastQuarterStartDate) +
          "-" +
          formatDate(lastQuarterEndDate);
        break;
      case "This Year_Previous Year":
        newFilteredText =
          formatDate(thisYearStartDate) + "-" + formatDate(thisYearEndDate);
        break;
      case "Last Year_Previous Year":
        // text = `Filtered text based on ${filterA} and ${filterB}`;
        newFilteredText =
          formatDate(lastYearStartDate) + "-" + formatDate(lastYearEndDate);
        break;

      default:
        newFilteredText =
          formatDate(thisMonthStartDate) + " - " + formatDate(thisMonthEndDate);
    }
    setFilteredText(newFilteredText);
  }, [filterA, filterB]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleFilterChange = (filterType, value) => {
    if (filterType === "filterA") {
      setFilterA(value);
    } else if (filterType === "filterB") {
      setFilterB(value);
    }
  };

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 2, // You can adjust this value based on your preference
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: title,
      },
      tooltip: {
        mode: 'index',
        callbacks: {
          label: function (tooltipItem) {
            const datasetLabel = tooltipItem.dataset.label || '';
            const value = tooltipItem.raw.toFixed(2);
            const formattedValue = new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 0,
              maximumFractionDigits: 2
            }).format(value);
            return ` ${datasetLabel}:  ${currencySymbol}${formattedValue}`;
          }
        }
      }
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: true,
        },
        ticks: {
          callback: function (value) {
            const isNegative = value < 0;
            const absValue = Math.abs(value);
            let formattedValue;
            if (absValue >= 1e9) {
              formattedValue = (absValue / 1e9) + 'B'; // Billion
            } else if (absValue >= 1e6) {
              formattedValue = (absValue / 1e6) + 'M'; // Million
            } else if (absValue >= 1e3) {
              formattedValue = (absValue / 1e3) + 'K'; // Thousand
            } else {
              formattedValue = absValue; // Less than thousand
            }

            return isNegative ? `-${formattedValue}` : formattedValue;
          }
        }
      },
      y: {
        display: true,
        grid: {
          display: true,
        },
      },
    },
  };

  // Get the current date
  const currentDate = new Date();
  function formatDate(date) {
    const options = { month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  // Get the current day of the week (0 is Sunday, 1 is Monday, ..., 6 is Saturday)
  const currentDayOfWeek = currentDate.getDay();

  // Calculate the difference in days between the current day and Sunday (assuming Sunday is the first day of the week)
  const daysUntilSunday = currentDayOfWeek === 0 ? 0 : 7 - currentDayOfWeek;

  // Calculate the start date of the current week (Sunday)
  const thisWeekStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - daysUntilSunday
  );

  // Calculate the end date of the current week (Saturday)
  const thisWeekEndDate = new Date(
    thisWeekStartDate.getFullYear(),
    thisWeekStartDate.getMonth(),
    thisWeekStartDate.getDate() + 6
  );

  // Calculate the start date of the previous week (7 days before the start of the current week)
  const lastWeekStartDate = new Date(
    thisWeekStartDate.getFullYear(),
    thisWeekStartDate.getMonth(),
    thisWeekStartDate.getDate() - 7
  );

  // Calculate the end date of the previous week (6 days before the start of the current week)
  const lastWeekEndDate = new Date(
    thisWeekStartDate.getFullYear(),
    thisWeekStartDate.getMonth(),
    thisWeekStartDate.getDate() - 1
  );

  // Calculate dates for this month
  const thisMonthStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const thisMonthEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  // Calculate dates for last month
  const lastMonthStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  const lastMonthEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  );

  // Calculate dates for this quarter
  const currentQuarter = Math.floor(currentDate.getMonth() / 3);
  const thisQuarterStartDate = new Date(
    currentDate.getFullYear(),
    currentQuarter * 3,
    1
  );
  const thisQuarterEndDate = new Date(
    currentDate.getFullYear(),
    (currentQuarter + 1) * 3,
    0
  );

  // Calculate dates for last quarter
  const lastQuarterStartDate = new Date(
    currentDate.getFullYear(),
    (currentQuarter - 1) * 3,
    1
  );
  const lastQuarterEndDate = new Date(
    currentDate.getFullYear(),
    currentQuarter * 3,
    0
  );

  // Calculate dates for Year to Date
  const thisYearStartDate = new Date(currentDate.getFullYear(), 0, 1);
  const thisYearEndDate = new Date(currentDate.getFullYear(), 11, 31);

  // Calculate dates for last year
  const lastYearStartDate = new Date(currentDate.getFullYear() - 1, 0, 1);
  const lastYearEndDate = new Date(currentDate.getFullYear() - 1, 11, 31);

  const handleGoButtonClick = () => {
    console.log(filterA)
    let text = "";
    switch (`${filterA}_${filterB}`) {
      case "This Week_Previous Period":
        text = formatDate(thisWeekStartDate);
        break;
      case "Last Week_Previous Period":
        text = formatDate(lastWeekStartDate);
        break;
      case "This Month_Previous Period":
        text = formatDate(thisMonthStartDate);
        break;
      case "Last Month_Previous Period":
        text = formatDate(lastMonthStartDate);
        break;
      case "This Quarter_Previous Period":
        text =
          formatDate(thisQuarterStartDate) +
          "-" +
          formatDate(thisQuarterEndDate);
        break;
      case "Last Quarter_Previous Period":
        text =
          formatDate(lastQuarterStartDate) +
          "-" +
          formatDate(lastQuarterEndDate);
        break;
      case "This Year_Previous Period":
        text =
          formatDate(thisYearStartDate) + "-" + formatDate(thisYearEndDate);
        break;
      case "Last Year_Previous Period":
        text =
          formatDate(lastYearStartDate) + "-" + formatDate(lastYearEndDate);
        break;
      case "This Week_Previous Year":
        text =
          formatDate(thisWeekStartDate) + "-" + formatDate(thisWeekEndDate);
        break;
      case "Last Week_Previous Year":
        // text = `Filtered text based on ${filterA} and ${filterB}`;
        text =
          formatDate(lastWeekStartDate) + "-" + formatDate(lastWeekEndDate);
        break;
      case "This Month_Previous Year":
        text =
          formatDate(thisMonthStartDate) + " - " + formatDate(thisMonthEndDate);
        break;
      case "Last Month_Previous Year":
        text =
          formatDate(lastMonthStartDate) + " - " + formatDate(lastMonthEndDate);
        break;
      case "This Quarter_Previous Year":
        text =
          formatDate(thisQuarterStartDate) +
          "-" +
          formatDate(thisQuarterEndDate);
        break;
      case "Last Quarter_Previous Year":
        text =
          formatDate(lastQuarterStartDate) +
          "-" +
          formatDate(lastQuarterEndDate);
        break;
      case "This Year_Previous Year":
        text =
          formatDate(thisYearStartDate) + "-" + formatDate(thisYearEndDate);
        break;
      case "Last Year_Previous Year":
        // text = `Filtered text based on ${filterA} and ${filterB}`;
        text =
          formatDate(lastYearStartDate) + "-" + formatDate(lastYearEndDate);
        break;
      default:
        text =
          formatDate(thisMonthStartDate) + " - " + formatDate(thisMonthEndDate);
    }
    setFilteredText(text);
    if (title === "Top 10 Brands By Sales") {
      dispatch(updateDearSalesByBrandDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearSalesByBrandComparisonFilter({ comparisonFilter: filterB })
      );
    }
    if (title === "Top 10 Products By Sales") {
      dispatch(updateDearSalesByProductDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearSalesByProductComparisonFilter({ comparisonFilter: filterB })
      );
    }
    if (title === "Top 10 Categories By Sales") {
      dispatch(updateDearSalesByCategoryDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearSalesByCategoryComparisonFilter({ comparisonFilter: filterB })
      );
    }
    if (title === "Top 10 Customers By Sales") {
      dispatch(updateDearSalesByCustomerDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearSalesByCustomerComparisonFilter({ comparisonFilter: filterB })
      );
    }
    if (title === "Top 10 Product Tags By Sales") {
      dispatch(updateDearSalesByProductTagDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearSalesByProductTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Sales by Product Type") {
      dispatch(updateDearSalesByProductTypeDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearSalesByProductTypeComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Customer Tags By Sales") {
      dispatch(updateDearSalesByCustomerTagDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearSalesByCustomerTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Categories By Gross Profit") {
      dispatch(updateDearProfitByCategoryDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearProfitByCategoryComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Products By Gross Profit") {
      dispatch(updateDearProfitByProductDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearProfitByProductComparisonFilter({ comparisonFilter: filterB })
      );
    }
    if (title === "Top 10 Brands By Gross Profit") {
      dispatch(updateDearProfitByBrandDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearProfitByBrandComparisonFilter({ comparisonFilter: filterB })
      );
    }
    if (title === "Top 10 Customers By Gross Profit") {
      dispatch(updateDearProfitByCustomerDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearProfitByCustomerComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Customer Tags By Gross Profit") {
      dispatch(
        updateDearProfitByCustomerTagDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearProfitByCustomerTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Product Tags By Gross Profit") {
      dispatch(updateDearProfitByProductTagDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearProfitByProductTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Profit By Product Type") {
      dispatch(
        updateDearProfitByProductTypeDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearProfitByProductTypeComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }

    if (title === "Top 10 Brands By Units Sold") {
      dispatch(updateDearUnitsSoldByBrandDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearUnitsSoldByBrandComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Products By Units Sold") {
      dispatch(updateDearUnitsSoldByProductDateFilter({ dateFilter: filterA }));
      dispatch(
        updateDearUnitsSoldByProductComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Categories By Units Sold") {
      dispatch(
        updateDearUnitsSoldByCategoryDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsSoldByCategoryComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Customers By Units Sold") {
      dispatch(
        updateDearUnitsSoldByCustomerDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsSoldByCustomerComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Product Tags By Units Sold") {
      dispatch(
        updateDearUnitsSoldByProductTagDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsSoldByProductTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Units Sold by Product Type") {
      dispatch(
        updateDearUnitsSoldByProductTypeDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsSoldByProductTypeComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Customer Tags By Units Sold") {
      dispatch(
        updateDearUnitsSoldByCustomerTagDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsSoldByCustomerTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    // Units Shipped
    if (title === "Top 10 Brands By Units Shipped") {
      dispatch(
        updateDearUnitsShippedByBrandDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsShippedByBrandComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Products By Units Shipped") {
      dispatch(
        updateDearUnitsShippedByProductDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsShippedByProductComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Categories By Units Shipped") {
      dispatch(
        updateDearUnitsShippedByCategoryDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsShippedByCategoryComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Customers By Units Shipped") {
      dispatch(
        updateDearUnitsShippedByCustomerDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsShippedByCustomerComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Product Tags By Units Shipped") {
      dispatch(
        updateDearUnitsShippedByProductTagDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsShippedByProductTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Units Shipped by Product Type") {
      dispatch(
        updateDearUnitsShippedByProductTypeDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsShippedByProductTypeComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }
    if (title === "Top 10 Customer Tags By Units Shipped") {
      dispatch(
        updateDearUnitsShippedByCustomerTagDateFilter({ dateFilter: filterA })
      );
      dispatch(
        updateDearUnitsShippedByCustomerTagComparisonFilter({
          comparisonFilter: filterB,
        })
      );
    }

    setIsDropdownOpen(false);
  };
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!dropdownRef?.current?.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);
  const filterOptions = [
    { label: "This Week", value: "This Week" },
    { label: "Last Week", value: "Last Week" },
    { label: "This Month", value: "This Month" },
    { label: "Last Month", value: "Last Month" },
    { label: "This Quarter", value: "This Quarter" },
    { label: "Last Quarter", value: "Last Quarter" },
    { label: "Year to Date", value: "This Year" },
    { label: "Last Year", value: "Last Year" },
    { label: "Last 365 Days", value: "Last 365 Days" },
  ];
  const [toggleKPIDrillDownModal, setKPIDrillDownModal] = useState(false);
  const toggleDrillDownModalSheet = (title) => {
    setKPIDrillDownModal(!toggleKPIDrillDownModal);
  };

  const handleClick = () => {
    if (drilldownData && data.datasets.length > 0) {
      toggleDrillDownModalSheet();
    }
  };

  return (
    <div className="mt-5 w-full flex flex-col p-2 border rounded-lg border-light-grey bg-white">
      <div className="flex flex-row justify-end items-center gap-2">
        <div className="relative text-left">
          <div>
            <span className="rounded-md z-10 shadow-sm">
              <button
                type="button"
                className="inline-flex capitalize   justify-center text-[10px]  w-full rounded-md border  border-gray-300 bg-white px-3 py-1  font-medium text-gray-400 hover:bg-gray-50 focus:outline-none  "
                id="options-menu"
                aria-haspopup="true"
                aria-expanded={isDropdownOpen ? "true" : "false"}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {filterA === "This Year"
                  ? "Year to Date"
                  : filterA.replace("_", " ").replace(/^\w/, (c) => c.toUpperCase())}{" "}
                {/* {filterA.replace("_", " ").replace(/^\w/, (c) => c.toUpperCase())}{" "} */}
                ({filteredText})
                <svg
                  className="-mr-1 ml-2 h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 12a1 1 0 01-.7-.29l-4-4a1 1 0 111.41-1.42L10 9.59l3.29-3.3a1 1 0 111.42 1.42l-4 4a1 1 0 01-.71.29z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </span>
          </div>
          <div ref={dropdownRef}>
            {isDropdownOpen && (
              <div
                ref={dropdownRef}
                className="origin-top-right z-20 absolute text-gray-500 text-xs  w-[155px] rounded-md shadow-md bg-white ring-1 ring-black ring-opacity-5"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <div
                  className="flex gap-2 text-xs px-2 py-4 flex-col "
                  role="none"
                >
                  <p className="font-semibold text-xs text-grey">
                    Current Period
                  </p>
                  {filterOptions.map((option) => (
                    <label
                      key={option.value}
                      className="inline-flex items-center"
                    >
                      <input
                        type="radio"
                        className="form-radio text-purple h-4 w-4"
                        name="filterA"
                        value={option.value}
                        checked={filterA === option.value}
                        onChange={() =>
                          handleFilterChange("filterA", option.value)
                        }
                      />
                      <span className="ml-2">{option.label}</span>
                    </label>
                  ))}
                  <hr className="my-1" />
                  <p className="font-semibold text-xs text-grey">
                    Compare with
                  </p>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-purple h-4 w-4"
                      name="filterB"
                      value="Previous Period"
                      checked={filterB === "Previous Period"}
                      onChange={() =>
                        handleFilterChange(
                          "filterB",
                          "Previous Period"
                        )
                      }
                    />
                    <span className="ml-2">Previous Period</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-purple h-4 w-4"
                      name="filterB"
                      value="Previous Year"
                      checked={filterB === "Previous Year"}
                      onChange={() =>
                        handleFilterChange("filterB", "Previous Year")
                      }
                    />
                    <span className="ml-2">Previous Year</span>
                  </label>
                </div>
                <div className="flex justify-end px-2 py-2">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm py-1 bg-purple text-base font-medium text-white hover:bg-purple/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    onClick={handleGoButtonClick}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {isAdvancedDearFilters !== undefined ? (
          <div
            className="cursor-pointer"
            onClick={handleAdvanceDearFilters}
          >
            <BsFillFilterSquareFill className="text-purple" size={22} />
          </div>
        ) : null}

      </div>
      {isAdvancedDearFilters ? (
        <div className="w-full mt-2 ">
          <div className="w-full flex flex-col gap-2">
            <div className="flex items-center flex-row">
              <p className="w-1/4 text-[10px]">Brands: </p>
              <MultiSelect
                color="#fdba74"
                className="w-full"
                key="brand_id"
                options={brandFilterOptions}
                onChange={handleBrandChange}
                value={brandFilterSelected}
                isSelectAll={true}
                menuPlacement={"bottom"}
              />
            </div>
            <div className="flex items-center flex-row">
              <p className="w-1/4 text-[10px]">Product: </p>
              <MultiSelect
                className="w-full"
                key="product_tags_id"
                // color="#69b6ff"
                color="#addff7"
                options={productFilterOptions}
                onChange={handleProductChange}
                value={productFilterSelected}
                isSelectAll={true}
                menuPlacement={"bottom"}
              />
            </div>
            <div className="flex items-center flex-row">
              <p className="w-1/4 text-[10px]">Product Tag: </p>
              <MultiSelect
                className="w-full"
                key="product_tags_id"
                // color="#69b6ff"
                color="#7dd3fc"
                options={productTagsFilterOptions}
                onChange={handleProductTagsChange}
                value={productTagsFilterSelected}
                isSelectAll={true}
                menuPlacement={"bottom"}
              />
            </div>
            <div className="flex items-center flex-row">
              <p className="w-1/4 text-[10px]">Customer: </p>
              <MultiSelect
                className="w-full"
                key="customer_tag_id"
                color="#9ae6c7"
                options={contactFilterOptions}
                onChange={handleContactChange}
                value={contactFilterSelected}
                isSelectAll={true}
                menuPlacement={"bottom"}
              />
            </div>
            <div className="flex items-center flex-row">
              <p className="w-1/4 text-[10px]">Customer Tag: </p>
              <MultiSelect
                className="w-full"
                key="customer_tag_id"
                color="#6ee7b7"
                options={customerTagsFilterOptions}
                onChange={handleCustomerTagsChange}
                value={customerTagsFilterSelected}
                isSelectAll={true}
                menuPlacement={"bottom"}
              />
            </div>
            <div className="flex items-center flex-row">
              <p className="w-1/4 text-[10px]">Category: </p>
              <MultiSelect
                className="w-full"
                key="category_id"
                color="#d4d4d4"
                options={categoryFilterOptions}
                onChange={handleCategoryChange}
                value={categoryFilterSelected}
                isSelectAll={true}
                menuPlacement={"bottom"}
              />
            </div>
            <div className="flex items-center flex-row">
              <p className="w-1/4 text-[10px]">Type: </p>
              <MultiSelect
                className="w-full"
                key="product_type_id"
                color="#d8b4fe"
                options={productTypeFilterOptions}
                onChange={handleProductTypeChange}
                value={productTypeFilterSelected}
                isSelectAll={true}
                menuPlacement={"bottom"}
              />
            </div>
            <div className="flex items-center flex-row">
              <p className="w-1/4 text-[10px]">Location: </p>
              <MultiSelect
                className="w-full"
                key="product_type_id"
                color="#ff3b7b"
                options={locationFilterOptions}
                onChange={handleLocationChange}
                value={locationFilterSelected}
                isSelectAll={true}
                menuPlacement={"bottom"}
              />
            </div>
            <div className="flex items-center mt-3 flex-row gap-2 ">
              <button
                onClick={saveSelectedFilters}
                className="w-full inline-flex justify-center rounded-md border border-transparent text-xs shadow-sm py-2 bg-purple  font-medium text-white hover:bg-purple/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save Filters
              </button>
              <button
                type="button"
                onClick={handleAdvanceDearFiltersClose}
                className="w-full inline-flex justify-center rounded-md border border-slate-200 text-xs shadow-sm py-2  font-medium text-grey hover:bg-dark-purple hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
              >
                Close
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className={`h-[550px] mt-5 ${drilldownData ? 'cursor-pointer' : ''}`}
            onClick={handleClick}
          >
            <KPIDrillDownModal
              title={title}
              advanceFilterTitle={advanceFilterTitle}
              drilldownTitle={drilldownTitle}
              drilldownTooltip={drilldownTooltip}
              drilldownType={drilldownType}
              toggle={toggleKPIDrillDownModal}
              toggleModal={toggleDrillDownModalSheet}
              kpiName={title}
              tableData={drilldownData}
            />
            <Bar data={data} options={options} />
          </div>
          <div className="flex flex-wrap gap-1 p-2">
            {/* Render the filters */}
            {filtersToShow.map((selected, index) => (
              <div
                onClick={handleAdvanceDearFilters}
                key={index}
                className={`flex-none text-[10px] px-3 py-1 cursor-pointer rounded-md font-semibold
                    ${selected.colorClass || 'bg-gray-200 text-gray-600'}`}
              >
                {selected.value}
              </div>
            ))}

            {/* Show More/Show Less button */}
            {combinedFilters.length > 5 && (
              <Toggle
                onClick={() => setShowMore(prev => !prev)}
                variant="outline"
                className="flex-none text-[10px] h-6 cursor-pointer rounded-md"
              >
                {showMore ? 'Show Less' : `Show More (${combinedFilters.length - 5})`}
              </Toggle>
            )}
          </div>
        </>
      )}

    </div>
  );
}

export default HorizontalBarChart;
