import React, { useEffect, useState } from "react";

import DashboardBanner from "../../../components/ui-elements/banner/dashbaord-banner";


function DashboardWorkflowBetaPage(props) {
 
  return (
    <div className="w-full h-full px-5 py-5 bg-light-white flex flex-col">
      <div className="bg-white flex-1 p-5 border-2 border-light-grey mt-5 justify-center items-start">
        <DashboardBanner title="Workflow (Beta)" noRefreshButton={true} isGlobalFilterRequired={false} />
        
      </div>
    </div>
  );
}

export default DashboardWorkflowBetaPage;
