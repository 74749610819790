import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OrganisationModal from "../modals/organisation-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrganisations,
  selectOrganisation,
} from "../../../../redux-features/slices/organisation/orgSlice";
import { toast } from "react-toastify";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import cin7 from "../../../../assets/logos/cin7_logo.png";
import xero from "../../../../assets/logos/xero_logo.png";
import quickbooks from "../../../../assets/logos/quickbooks_logo.png";
import hubspot from "../../../../assets/logos/hubspot_logo.png";
import pipedrive from "../../../../assets/logos/pipedrive_logo.png";
import katana from "../../../../assets/logos/katana_logo.png";
import dearsystems from "../../../../assets/logos/cin7-core-teal-1.png";
import mrp from "../../../../assets/logos/mrpreasy_logo.png";
import { BsPlus } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";
import OrganisationActiveUsersModal from "../modals/organisation-active-users";
import Spinner2 from "../utilis/spinner-2";
import {
  embedLooker,
  setEmbedURL,
} from "../../../../redux-features/slices/looker/lookerSlice";
import Tippy from "@tippyjs/react";
import { getBalanceSheet, getDashboardDearData, getDashboardXeroData, getProfitAndLoss } from "../../../../redux-features/slices/kpi/kpiSlice";
import countryToSymbolMap from "../../../../constant/country-code-currency.json";
import {
  getDearProductSalesKPIFilteredDataCategory,
  getDearGrossProfitMarginKPIFilteredDataCategory,
  getDearInventoryValueKPIFilteredDataCategory,
} from "../../../../redux-features/slices/kpi/kpiSlice";

function OrganisationTable(props) {
  const apps = [
    {
      name: "Cin7",
      logo: cin7,
      category: "Accounting",
      link: "/connected-apps/apps/cin-7/",
    },
    {
      name: "Xero",
      logo: xero,
      category: "Accounting",
      link: "/connected-apps/apps/xero/",
    },
    {
      name: "Quickbooks",
      logo: quickbooks,
      category: "Accounting",
      link: "/",
    },
    {
      name: "MRP Easy",
      logo: mrp,
      category: "MRP/ERP",
      link: "/",
    },
    {
      name: "Katana",
      logo: katana,
      category: "MRP/ERP",
      link: "/",
    },
    {
      name: "DEAR",
      name: "DEAR",
      logo: dearsystems,
      category: "MRP/ERP",
      link: "/",
    },
    {
      name: "Pipedrive",
      logo: pipedrive,
      category: "CRM",
      link: "/",
    },
    {
      name: "Hubspot",
      logo: hubspot,
      category: "CRM",
      link: "/",
    },
  ];

  const categories = [
    "Superior",
    "Superior Deluxe Panels",
    "Ultimate Panels",
    "Aluminium Posts",
    "Concrete Posts",
    "Composite Gates",
    "Lights",
    "Pergolas",
    "3D Wall Panels",
    "Composite Posts",
    "Aluminium Gates",
    "Screens",
  ];

  const products = [
    [
      "Chestnut Fence Boards",
      "Chestnut Fence Boards - 2200mm",
      "Chestnut Fence Boards - 2400mm",
      "Chestnut Fence Boards - 2500mm",
      "Irish Oak Fence Boards",
      "Irish Oak Fence Boards - 2.8m",
      "Irish Oak Fence Boards - 2200mm",
      "Irish Oak Fence Boards - 2500mm",
      "Irish Oak Fence Boards 2.4m",
      "Pebble Grey Fence Boards",
      "Pebble Grey Fence Boards - 2200mm",
      "Pebble Grey Fence Boards - 2500mm",
      "Pebble Grey Woodgrain",
      "Pebble Grey Woodgrain Fence Boards - 2200mm",
      "Pebble Grey Woodgrain Fence Boards - 2500mm",
      "Sanded Chestnut Fence Boards",
      "Slate Grey (Sanded) Fence Boards",
      "Slate Grey Fence Boards - 2200mm",
      "Slate Grey Fence Boards - 2500mm",
      "Slate Grey Fence Boards - 3000mm",
      "Slate Grey Woodgrain",
      "Slate Grey Woodgrain Board 2.75m",
      "Slate Grey Woodgrain Fence Boards - 2200mm",
      "Slate Grey Woodgrain Fence Boards - 2500mm",
      "Walnut Fence Boards",
      "Walnut Fence Boards - 2200mm",
      "Walnut Fence Boards - 2500mm"
    ],
    [
      "Black Deluxe Fence Board",
      "Chestnut Deluxe Deluxe Fence Board",
      "Irish Oak Deluxe Fence Board",
      "Pebble Grey Deluxe Fence Board",
      "Pebble Grey Woodgrain Deluxe Fence Board",
      "Slate Grey (Sanded) Deluxe Fence Board",
      "Slate Grey Woodgrain Deluxe Fence Board",
      "Slate Grey Woodgrain Deluxe Fence Board - 2500mm",
      "Walnut Deluxe Fence Board"
    ],
    
    [
      "Evergreen Fence Board",
      "Evergreen Woodgrain Fence Board",
      "Wpc Fence Board (Charcoal Woodgrain)",
      "Wpc Fence Board (Charcoal)",
      "Wpc Fence Board (Charcoal) Sanded",
      "Wpc Fence Board (Dark Oak)",
      "Wpc Fence Board (SANDED Dark Oak)",
      "Wpc Fence Board (Stone)"
    ],
    [
      "Superior - 1.9M Aluminium Post Kit - Graphite",
      "Superior - 2.4M Aluminium End Post Kit - Graphite",
      "Superior - 2.7M Aluminium End Post Kit - Graphite",
      "Ultimate Package 3.5m Dark Grey Post",
      "Ultimate Package 3C - End Post Grey",
      "Ultimate Package 3m Dark Grey Post"
    ],    
    ["Concrete Channel Insert (6ft)"],
    [
      "Composite Pedestrian Gate - Graphite & Chestnut - 900mm x 1800mm",
      "Composite Pedestrian Gate - Graphite & Irish Oak - 900mm x 1800mm",
      "Composite Pedestrian Gate - Graphite & Pebble Grey Sanded - 900mm x 1800mm",
      "Composite Pedestrian Gate - Graphite & Pebble Grey Woodgrain - 900mm x 1800mm",
      "Composite Pedestrian Gate - Graphite & Slate Grey Sanded - 900mm x 1800mm",
      "Composite Pedestrian Gate - Graphite & Slate Grey Woodgrain - 900mm x 1800mm",
      "Composite Pedestrian Gate - Graphite & Walnut - 900mm x 1800mm",
      "Composite Pedestrian Gate Frame - 900mm x 1200mm - Graphite",
      "Composite Pedestrian Gate Frame - 900mm x 1200mm - RAL 7016",
      "Composite Pedestrian Gate Frame - 900mm x 2000mm - Graphite",
      "Composite Pedestrian Split Gate Frame - 900mm x 1800mm - Graphite"
    ],    
    [
      "Led - Vertical Solar Strip Light",
      "Led Post Cap",
      "Led Strip light c/w solar post cap",
      "Superior - Led Post Lights",
      "Ultimate Post Light"
    ],
    [
      "Pergola - 3m Electric Screen",
      "Pergola - 3m Glass Doors",
      "Pergola - 4m Electric Screen",
      "Pergola - 4m Glass Doors",
      "Pergola - LED Strip Light",
      "Pergola Structure - 3m x 2m",
      "Pergola Structure - 3m x 3m",
      "Pergola Structure - 3m x 4m"
    ],    
    [
      "3D Wall Sample - Platinum, Flint, Driftwood & Rosewood",
      "Driftwood Wall - 2.4m 3D Panel",
      "Flint Wall - 2.4m 3D Panel",
      "Platinum Wall - 2.4m 3D Panel",
      "Rosewood Wall - 2.4m 3D Panel"
    ],
    [
      "Superior - 1.9M Composite Corner Post - Slate Grey",
      "Superior - 1.9M Composite Corner Post - Walnut",
      "Superior - 1.9M Composite Mid Post - Slate Grey",
      "Superior - 1.9M Composite Mid Post - Walnut"
    ]
    ,
    [
      "Aluminium Pedestrian - RAL 7016 - Horizontal",
      "Aluminium Pedestrian - RAL 7016 - Vertical",
      "Horizontal Aluminium Pedestrian Gate Anthracite - 1000mm x 1800mm",
      "100mm W, 3m Long Anthracite Pedestrian Gate Post",
      "2.4M Driveway Gate Post 100mm x 100mm",
      "2.4m Driveway Gate Post 150mm x 150mm",
      "3m Driveway Gate Post 150mm x 150mm",
      "50mm x 80mm 3m Long Pedestrian Gate Post",
      "Horizontal Anthracite 1000mm x 1600mm Gate",
      "Horizontal Anthracite 1000mm x 1800mm Gate",
      "Horizontal Anthracite 1000mm x 2000mm Gate",
      "Horizontal Anthracite 1000mm x 2200mm Gate",
      "Horizontal Anthracite 1200mm x 1600mm Gate",
      "Horizontal Anthracite 1200mm x 1800mm Gate",
      "Horizontal Anthracite 1200mm x 2000mm Gate",
      "Horizontal Anthracite 1200mm x 2200mm Gate",
      "Horizontal Anthracite 3000mm x 1600mm Driveway Gate",
      "Horizontal Anthracite 3000mm x 1800mm Driveway Gate",
      "Horizontal Anthracite 3000mm x 2000mm Driveway Gate",
      "Horizontal Anthracite 3250mm x 1600mm Driveway Gate",
      "Horizontal Anthracite 3250mm x 1800mm Driveway Gate",
      "Horizontal Anthracite 3250mm x 2000mm Driveway Gate",
      "Horizontal Anthracite 3500mm x 1600mm Driveway Gate",
      "Horizontal Anthracite 3500mm x 1800mm Driveway Gate",
      "Horizontal Anthracite 3500mm x 2000mm Driveway Gate",
      "Horizontal Anthracite 3750mm x 1600mm Driveway Gate",
      "Horizontal Anthracite 3750mm x 1800mm Driveway Gate",
      "Horizontal Anthracite 3750mm x 2000mm Driveway Gate",
      "Horizontal Anthracite 3750mm x 2200mm Driveway Gate",
      "Horizontal Anthracite 4000mm x 1600mm Driveway Gate",
      "Horizontal Anthracite 4000mm x 1800mm Driveway Gate",
      "Horizontal Anthracite 4000mm x 2000mm Driveway Gate",
      "Horizontal Anthracite 4000mm x 2200mm Driveway Gate",
      "Horizontal Anthracite 900mm x 1600mm Gate",
      "Horizontal Anthracite 900mm x 1800mm Gate",
      "Horizontal Anthracite 900mm x 2000mm Gate",
      "Horizontal Anthracite 900mm x 2200mm Gate",
      "Horizontal Black 1000mm x 1600mm Black",
      "Horizontal Black 1000mm x 1800mm Gate",
      "Horizontal Black 1000mm x 2000mm Gate",
      "Horizontal Black 1000mm x 2200mm Gate",
      "Horizontal Black 1200mm x 1600mm Gate",
      "Horizontal Black 1200mm x 1800mm Gate",
      "Horizontal Black 1200mm x 2000mm Gate",
      "Horizontal Black 1200mm x 2200mm Gate",
      "Horizontal Black 3000mm x 1800mm Driveway Gate",
      "Horizontal Black 3250mm x 1600mm Driveway Gate",
      "Horizontal Black 3250mm x 1800mm Driveway Gate",
      "Horizontal Black 3250mm x 2000mm Driveway Gate",
      "Horizontal Black 3500mm x 1600mm Driveway Gate",
      "Horizontal Black 3500mm x 1800mm Driveway Gate",
      "Horizontal Black 3500mm x 2000mm Driveway Gate",
      "Horizontal Black 3750mm x 1600mm Driveway Gate",
      "Horizontal Black 3750mm x 1800mm Driveway Gate",
      "Horizontal Black 3750mm x 2000mm Driveway Gate",
      "Horizontal Black 3750mm x 2200mm Driveway Gate",
      "Horizontal Black 4000mm x 1600mm Driveway Gate",
      "Horizontal Black 4000mm x 1800mm Driveway Gate",
      "Horizontal Black 4000mm x 2000mm Driveway Gate",
      "Horizontal Black 4000mm x 2200mm Driveway Gate",
      "Horizontal Black 900mm x 1600mm Gate",
      "Horizontal Black 900mm x 1800mm Gate",
      "Horizontal Black 900mm x 2000mm Gate",
      "Horizontal Black 900mm x 2200mm Gate",
      "Horizontal Driveway Gate Black 3000mm x 1600mm",
      "Horizontal Graphite 900mm x 1800mm Gate",
      "Pedestrian Gate 2.4m Anthracite Post",
      "Pedestrian Gates Keypad Lock",
      "Vertical Anthracite 1000mm x 1600mm Gate",
      "Vertical Anthracite 1000mm x 1800mm Gate",
      "Vertical Anthracite 1000mm x 2000mm Gate",
      "Vertical Anthracite 1000mm x 2200mm Gate",
      "Vertical Anthracite 1100mm x 1800mm Gate",
      "Vertical Anthracite 1100mm x 2000mm Gate",
      "Vertical Anthracite 1100mm x 2200mm Gate",
      "Vertical Anthracite 1200mm x 1600mm Gate",
      "Vertical Anthracite 1200mm x 1800mm Gate",
      "Vertical Anthracite 1200mm x 2000mm Gate",
      "Vertical Anthracite 1200mm x 2200mm Gate",
      "Vertical Anthracite 900mm x 1600mm Gate",
      "Vertical Anthracite 900mm x 1800mm Gate",
      "Vertical Anthracite 900mm x 2000mm Gate",
      "Vertical Anthracite 900mm x 2200mm Gate",
      "Vertical Anthracite Pedestrian Gate 1100mm x 1600mm",
      "Vertical Black 1000mm x 1600mm Gate",
      "Vertical Black 1000mm x 1800mm Gate",
      "Vertical Black 1000mm x 2000mm Gate",
      "Vertical Black 1000mm x 2200mm Gate",
      "Vertical Black 1100mm x 1600mm Gate",
      "Vertical Black 1100mm x 1800mm Gate",
      "Vertical Black 1100mm x 2000mm Gate",
      "Vertical Black 1100mm x 2200mm Gate",
      "Vertical Black 1200mm x 1600mm Gate",
      "Vertical Black 1200mm x 1800mm Gate",
      "Vertical Black 1200mm x 2000mm Gate",
      "Vertical Black 1200mm x 2200mm Gate",
      "Vertical Black 900mm x 1600mm Gate",
      "Vertical Black 900mm x 1800mm Gate",
      "Vertical Black 900mm x 2000mm Gate",
      "Vertical Black 900mm x 2200mm Gate",
      "Vertical Graphite 1000mm x 1600mm Gate",
      "Vertical Graphite 1000mm x 1800mm Gate",
      "Vertical Graphite 1000mm x 2000mm Gate",
      "Vertical Graphite 1000mm x 2200mm Gate",
      "Vertical Graphite 1100mm x 1600mm Gate",
      "Vertical Graphite 1100mm x 1800mm Gate",
      "Vertical Graphite 1100mm x 2000mm Gate",
      "Vertical Graphite 1100mm x 2200mm Gate",
      "Vertical Graphite 1200mm x 1600mm Gate",
      "Vertical Graphite 1200mm x 1800mm Gate",
      "Vertical Graphite 1200mm x 2000mm Gate",
      "Vertical Graphite 1200mm x 2200mm Gate",
      "Vertical Graphite 900mm x 1600mm Gate",
      "Vertical Graphite 900mm x 1800mm Gate",
      "Vertical Graphite 900mm x 2000mm Gate",
      "Vertical Graphite 900mm x 2200mm Gate"
    ],    
    [
      "Autumn Life - 19mm - Black - 300mm x 1830mm",
      "Autumn Life - 19mm - Black - 900mm x 1830mm",
      "Autumn Life - 19mm - Dove Grey - 300mm x 1830mm",
      "Autumn Life - 19mm - Grey - 300mm x 1830mm",
      "Autumn Life - 19mm - Grey - 900mm x 1830mm",
      "Autumn Life - 6mm - Black - 300mm x 1830mm",
      "Autumn Life - 6mm - Black - 900mm x 1830mm",
      "Autumn Life - 6mm - Grey - 300mm x 1830mm",
      "Autumn Life - 6mm - Grey - 900mm x 1830mm",
      "Bonsai Breeze - 19mm - Black - 300mm x 1830mm",
      "Bonsai Breeze - 19mm - Black - 900mm x 1830mm",
      "Bonsai Breeze - 19mm - Dove Grey - 300mm x 1830mm",
      "Bonsai Breeze - 19mm - Grey - 300mm x 1830mm",
      "Bonsai Breeze - 19mm - Grey - 900mm x 1830mm",
      "Bonsai Breeze - 6mm - Black - 300mm x 1830mm",
      "Bonsai Breeze - 6mm - Black - 900mm x 1830mm",
      "Bonsai Breeze - 6mm - Grey - 300mm x 1830mm",
      "Bonsai Breeze - 6mm - Grey - 900mm x 1830mm",
      "Bonsai Breeze Curve - 19mm - Black - 400mm x 1830mm",
      "Criss Cross - 19mm - Black - 300mm x 1830mm",
      "Criss Cross - 19mm - Dove Grey - 300mm x 1830mm",
      "Criss Cross - 19mm - Grey - 300mm x 1830mm",
      "Heat Wave - 19mm - Black - 300mm x 1830mm",
      "Heat Wave - 19mm - Black - 900mm x 1830mm",
      "Heat Wave - 19mm - Grey - 300mm x 1830mm",
      "Heat Wave - 19mm - Grey - 900mm x 1830mm",
      "Heat Wave - 6mm - Black - 300mm x 1830mm",
      "Heat Wave - 6mm - Black - 900mm x 1830mm",
      "Heat Wave - 6mm - Grey - 300mm x 1830mm",
      "Heat Wave - 6mm - Grey - 900mm x 1830mm",
      "Indian Summer - 19mm - Black - 300mm x 1830mm",
      "Indian Summer - 19mm - Black - 900mm x 1830mm",
      "Indian Summer - 19mm - Grey - 300mm x 1830mm",
      "Indian Summer - 19mm - Grey - 900mm x 1830mm",
      "Indian Summer - 6mm - Black - 300mm x 1830mm",
      "Indian Summer - 6mm - Black - 900mm x 1830mm",
      "Indian Summer - 6mm - Grey - 300mm x 1830mm",
      "Indian Summer - 6mm - Grey - 900mm x 1830mm",
      "Japanese Garden - 19mm - Black - 300mm x 1830mm",
      "Japanese Garden - 19mm - Black - 900mm x 1830mm",
      "Japanese Garden - 19mm - Grey - 300mm x 1830mm",
      "Japanese Garden - 19mm - Grey - 900mm x 1830mm",
      "Japanese Garden - 6mm - Black - 300mm x 1830mm",
      "Japanese Garden - 6mm - Black - 900mm x 1830mm",
      "Japanese Garden - 6mm - Grey - 300mm x 1830mm",
      "Japanese Garden - 6mm - Grey - 900mm x 1830mm",
      "Paradise Palm - 19mm - Black - 300mm x 1830mm",
      "Paradise Palm - 19mm - Black - 900mm x 1830mm",
      "Paradise Palm - 19mm - Grey - 300mm x 1830mm",
      "Paradise Palm - 19mm - Grey - 900mm x 1830mm",
      "Paradise Palm - 6mm - Black - 300mm x 1830mm",
      "Paradise Palm - 6mm - Black - 900mm x 1830mm",
      "Paradise Palm - 6mm - Grey - 300mm x 1830mm",
      "Paradise Palm - 6mm - Grey - 900mm x 1830mm",
      "Rosemary Spice - 19mm - Black - 300mm x 1830mm",
      "Rosemary Spice - 19mm - Black - 900mm x 1830mm",
      "Slatted - 19mm - Black - 300mm x 1830mm",
      "Slatted - 19mm - Grey - 300mm x 1830mm"
    ],
    
  ];
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { organisations, organisation, isLoadingAll, isDisplay, token, lastViewed } = props;

  useEffect(() => {
    dispatch(getAllOrganisations({ token }));
  }, [dispatch]);

  const [toggleOrganisation, setOrganisationToggle] = useState(false);
  const [toggleOrganisationActiveUsers, setOrganisationActiveUsersToggle] =
    useState(false);
  const [appType, setAppType] = useState("Dear");

  const toggleOrganisationActiveUsersModal = (slug) => {
    setOrganisationActiveUsersToggle(!toggleOrganisationActiveUsers);
    dispatch(selectOrganisation({ slug, token }));
  };

  const toggleOrganisationModal = () => {
    setOrganisationToggle(!toggleOrganisation);
  };

  const [openDropdownOrgId, setOpenDropdownOrgId] = useState(null);

  const toggleDropdown = (orgSlug) => {
    setOpenDropdownOrgId(openDropdownOrgId === orgSlug ? null : orgSlug);
  };

  const handleOrganisationDelete = (slug) => {
    toast.info("A request has been sent to the admin in this regards", {
      autoClose: 10000,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
    });
    toggleDropdown(slug);
  };

  const [isEdit, setIsEdit] = useState(false);

  const handleOrganisationEdit = async (slug) => {
    setIsEdit(true);
    dispatch(selectOrganisation({ slug, token }));
    toggleOrganisationModal();
  };

  const handleOrganisationSelect = (slug) => {
    dispatch(selectOrganisation({ slug, token }));
    navigate("/connected-apps/apps/");
  };

  // const handleOrganisationSelectLooker = (slug, embedURL, appType) => {
  //   dispatch(selectOrganisation({ slug, token }));
  //   if (slug === "louella-belle") {
  //     dispatch(setEmbedURL({ embedURL, appType }));
  //   } else {
  //     dispatch(embedLooker({ slug, token }));
  //     navigate("/dashboard/main");
  //   }
  // };

  // const handleOrganisationDashboardClick = async (slug) => {
  //   // Dispatch selectOrganisation without waiting for it to complete
  //   dispatch(selectOrganisation({ slug, token }));

  //   // If XERO is among the connected apps, dispatch getDashboardXeroData
  //     dispatch(getDashboardXeroData({ slug, token }));

  //     const params = {
  //       brand: '',
  //       category: '',
  //       contact: '',
  //       product: '',
  //       productType: '',
  //       productTags: '',
  //       customerTags: ''
  //       // Add more parameters here if needed
  //     };
  //     dispatch(getDashboardDearData({ slug, token, params }));
  //   navigate("/dashboard/home");
  // };

  const handleOrganisationDashboardClick = async (slug, xero_dataset_id, dear_dataset_id) => {
    await dispatch(selectOrganisation({ slug, token }));

    const params = {
      brand: '',
      category: '',
      contact: '',
      product: '',
      productType: '',
      productTags: '',
      customerTags: '',
      productFamily:''
    };
    navigate("/dashboard/home");
    
    const promises = [
      dispatch(getDashboardXeroData({ slug, xero_dataset_id, token })),
      dispatch(getDashboardDearData({ slug, dear_dataset_id, token, params })),
      dispatch(getProfitAndLoss({ dataset_id: xero_dataset_id, token})),
      dispatch(getBalanceSheet({ dataset_id: xero_dataset_id, token})),
    ];

    categories.forEach((categoryName, index) => {
      const categoryProducts = Array.isArray(products[index]) ? products[index] : [];
      
      // Join the elements of the array with ", "
      const categoryProductsF = categoryProducts.join(", ")
      const categoryParams = {
        brand: '',
        category: '',
        contact: '',
        product: categoryProductsF,
        productType: '',
        productTags: '',
        customerTags: '',
      }
      promises.push(
        dispatch(
          getDearProductSalesKPIFilteredDataCategory({
            dataset_id: dear_dataset_id,
            token,
            params: categoryParams,
            categoryFilter: categoryName,
          })
        )
      );

      promises.push(
        dispatch(
          getDearGrossProfitMarginKPIFilteredDataCategory({
            dataset_id: dear_dataset_id,
            token,
            params: categoryParams,
            categoryFilter: categoryName,
          })
        )
      );

      promises.push(
        dispatch(
          getDearInventoryValueKPIFilteredDataCategory({
            dataset_id: dear_dataset_id,
            token,
            params: categoryParams,
            categoryFilter: categoryName,
          })
        )
      );
    });

    await Promise.all(promises);
  };

  if (organisations?.length === 0) {
    return (
      <>
        <div className="flex flex-col bg-light-grey-2  w-full last:border-2">
          <div>
            <table className="table-auto w-full relative border-collapse ">
              <thead>
                <tr className="justify-center border-b-2  border-grey  shadow-sm">
                  <th className=" text-lg text-dark-green py-2 px-4">
                    Organisation
                  </th>
                  <th className=" text-md text-dark-green p-4 font-normal ">
                    Connected Apps
                  </th>
                  <th className=" text-md text-dark-green font-normal p-4">
                    Access Level
                  </th>
                  <th className=" text-md text-dark-green font-normal p-4">
                    Active Users
                  </th>

                  <th className=" text-md text-dark-green font-normal p-4 ">
                    Last Connected
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="flex flex-col h-64 items-center gap-6 justify-center">
            <h4 className="text-md">
              To get started, please connect your first organisation
            </h4>
            <button
              onClick={toggleOrganisationModal}
              className="  bg-warning text-black font-bold py-2 px-4 rounded"
            >
              + Add new organisation
            </button>
            <OrganisationModal
              token={token}
              organisation={organisation}
              toggle={toggleOrganisation}
              toggleModal={toggleOrganisationModal}
            />
          </div>
        </div>
      </>
    );
    // or return a loading state or error message
  }
  // const countryCode = organisation?.country_alpha3.replace(/"/g, ""); // Remove double quotes
  // const currencySymbol = countryToSymbolMap[countryCode];
  return (
    <div className="relative overflow-x-auto text-sm h-full">
      <OrganisationActiveUsersModal
        token={token}
        organisation={organisation}
        toggle={toggleOrganisationActiveUsers}
        toggleModal={toggleOrganisationActiveUsersModal}
      />

      {isDisplay ? (
        <>
          {organisations && (
            <div className="bg-white p-3 w-full pb-16 z-20 rounded-lg">
              <table className="table-auto  z-5 w-full relative border-collapse bg-white p-2 ">
                <thead className="text-black border-b-2 border-black rounded-md">
                  <tr className=" text-left text-md shadow-sm ">
                    <th className="border-b font-bold  border-gray-300 p-4">
                      Organisation
                    </th>
                    <th className="border-b font-medium border-gray-300 p-4">
                      Connected Apps
                    </th>
                    <th className="border-b font-medium border-gray-300 p-4">
                      Access Level
                    </th>
                    <th className="border-b font-medium border-gray-300 p-4">
                      Active Users
                    </th>
                    <th className="border-b font-medium border-gray-300 p-4">
                      Last Connected
                    </th>
                    <th className="border-b font-medium border-gray-300 p-4 "></th>
                  </tr>
                </thead>
                <tbody className="">
                  {organisations
                    ?.slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(
                      (org) =>
                        org && (
                          <tr key={org?.slug} className=" ">
                            <td className="px-2 py-2 border-b-2 border-grey">
                              <div className="flex gap-2">
                                <span className="font-bold">{`[${org?.country_alpha3}] `}</span>
                                <p
                                  onClick={() =>
                                    handleOrganisationDashboardClick(org?.slug, org?.dataset_ids?.xero, org?.dataset_ids?.dear)
                                  }
                                  className="hover:underline cursor-pointer"
                                  style={{ margin: 0 }}
                                >
                                  {org?.name}
                                </p>
                              </div>
                            </td>

                            <td className="border-b-2 border-grey z-10 px-2 py-2">
                              {org.connected_apps.length ? (
                                <div className="flex flex-row justify-start w-full ">
                                  <div className="flex flex-row justify-start w-32  ">
                                    {apps
                                      ?.filter((app) =>
                                        org?.connected_apps?.some(
                                          (connectedApp) =>
                                            connectedApp.toLowerCase() ===
                                            app.name.toLowerCase()
                                        )
                                      )
                                      .map((app) => (
                                        <div className=" w-12  ">
                                          {app.name === "Xero" ? (
                                            <>
                                              <div
                                                className="rounded-full  h-9 w-9"
                                              // onClick={() =>
                                              //   handleOrganisationSelectLooker(
                                              //     org.slug,
                                              //     org.xero_embed
                                              //   )
                                              // }
                                              >
                                                <Tippy
                                                  theme="custom-white"
                                                  size="small"
                                                  placement="top"
                                                  content="Xero"
                                                >
                                                  <img
                                                    className="rounded-full object-contain h-full w-full"
                                                    src={app.logo}
                                                    alt={app.name}
                                                  />
                                                </Tippy>
                                              </div>
                                            </>
                                          ) : null}
                                          {app.name === "Cin7" ? (
                                            <div
                                              className="rounded-full m-1.5 h-6 w-6"
                                            // onClick={() =>
                                            //   handleOrganisationSelectLooker(
                                            //     org.slug,
                                            //     org.cin7_embed
                                            //   )
                                            // }
                                            >
                                              <Tippy
                                                theme="custom-white"
                                                size="small"
                                                placement="top"
                                                content="Cin7 Omni"
                                              >
                                                <img
                                                  className="rounded-full object-contain h-full w-full"
                                                  src={app.logo}
                                                />
                                              </Tippy>
                                            </div>
                                          ) : null}
                                          {app.name === "DEAR" ? (
                                            <div
                                              className="rounded-full m-1.5 h-6 w-6"
                                            // onClick={() =>
                                            //   handleOrganisationSelectLooker(
                                            //     org.slug,
                                            //     org.dear_embed,
                                            //     "Dear"
                                            //   )
                                            // }
                                            >
                                              <Tippy
                                                theme="custom-white"
                                                size="small"
                                                placement="top"
                                                content="Cin7 Core"
                                              >
                                                <img
                                                  className="rounded-full object-contain h-full w-full"
                                                  src={app.logo}
                                                />
                                              </Tippy>
                                            </div>
                                          ) : null}
                                        </div>
                                      ))}
                                  </div>
                                  <div
                                    className="p-2  cursor-pointer "
                                    onClick={() =>
                                      handleOrganisationSelect(org.slug)
                                    }
                                  >
                                    <BsPlus
                                      size={20}
                                      className="text-black bg-warning "
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="cursor-pointer w-full"
                                  onClick={() =>
                                    handleOrganisationSelect(org.slug)
                                  }
                                >
                                  <button className=" w-40 bg-warning text-sm text-black font-bold p-2 rounded">
                                    + Connect Apps
                                  </button>
                                </div>
                              )}
                            </td>
                            <td className="border-b-2 px-4 py-2 border-grey">
                              {org.owner ? "Admin" : ""}
                            </td>
                            <td className="border-b-2 px-4 py-2 border-grey">
                              <span
                                onClick={() =>
                                  toggleOrganisationActiveUsersModal(org.slug)
                                }
                              >
                                {org.staffs.length} User
                              </span>
                            </td>

                            <td className="border-b-2 border-grey px-4 py-2">
                              {moment(org.updated_at).fromNow()}
                            </td>


                            <td className="border-b-2 z-5  border-grey px-4 py-2 cursor-pointer">
                              <div
                                className="relative flex flex-row gap-3"
                                onClick={() => toggleDropdown(org.slug)}
                              >
                                <AiFillEdit
                                  onClick={() => {
                                    handleOrganisationEdit(org.slug);
                                  }}
                                  className="text-grey hover:text-warning"
                                  size={20}
                                />
                                {/* <MdDelete
                                  className="text-grey hover:text-rose-500"
                                  size={20}
                                  onClick={() => {
                                    handleOrganisationDelete(org.slug);
                                  }}
                                /> */}
                              </div>
                            </td>

                          </tr>
                        )
                    )}
                  <OrganisationModal
                    token={token}
                    toggleDropdown={toggleDropdown}
                    isEdit={isEdit}
                    toggle={toggleOrganisation}
                    toggleModal={toggleOrganisationModal}
                  />
                </tbody>
              </table>
            </div>
          )}
        </>
      ) : (
        <div className=" h-96 w-full bg-light-white">
          <Spinner2 note="Please wait while we are fetching your organisations..." />
        </div>
      )}
    </div>
  );
}

export default OrganisationTable;
