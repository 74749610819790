import React from "react";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";

const SimpleSparkline = (props) => {
    const {sparkLineData, color}=props
  return (
    <Sparklines data={sparkLineData} style={{ width: "80px", height: "30px" }}>
      <SparklinesLine
        color={color}
        style={{ fill: "none", strokeWidth: "5" }}
      />
      {/* <SparklinesSpots /> */}
    </Sparklines>
  );
};

export default SimpleSparkline;
