import React, { useEffect, Suspense, lazy, useState } from "react";
import { IoIosClose, IoIosInformationCircle } from "react-icons/io";
import Spinner2 from "../../utilis/spinner-2";
import { Expand } from "lucide-react";
import Tippy from "@tippyjs/react";

// Lazy-loaded table components (no changes here)
const DrillDown01ProductKPITable = lazy(() =>
  import(
    "../../tables/kpis-tables/dear/drill-down-1/drill-down-1-product-table"
  )
);
const DrillDown01CustomerKPITable = lazy(() =>
  import(
    "../../tables/kpis-tables/dear/drill-down-1/drill-down-1-customer-table"
  )
);
const DrillDownPercentageCustomer80Sales = lazy(() =>
  import(
    "../../tables/kpis-tables/dear/drill-down-1/percentage-customer-80-sales-table"
  )
);
const DrillDownPercentageProduct80Sales = lazy(() =>
  import(
    "../../tables/kpis-tables/dear/drill-down-1/percentage-product-80-sales-table"
  )
);
const DrillDownInventoryValueTable=lazy(()=>import("../../tables/kpis-tables/dear/drill-down-1/inventory-value-table"))
const DrillDownIncomingInventoryTable=lazy(()=>import("../../tables/kpis-tables/dear/drill-down-1/incoming-inventory-table"))
function KPIDrillDownModal(props) {
  const {
    title,
    drilldownTitle,
    drilldownTooltip,
    advanceFilterTitle,
    toggle,
    toggleModal,
    tableData,
    drilldownType,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const handleClose = () => {
    toggleModal();
    setIsExpanded(false);
  };

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (toggle) {
      document.body.style.overflow = "hidden";

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } else {
      document.body.style.overflow = "";
      setIsLoading(true);
    }

    return () => {
      document.body.style.overflow = "";
      setIsLoading(true);
    };
  }, [toggle]);

  return (
    <div className="z-100">
      {toggle && (
        <div className="fixed z-30 inset-0 overflow-hidden no-scrollbar">
          <div className="flex items-center justify-center bg-black bg-opacity-50 min-h-screen p-8">
            <div
              className={`bg-white rounded-lg py-2 px-5 overflow-hidden no-scrollbar ${
                isExpanded
                  ? "w-full h-full min-h-[850px] max-h-[1000px]"
                  : "w-[1200px] max-h-[800px] h-[600px]"
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex flex-row mb-4 justify-between items-start">
                <h2 className="text-lg font-bold mt-4 flex gap-1 items-center">
                  {drilldownTitle}
                  <Tippy
                    theme="custom-white"
                    size="small"
                    placement="top"
                    content={drilldownTooltip}
                  >
                    <div>
                      <IoIosInformationCircle
                        className="  text-grey"
                        size={14}
                      />
                    </div>
                  </Tippy>
                  {/* <span className="font-medium text-purple">{title}</span> */}
                </h2>
                <div className="flex mt-4 items-center gap-2">
                  <button
                    onClick={handleExpand}
                    className="text-sm text-gray-500"
                  >
                    <Expand size={16} />
                  </button>
                  <IoIosClose
                    onClick={handleClose}
                    className="cursor-pointer"
                    size={35}
                  />
                </div>
              </div>

              {isLoading ? (
                <Spinner2 />
              ) : (
                <Suspense fallback={<Spinner2 />}>
                  {drilldownTitle ===
                  "% of Customers Who Make Up 80% of Sales Drilldown" ? (
                    <DrillDownPercentageCustomer80Sales
                      title={title}
                      drilldownTitle={drilldownTitle}
                      tableData={tableData}
                    />
                  ) : drilldownTitle ===
                    "% of Products That Make Up 80% of Sales Drilldown" ? (
                    <DrillDownPercentageProduct80Sales
                    title={title}
                    drilldownTitle={drilldownTitle}
                      tableData={tableData}
                    />
                  ) : drilldownTitle ===
                  "Inventory Value Drilldown" ? (
                  <DrillDownInventoryValueTable
                  title={title}
                  drilldownTitle={drilldownTitle}
                    tableData={tableData}
                  />)
                  : drilldownTitle ===
                  "Incoming Stock Drilldown" ? (
                  <DrillDownIncomingInventoryTable
                  title={title}
                  drilldownTitle={drilldownTitle}
                    tableData={tableData}
                  />)
                  : drilldownType === "Product" ? (
                    // Fallback or default product drill-down table
                    <DrillDown01ProductKPITable
                    title={title}
                    drilldownTitle={drilldownTitle}
                    advanceFilterTitle={advanceFilterTitle}

                      tableData={tableData}
                    />
                  ) : drilldownType === "Customer" ? (
                    // Fallback or default customer drill-down table
                    <DrillDown01CustomerKPITable
                    title={title}
                    advanceFilterTitle={advanceFilterTitle}
                    drilldownTitle={drilldownTitle}
                      tableData={tableData}
                    />
                  ) : (
                    // Fallback for when no conditions match
                    <div>No data available for the selected criteria.</div>
                  )}
                </Suspense>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default KPIDrillDownModal;
