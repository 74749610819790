import React, { useState } from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import { IoIosInformationCircle } from "react-icons/io";
import countryToSymbolMap from "../../../../../../../constant/country-code-currency.json";
import dear from "../../../../../../../assets/logos/cin7-core-teal-1.png";
import SpinnerKPI from "../../../../utilis/spinner-kpi";
import AdvancedDearFilters from "../../../../filters/dear-advance-filters";
import { BsFillFilterSquareFill } from "react-icons/bs";
import { Expand } from "lucide-react";
import ProductBySalesByMonthModal from "../../../../modals/kpis-modals/product-by-sales-by-month-modal";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger, DropdownMenuCheckboxItem } from "../../../../../../../shadcn-components/ui/dropdown-menu";
import { ArrowUpDown } from "lucide-react";
import { ColumnFilterMenu } from "../drill-down-1/data-table/column-filters";


const SalesByMonthByProduct = ({ title, tableData, isLoading, tooltip, isWidthFull, opened }) => {
  const { organisation } = useSelector((state) => state.organisation);

  const countryCode = organisation?.country_alpha3?.replace(/"/g, "");
  const currencySymbol = countryToSymbolMap[countryCode] || "";
  const [isAdvancedDearFilters, setIsAdvancedDearFilters] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(opened);

  // Get the current month index
  const currentMonthIndex = new Date().getMonth(); // 0 for January, 11 for December
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Create an array of months starting from the current month and moving backwards
  const rearrangedMonthNames = [];
  for (let i = 0; i < 12; i++) {
    const monthIndex = (currentMonthIndex - i + 12) % 12; // Wrap around using modulo
    rearrangedMonthNames.push(monthNames[monthIndex]);
  }

  // State to manage column visibility
  const [columns, setColumns] = useState([
    { key: "sku_code", label: "SKU Code", visible: true },
    { key: "product_name", label: "Product Name", visible: true },
    ...rearrangedMonthNames.flatMap((month, index) => [
      { key: `month_${index + 1}`, label: month, visible: true },
      { key: `%_change_${index + 1}`, label: `% Change`, visible: false },
    ]),
  ]);

  
  const [sortKey, setSortKey] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortOrders, setSortOrders] = useState({});

  const handleSort = (key, order) => {
    setSortOrders((prev) => ({ ...prev, [key]: order }));
    setSortKey(key);
    setSortOrder(order);
  };

  // Memoized sorted data
  const sortedData = React.useMemo(() => {
    if (!sortKey || !sortOrder) return tableData;

    const sorted = [...tableData].sort((a, b) => {
      if (typeof a[sortKey] === "number") {
        return sortOrder === "asc" ? a[sortKey] - b[sortKey] : b[sortKey] - a[sortKey];
      } else if (typeof a[sortKey] === "string") {
        return sortOrder === "asc" ? a[sortKey].localeCompare(b[sortKey]) : b[sortKey].localeCompare(a[sortKey]);
      }
      return 0;
    });
    
    return sorted;
  }, [tableData, sortKey, sortOrder]);


  const handleAdvanceDearFilters = () => {
    setIsAdvancedDearFilters(!isAdvancedDearFilters);
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Toggle column visibility
  const toggleColumnVisibility = (key) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.key === key ? { ...col, visible: !col.visible } : col
      )
    );
  };

  return (
    <>
      <div
        className={`relative ${isWidthFull ? "w-full" : "w-1/2"} border-[0.5px] rounded-lg p-4 bg-white shadow-sm border-zinc-200 mt-5`}
      >
        <div className="flex flex-row justify-between items-center mb-4 gap-2">
          {!isExpanded ?
            <div className="font-bold flex items-center justify-center gap-1">
              <div className="rounded-full flex items-center justify-center h-9 w-9 bg-slate-200">
                <Tippy theme="custom-white" size="small" placement="top" content="Dear">
                  <img className="rounded-full object-contain h-[26px] w-[26px]" src={dear} />
                </Tippy>
              </div>
              {title}  {`(${currencySymbol})`}
              <Tippy theme="custom-white" size="small" placement="top" content={tooltip}>
                <div>
                  <IoIosInformationCircle className="text-grey" size={14} />
                </div>
              </Tippy>
            </div> : null}

          <div className={`flex gap-3 items-center ${isExpanded ? 'w-full justify-end' : ''}`}>
            {!isExpanded ? <button onClick={handleModalToggle} className="text-sm text-gray-500">
              <Expand size={20} />
            </button> : null}
            <ColumnFilterMenu columns={columns} setColumns={setColumns} />

            <div className="cursor-pointer" onClick={handleAdvanceDearFilters}>
              <BsFillFilterSquareFill className="text-purple" size={26} />
            </div>
          </div>
        </div>

        {isAdvancedDearFilters && (
          <div className="flex flex-row mb-4 gap-2">
            <AdvancedDearFilters
              title={title}
              showBrandFilter={true}
              showProductFilter={true}
              showProductTagsFilter={true}
              showCustomerFilter={true}
              showCustomerTagsFilter={true}
              showCategoryFilter={true}
              showTypeFilter={true}
              handleAdvanceDearFilters={handleAdvanceDearFilters}
              isAdvancedDearFilters={true}
            />
          </div>
        )}

        {/* Horizontal and Vertical Scroll for Overflowing Table */}
        <div className={`overflow-auto ${isExpanded ? "max-h-[650px]" : "max-h-[500px]"} rounded-md border border-gray-300`}>
          {isLoading ? (
            <SpinnerKPI />
          ) : (
            <table className="border-separate border-spacing-0 table-auto">
              <thead className="bg-gray-200 sticky top-0 z-20">
                <tr>
                  {columns.map((col, columnIndex) =>
                    col.visible && (
                      <th
                        key={col.key}
                        className={`border border-gray-300 text-sm px-2 py-2 whitespace-nowrap ${columnIndex === columns.findIndex((c) => c.visible) ? "sticky left-0 bg-gray-200 z-20" : ""
                          }`}
                      >
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <span className="cursor-pointer flex items-center justify-center gap-1">
                              {col.label}
                              <ArrowUpDown className="text-purple flex-shrink-0" size={14} />
                            </span>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            <DropdownMenuCheckboxItem
                              checked={sortOrders[col.key] === "asc"}
                              onCheckedChange={() => handleSort(col.key, "asc")}
                            >
                              Ascending
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuCheckboxItem
                              checked={sortOrders[col.key] === "desc"}
                              onCheckedChange={() => handleSort(col.key, "desc")}
                            >
                              Descending
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuCheckboxItem
                              checked={col.visible}
                              onCheckedChange={() => toggleColumnVisibility(col.key)}
                            >
                              {col.visible ? "Show" : "Hide"} Column
                            </DropdownMenuCheckboxItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {sortedData?.map((row, rowIndex) => (
                  <tr key={rowIndex} className="hover:bg-gray-100">
                    {columns.map((col, columnIndex) =>
                      col.visible && (
                        <td
                          key={col.key}
                          className={`border border-gray-300 text-sm px-2 py-2 whitespace-nowrap ${columnIndex === columns.findIndex((c) => c.visible) ? "sticky left-0 z-10 bg-white hover:bg-gray-100" : ""
                            }`}
                        >
                          {row[col.key] !== undefined
                            ? col.key.includes("month_") || col.key.includes("%_change_")
                              ? (
                                (col.key.includes("month_") ? currencySymbol + " " : "") +
                                (row[col.key] * (col.key.includes("%_change_") ? 100 : 1))
                                  .toLocaleString(undefined, {
                                    minimumFractionDigits: col.key.includes("month_") ? 2 : 0,
                                    maximumFractionDigits: col.key.includes("month_") ? 2 : 2
                                  }) +
                                (col.key.includes("%_change_") ? "%" : "")
                              )
                              : row[col.key].toLocaleString()
                            : "-"}
                        </td>
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {/* KPIDrillDownModal to show expanded view */}
      <ProductBySalesByMonthModal
        toggle={isModalOpen}
        toggleModal={handleModalToggle}
        tableData={tableData}
        drilldownTitle={title}
        drilldownTooltip={tooltip}
        drilldownType="Product"
        currencySymbol={currencySymbol}
      />
    </>
  );
};

export default SalesByMonthByProduct;
