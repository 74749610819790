import React, { useState } from "react";
import { BiSolidBusiness } from "react-icons/bi";
import { ImUser, ImUsers } from "react-icons/im";
import SubscriptionPaymentModal from "../modals/subscription-payment";
import {
  BsArrowDownCircleFill,
  BsFillArrowUpCircleFill,
  BsFillQuestionCircleFill,
} from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import SpinnerKPI from "../utilis/spinner-kpi";
import { addKPIFavorite } from "../../../../redux-features/slices/kpi/kpiSlice";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
import SimpleSparkline from "../../graphs/recharts/spark-line";
import xero from "../../../../assets/logos/xero_logo.png";
import { BiCommentDetail } from "react-icons/bi";
import { HiOutlineDotsVertical } from "react-icons/hi";

import Tippy from "@tippyjs/react";
const BoxCard03 = (props) => {
  const { isLoadingKPI } = useSelector((state) => state.kpi);
  const { title, data, value, toggleKPI, symbol } = props;
  const dispatch = useDispatch();
  const [togglePaySubscription, setPaySubscriptionToggle] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { organisation } = useSelector((state) => state.organisation);

  const token = user?.data?.api_token;
  const handleFavorite = () => {
    // dispatch(addKPIFavorite({ org_id, kpiFavData, token }));
  };
  return (
    <div className="flex-shrink-0 w-full min-w-[170px]  lg:min-w-250px]">
      <div className="flex flex-col min-h-[150px] ">
        <div className="flex-grow h-full w-full border-[0.5px] rounded-lg  bg-white shadow-sm border-zinc-200">
        <div className="px-5 py-3 w-full    flex flex-col items-start gap-2 justify-start">
          <div className="flex w-full flex-row justify-between">
            <p className="flex flex-row gap-2 justify-center items-center text-xs text-grey font-semibold">
              {" "}
              {title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()}
              <Tippy
                theme="custom-white"
                size="small"
                placement="top"
                content={data?.Description}
              >
                <div>
                  <BsFillQuestionCircleFill
                    className="  text-default"
                    size={12}
                  />
                </div>
              </Tippy>
            </p>
          </div>
          <p
            className="text-2xl text-zinc-700 font-bold hover:underline"
            onClick={toggleKPI}
          >
            {isLoadingKPI ? (
              <SpinnerKPI />
            ) : data ? (
              <>
                {" "}
                {symbol}
                {typeof value === "number" ? (
                  value.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })
                ) : (
                  <p className="text-[10px] font-light text-grey italic">
                    No data found, please refresh or try later
                  </p>
                )}
              </>
            ) : (
              <p className="text-[12px] font-light text-grey italic">
                No data found, please refresh or try later
              </p>
            )}
          </p>

          <div className="flex flex-row mt-3 gap-2 justify-between w-full items-center">
            <div className="flex gap-2 items-center">
              <div className="rounded-full  h-9 w-9 bg-slate-200">
                <Tippy
                  theme="custom-white"
                  size="small"
                  placement="top"
                  content="Xero"
                >
                  <img
                    className="rounded-full object-contain h-full w-full"
                    src={xero}
                  />
                </Tippy>
              </div>
              {/* <span className="font-light text-sm text-grey">+1</span> */}
            </div>

            <div className="flex flex-row gap-2">
              <AiOutlineHeart
                size={18}
                className="text-zinc-400 cursor-pointer hover:text-purple"
                onClick={handleFavorite}
              />
              <BiCommentDetail
                size={18}
                className="text-zinc-400 cursor-pointer hover:text-purple "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default BoxCard03;
