import React, { useState, useEffect, useRef } from 'react';
import { CornerDownLeft, Mic, UserRound } from 'lucide-react';
import { Badge } from "../../../../shadcn-components/ui/badge";
import { Label } from "../../../../shadcn-components/ui/label";
import { TooltipProvider, Tooltip, TooltipContent, TooltipTrigger } from "../../../../shadcn-components/ui/tooltip";
import { Button } from "../../../../shadcn-components/ui/button";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import qurk from "../../../../assets/logos/qurk_logo_circle.png";
import { motion } from "framer-motion";

const DotLoader = () => {
  return (
    <div className="flex gap-2 items-center">
      {[...Array(3)].map((_, i) => (
        <motion.span
          key={i}
          className="w-2 h-2 rounded-full bg-gray-600"
          animate={{ opacity: [0.3, 1, 0.3] }}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: "loop",
            delay: i * 0.5, // Stagger the animation of each dot
          }}
        />
      ))}
    </div>
  );
};

const KPIChatComponent = (props) => {
  const { roomName } = props;
  const { user } = useSelector((state) => state.auth);
  const { organisation } = useSelector((state) => state.organisation);
  const token = user?.data?.api_token;
  const slug = user?.data?.slug;
  const userEmail = user?.data?.email;

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [taggedUsers, setTaggedUsers] = useState([]);

  const staffUsers = [
    ...organisation?.staffs?.map(staff => ({
      name: staff.name,
      email: staff.email,
      slug: staff.slug || null
    })),
    { name: 'Robin AI', email: 'robinai', slug: 'robinai' }
  ];
  
  const ws = useRef(null);

  // useEffect(() => {
  //   ws.current = new WebSocket(`wss://qurk.uk/ws/threads/chat/${roomName}/nitish-t4ns/`);
  
  //   ws.current.onopen = () => {
  //     console.log('WebSocket connection opened.');
  //   };
  
  //   ws.current.onmessage = (event) => {
  //     const data = JSON.parse(event.data);
  
  //     if (data?.type === "connection_established") {
  //       return;
  //     }
  
  //     if (data?.notification) {
  //       console.log('Notification:', data.notification);
  //       toast.info(data.notification.content, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       return;
  //     }
  
  //     if (data?.message) {
  //       const text = data?.email === "robinai" 
  //         ? data?.message?.summary?.summary 
  //         : data?.message;
      
  //       setLoading(false);
      
  //       if (data?.email !== 'nitish@kounteq.com') {
  //         setMessages((prevMessages) => [
  //           ...prevMessages,
  //           { text, sender: data.email }
  //         ]);
  //       }
  //     }
      
  //   };
  
  //   ws.current.onclose = () => {
  //     console.log('WebSocket connection closed.');
  //   };
  
  //   ws.current.onerror = (error) => {
  //     console.error('WebSocket error:', error);
  //   };
  
  //   return () => {
  //     if (ws.current) {
  //       ws.current.close();
  //     }
  //   };
  // }, [roomName]);
  
  useEffect(() => {
    ws.current = new WebSocket(`wss://staging-dot-ami-postgres-staging.nw.r.appspot.com/ws/threads/chat/${roomName}/${slug}/`);
  
    ws.current.onopen = () => {
      console.log('WebSocket connection opened.');
    };
  
    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
  
      if (data?.type === "connection_established") {
        return;
      }
  
      if (data?.notification) {
        console.log('Notification:', data.notification);
        toast.info(data.notification.content, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
  
      if (data?.message) {
        const text = data?.email === "robinai" 
          ? data?.message?.summary?.summary 
          : data?.message;
        
        setLoading(false);
        
        if (data?.email === 'robinai') {
          // Move robinai message to the end
          setMessages((prevMessages) => {
            const nonRobinMessages = prevMessages.filter(msg => msg.sender !== 'robinai');
            return [
              ...nonRobinMessages,
              { text, sender: 'robinai' }
            ];
          });
        } else if (data?.email !== userEmail) {
          // Regular message handling
          setMessages((prevMessages) => [
            ...prevMessages,
            { text, sender: data.email }
          ]);
        }
      }
    };
  
    ws.current.onclose = () => {
      console.log('WebSocket connection closed.');
    };
  
    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [roomName]);
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const taggedUserSlugs = [slug, ...taggedUsers.map(user => user.slug)];
    if (message.trim()) {
      setLoading(true);  // Set loading to true when the message is sent
      const messageData = {
        message: message.trim(),
        senderEmail: userEmail,
        taggedUserSlug: taggedUserSlugs,
        dataset_id: String(organisation?.dataset_ids?.dear),
        orgSlug: organisation?.slug
      };
      console.log('====================================');
      console.log(messageData);
      console.log('====================================');
      ws.current.send(JSON.stringify(messageData)); // Send the message through WebSocket
      setMessages([...messages, { text: message, sender: userEmail, taggedUsers }]);
      setMessage('');
      setSuggestions([]);
      setTaggedUsers([]);  // Clear tagged users after submission
      setShowSuggestions(false);
    } else {
      console.warn("Message input is empty.");
    }
  };
  
  
  const handleChange = (e) => {
    const value = e.target.value;
    setMessage(value);

    const lastWord = value.split(' ').pop();
    if (lastWord.startsWith('@')) {
      const query = lastWord.slice(1);
      const filteredUsers = staffUsers.filter(user => user.name.toLowerCase().includes(query.toLowerCase()));
      setSuggestions(filteredUsers);
      setShowSuggestions(filteredUsers.length > 0);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleSelectUser = (user) => {
    const lastWordIndex = message.lastIndexOf('@');
    const newMessage = message.slice(0, lastWordIndex);
    setMessage(newMessage);
    setTaggedUsers([...taggedUsers, user]);
    setShowSuggestions(false);
  };

  const renderStyledMessage = (msg, taggedUsers) => {
    return (
      <span className="text-black">
        {taggedUsers.length > 0 && (
          <span className="text-purple font-semibold">
            {`@${taggedUsers.map(user => user.name).join(', ')}`}
          </span>
        )}
        {' '}
        {msg}
      </span>
    );
  };
  
  return (
    <div className="relative flex h-full w-full min-h-[75vh] flex-col rounded-xl bg-gray-50 p-4 lg:col-span-2">
  <Badge variant="outline" className="absolute bg-purple text-white right-3 top-3">Online</Badge>
  
  {/* Scrollable message container */}
  <div className="flex-1 mt-8 overflow-y-auto p-2 scrollbar-thin max-h-[55vh]">
  <div className="flex flex-col space-y-2 ">
  {messages
    .filter((msg) => msg.sender !== 'robinai') // Filter out robinai messages
    .map((msg, index) => (
      <div key={index} className={`flex gap-2 items-start ${msg.sender === userEmail ? 'justify-end' : 'justify-start'}`}>
        {msg.sender !== userEmail && msg.sender !== "robinai" && 
          <div className="border border-gray-700 p-1 rounded-full">
            <UserRound size={20} />
          </div>
        }

        {/* robinai message */}
        {msg.sender === "robinai" && msg.sender !== userEmail && (
          <img
            src={qurk}
            className="w-9 h-9  image-cover rounded-full"
            alt="robinai"
          />
        )}

        <div className='flex flex-col gap-1 my-1'>
          <div className={`px-4 py-2 text-sm rounded-full ${msg.sender === userEmail ? 'bg-warning/50 text-right' : 'bg-gray-200 text-left'}`}>
            {renderStyledMessage(msg.text, msg.taggedUsers || [])}
          </div>
          <p className='self-end text-gray-500 text-[11px] mr-3'>
            {msg.sender === userEmail ? 'Delivered' : '6:40 PM'}
          </p>
        </div>

        {/* Right-side icon for current user */}
        {msg.sender === userEmail && (
          <div className="border border-gray-700 p-1 rounded-full">
            <UserRound size={20} />
          </div>
        )}
      </div>
    ))}

  {/* Then, render all robinai messages */}
  {messages
    .filter((msg) => msg.sender === 'robinai') // Only render robinai messages
    .map((msg, index) => (
      <div key={index} className="flex gap-2 items-start justify-start">
        <img
          src={qurk}
          className="w-9 h-9  image-cover rounded-full"
          alt="robinai"
        />
        <div className='flex flex-col gap-1 my-1'>
          <div className='px-4 py-2 text-sm rounded-full bg-gray-200 text-left'>
            {renderStyledMessage(msg.text, msg.taggedUsers || [])}
          </div>
          <p className='self-end text-gray-500 text-[11px] mr-3'>
            6:40 PM
          </p>
        </div>
      </div>
    ))}
</div>


    {loading && ( 
      <div className="flex justify-center my-4">
        <DotLoader color="#6c63ff" size={40} />
      </div>
    )}
  </div>

  <TooltipProvider>
    <form className="relative overflow-hidden rounded-lg border bg-background focus-within:ring-1 focus-within:ring-ring" onSubmit={handleSubmit}>
      <Label htmlFor="message" className="sr-only">Message</Label>
      <div className="relative w-full">
        <div className="">
          {taggedUsers.length > 0 && (
            <div className="flex mt-3 ml-2 flex-wrap gap-2">
              {taggedUsers.map((user, index) => (
                <span key={index} className="bg-light-purple text-purple text-sm font-medium px-2.5 py-0.5 rounded-md">
                  {user.name} <span className="cursor-pointer" onClick={() => setTaggedUsers(taggedUsers.filter((_, i) => i !== index))}>x</span>
                </span>
              ))}
            </div>
          )}
        </div>
        <textarea
          id="message"
          placeholder="Type your message here..."
          className="min-h-12 z-10 text-sm relative w-full resize-none border-0 bg-transparent p-3 shadow-none focus-visible:ring-0"
          value={message}
          onChange={handleChange}
        />
      </div>

      {showSuggestions && (
        <ul className="absolute left-2 top-10 z-10 bg-white border text-sm rounded-md shadow-md mt-1 w-1/2 scrollbar-none max-h-40 overflow-y-auto">
          {suggestions.map((user, index) => (
            <li key={index} onClick={() => handleSelectUser(user)} className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
              {user.name}
            </li>
          ))}
        </ul>
      )}

      <div className="flex items-center p-3 pt-0">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" size="icon">
              <Mic className="size-4" />
              <span className="sr-only">Use Microphone</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent side="top">Use Microphone</TooltipContent>
        </Tooltip>

        <Button type="submit" size="sm" className="ml-auto gap-1.5">
          Send Message
          <CornerDownLeft className="size-3.5" />
        </Button>
      </div>
    </form>
  </TooltipProvider>
</div>

  );
};

export default KPIChatComponent;
