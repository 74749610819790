import React from "react";
import { Button } from "../../../../../../../../shadcn-components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  DropdownMenuCheckboxItem
} from "../../../../../../../../shadcn-components/ui/dropdown-menu";
import { Eye } from "lucide-react";

export function ColumnFilterMenu({ columns, setColumns }) {
  const toggleColumnVisibility = (columnKey) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.key === columnKey ? { ...col, visible: !col.visible } : col
      )
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          size="sm"
          className="text-gray-400 font-regular gap-2 border border-gray-300 px-3 h-8"
          variant="outline"
        >
          <Eye size={20} strokeWidth={1.3} />
          View
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Show/Hide Columns</DropdownMenuLabel>
        <DropdownMenuGroup>
          {columns.map((col) => (
            <DropdownMenuCheckboxItem
              key={col.key}
              checked={col.visible}
              onCheckedChange={() => toggleColumnVisibility(col.key)}
              className="flex items-center"
            >
              {col.label}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
