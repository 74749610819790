import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { BsCalendar2Range, BsGraphUpArrow } from "react-icons/bs";
import { GrRefresh } from "react-icons/gr";
import { MdKeyboardArrowDown } from "react-icons/md";
import moment from "moment";
import { getOnDemandDearData } from "../../../../redux-features/slices/kpi/kpiSlice";
import { selectOrganisation } from "../../../../redux-features/slices/organisation/orgSlice";
import SpinnerKPI from "../utilis/spinner-kpi";
import { IoMdRefresh } from "react-icons/io";

// Import your Admin component here if not already imported
const labels = ["January", "February", "March", "April", "May", "June", "July"];
const companies = [
  // ... (your company data)
];

const currentDate = moment();
const formattedDate = currentDate.format("YYYY-MM-DD");

function DashboardBanner(props) {
  const { title, isGlobalFilterRequired, isDearBrandFilters, noRefreshButton } =
    props;
  const [isDropdownOpen, setIsDropdownOpen] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(companies[0] || {});
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { dearBrandFilters } = useSelector((state) => state.kpi);
  const { organisation, organisations } = useSelector(
    (state) => state.organisation
  );
  const token = user?.data?.api_token;
  const slug = organisation?.slug;
  const schedule_id = organisation?.metadata?.id;
  const {
    dearLastUpdated,
    xeroLastUpdated,
    isLoadingKPI,
    isLoeadingOnDemandExtraction,
  } = useSelector((state) => state.kpi);

  const handleOptionClick = (name, slug) => {
    const selectedCompany = companies.find((comp) => comp.name === name);
    setSelectedOption(selectedCompany);
    setIsOpen(false);
    dispatch(selectOrganisation({ slug, token }));
  };

  const [activeComponent, setActiveComponent] = useState("Home");

  const handleClick = (component) => {
    setActiveComponent(component);
  };

  const toggleDropdown = (sectionName) => {
    setIsDropdownOpen((prevState) => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }));
  };
  // State variables for month and year filters
  const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);
  const [selectedMonthFilter, setSelectedMonthFilter] = useState("This Month");

  const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);
  const [selectedYearFilter, setSelectedYearFilter] = useState("This Year");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [isBrandDropdownOpen, setIsBrandDropdownOpen] = useState(false);

  const handleBrandFilterSelect = (brand) => {
    setSelectedBrand(brand);
    setIsBrandDropdownOpen(false);
  };
  // Function to handle month filter selection
  const handleMonthFilterSelect = (filter) => {
    setSelectedMonthFilter(filter);
    setIsMonthDropdownOpen(false);
  };

  const handleYearFilterSelect = (filter) => {
    setSelectedYearFilter(filter);
    setIsYearDropdownOpen(false);
  };
  const today = moment();
  const todayFormatted = today.format("YYYY-MM-DD HH:mm:ss");

  // Dear data
  const dearLastUpdatedDate =
    dearLastUpdated?.results?.[0]?.data?.[0]?.last_updated;

  const dearLastUpdatedFormat =
    dearLastUpdatedDate && moment(dearLastUpdatedDate, "YYYY-MM-DD HH:mm:ss");
  // const dearLastUpdatedFormat = moment(dearLastUpdatedDate.split(" ")[0], "YYYY-MM-DD");
  const dearDifference = dearLastUpdatedFormat
    ? today.diff(dearLastUpdatedFormat)
    : null;
  const dearDifferenceDays = dearDifference
    ? Math.floor(dearDifference / (1000 * 60 * 60 * 24))
    : null; // Difference in days
  const dearDifferenceHours = dearDifference
    ? Math.floor((dearDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    : null; // Difference in hours
  const dearDifferenceMinutes = dearDifference
    ? Math.floor((dearDifference % (1000 * 60 * 60)) / (1000 * 60))
    : null; // Difference in minutes
  // Xero data
  const xeroLastUpdatedDate = xeroLastUpdated?.[0]?.data?.[0]?.last_updated;
  const xeroLastUpdatedFormat =
    xeroLastUpdatedDate && moment(xeroLastUpdatedDate, "YYYY-MM-DD HH:mm:ss");
  // const xeroLastUpdatedFormat = xeroLastUpdatedDate && moment(xeroLastUpdatedDate.split(" ")[0], "YYYY-MM-DD");
  const xeroDifference = xeroLastUpdatedFormat
    ? today.diff(xeroLastUpdatedFormat)
    : null;
  const xeroDifferenceDays = xeroDifference
    ? Math.floor(xeroDifference / (1000 * 60 * 60 * 24))
    : null; // Difference in days
  const xeroDifferenceHours = xeroDifference
    ? Math.floor((xeroDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    : null; // Difference in hours
  const xeroDifferenceMinutes = xeroDifference
    ? Math.floor((xeroDifference % (1000 * 60 * 60)) / (1000 * 60))
    : null; // Difference in minutes

  const handleDearRefresh = () => {
    const refresh_payload = {
      job_name:
        "projects/qurk-pipeline/locations/europe-west2/jobs/extract-load",
      env_vars: {
        schedule_id: schedule_id,
        TEST_GCP_PROJECT: "qurk-pipeline",
      },
    };
    dispatch(getOnDemandDearData({ slug, refresh_payload, token }));
  };

  return (
    <div className="w-full mb-3 ">
      <div className="flex w-full justify-between ">
        <h2 className="text-xl font-bold">{title}</h2>
      {noRefreshButton ? (
        <></>
      ):(
        <div className="flex flex-col gap-2 text-xs">
        {isLoeadingOnDemandExtraction ? (
          <div className="flex items-center justify-center">
            <SpinnerKPI />
          </div>
        ) : (
          <button
            className="flex items-center justify-center bg-warning rounded-md py-2.5 hover:underline gap-1 my-2 text-[14px] font-semibold text-dark-purple"
            onClick={handleDearRefresh}
          >
            <IoMdRefresh size={16} />
            Refresh
          </button>
        )}
        {organisation?.connected_apps?.includes("DEAR") && (
          <h2>
            Cin7 Core Last Updated:
            {isLoadingKPI ? (
              <SpinnerKPI />
            ) : (
              <span className="font-light ml-2">
                {dearDifferenceDays !== null && `${dearDifferenceDays} days `}
                {dearDifferenceHours !== null &&
                  `${dearDifferenceHours} hours `}
                {dearDifferenceMinutes !== null &&
                  `${dearDifferenceMinutes} minutes`}{" "}
                ago
              </span>
            )}
          </h2>
        )}
        {organisation?.connected_apps?.includes("XERO") && (
          <h2>
            Xero Last Updated:
            {isLoadingKPI ? (
              <SpinnerKPI />
            ) : (
              <span className="font-light ml-2">
                {xeroDifferenceDays !== null && `${xeroDifferenceDays} days `}
                {xeroDifferenceHours !== null &&
                  `${xeroDifferenceHours} hours `}
                {xeroDifferenceMinutes !== null &&
                  `${xeroDifferenceMinutes} minutes`}{" "}
                ago
              </span>
            )}
          </h2>
        )}
      </div>
      )}
        {/* <div className="relative self-end z-15 inline-block text-left">
          <div>
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="px-4 py-2 bg-white flex items-center justify-between text-sm w-56 font-medium  rounded-md border-2 border-purple focus:outline-none focus:ring focus:ring-opacity-50"
              id="options-menu"
              aria-haspopup="true"
              aria-expanded={isOpen}
            >
              {organisation.name ? organisation.name : "Select Organisation"}
              {isOpen ? (
                <BiSolidUpArrow className="text-purple" />
              ) : (
                <BiSolidDownArrow className="text-purple" />
              )}
            </button>
          </div>
          {isOpen && (
            <div className="origin-top-right z-20 absolute right-0 mt-2 w-64 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div
                className="py-1 px-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                {organisations?.map((comp, i) => (
                  <button
                    key={i}
                    onClick={() => handleOptionClick(comp.name, comp.slug)}
                    className={`block px-4 py-3 text-sm font-medium ${
                      selectedOption === comp.name
                        ? "bg-gray-100"
                        : "hover:bg-gray-100"
                    } w-full text-left`}
                    role="menuitem"
                  >
                    {comp.name}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div> */}
      </div>
      {isDearBrandFilters ? (
        <div className="p-2 mt-3 w-36 flex justify-between rounded-md border  border-gray-300 bg-white px-3 py-1  font-medium  hover:bg-gray-50 focus:outline-none">
          <div className="relative">
            <div
              onClick={() => setIsBrandDropdownOpen(!isBrandDropdownOpen)}
              className="flex items-center gap-1 justify-end text-xs text-grey cursor-pointer "
            >
              <p className="text-sm text-zinc-600">
                {selectedBrand || "Select Brand"}
              </p>
              <BiSolidDownArrow className="ml-1 text-zinc-600" size={12} />
            </div>
            {isBrandDropdownOpen && (
              <div className="absolute left-0 flex flex-col cursor-pointer gap-2 z-10 text-grey text-xs p-3 top-full w-40 mt-1 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                {dearBrandFilters.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handleBrandFilterSelect(item.brand)}
                  >
                    {item.brand || "Unknown Brand"}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {isGlobalFilterRequired ? (
        <div className="p-2 mt-3 w-full flex justify-between items-center ">
          <div className="flex gap-6">
            <div className="relative">
              <div
                onClick={() => setIsMonthDropdownOpen(!isMonthDropdownOpen)}
                className="flex items-center gap-1 justify-end text-sm cursor-pointer"
              >
                <BsCalendar2Range className="text-zinc-600" />
                <p className="text-sm text-zinc-600">{selectedMonthFilter}</p>
                <BiSolidDownArrow className="ml-1 text-zinc-600" size={12} />
              </div>
              {isMonthDropdownOpen && (
                <div className="absolute left-14 flex flex-col cursor-pointer gap-2 z-10 text-grey text-xs p-3 top-6 w-40 mt-1 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  {["This Month", "Last Month", "Previous Month"].map(
                    (month, i) => (
                      <div
                        key={i}
                        onClick={() => handleMonthFilterSelect(month)}
                      >
                        {month}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>

            <div className="relative">
              <div
                onClick={() => setIsYearDropdownOpen(!isYearDropdownOpen)}
                className="flex items-center gap-1 justify-end text-sm cursor-pointer"
              >
                <BsGraphUpArrow className="text-zinc-600" />
                <p className="text-sm text-zinc-600">{selectedYearFilter}</p>
                <BiSolidDownArrow className="ml-1 text-zinc-600" size={12} />
              </div>
              {isYearDropdownOpen && (
                <div className="absolute left-14 flex flex-col z-10 gap-2 p-3 top-6 text-grey text-xs w-40 mt-1 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  {["This Year", "Last Year"].map((year, i) => (
                    <div key={i} onClick={() => handleYearFilterSelect(year)}>
                      {year}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default DashboardBanner;
