import React, { useEffect } from "react";
import { IoIosClose } from "react-icons/io";
import { Link } from "react-router-dom";
import { AiFillFile } from "react-icons/ai";

function KPISheetModal(props) {
  const { toggle, toggleModal, isEdit, children, kpiName } = props;

  const onSubmit = () => {};
  const handleClose = () => {
    toggleModal();
  };

  useEffect(() => {
    if (toggle) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.overflow = "";
    };
  }, [toggle]);

  return (
    <div className="z-100">
      {toggle && (
        <div className="fixed top-0 right-0 h-full w-full bg-black bg-opacity-50" style={{ zIndex: 9999 }}>
          <div className="bg-white h-full p-8 w-2/5 absolute right-0 overflow-y-auto">
            {/* Add a close button or icon at the top-right corner */}
            <div className="flex justify-end">
              <button onClick={handleClose}>
                <IoIosClose size={30} />
              </button>
            </div>
            <div className="modal-body">{children}</div>
            {/* <div className="flex flex-col inset-0 bg-opacity-30 blur-sm bg-gray-100  backdrop-filter backdrop-blur-xm gap-2 mt-5 w-full h-24">
              <p className="font-bold text-md">Comments</p>
              <div className="flex items-center justify-between gap-2">
                <input
                  type="text"
                  className="w-11/12 bg-light-white border-0 p-2 font-light text-[12px] text-grey"
                  placeholder="Add Comments"
                />

                <div className="w-1/12 h-full flex items-center justify-center border-[0.3px] border-light-white cursor-pointer">
                  <AiFillFile />
                </div>
              </div>
            </div> */}
            
           <div className="mt-10">
           <Link
              to={{
                pathname: `/details/${kpiName}`,
              }}
              className="text-white bg-[#2a115b] py-3 px-8 rounded-sm text-sm font-semibold"
            >
              Explore More
            </Link>
           </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default KPISheetModal;
