import React, { useEffect, useState } from "react";

import { HiOutlineOfficeBuilding, HiClipboardList } from "react-icons/hi";
import { VscGraph } from "react-icons/vsc";
import {
  AiOutlineAppstoreAdd,
} from "react-icons/ai";
import qurk from "../assets/logos/qurk_logo.png";
import { BiArrowToLeft, BiArrowToRight, BiPurchaseTagAlt, BiSolidPurchaseTagAlt } from "react-icons/bi";
import { TbTargetArrow } from "react-icons/tb";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { VscGraphLine } from "react-icons/vsc";
import { TbCoins } from "react-icons/tb";
import { PiHandCoins } from "react-icons/pi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { MdOutlineInventory } from "react-icons/md";
import "./sidebar.css";
import { useSelector } from "react-redux";
import SpinnerKPI from "../views/components/ui-elements/utilis/spinner-kpi";
import { BsPeople } from "react-icons/bs";
import { BsShieldCheck } from "react-icons/bs";
const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState("");
  const location = useLocation();
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClick = (menuName) => {
    setOpenSubMenu((prevMenu) => (prevMenu === menuName ? "" : menuName));
    if (collapsed) {
      setCollapsed(false); // Expand the sidebar when a main menu item is clicked
    }
  };
  const { user,} = useSelector(
    (state) => state.auth
  );
  const last_viewed = user?.data?.custom_fields?.last_viewed;
  const token = user?.data?.api_token;
  const { organisation, isLoading } = useSelector((state) => state.organisation);
  const menu1 = [
    {
      name: ( last_viewed?.organisation ? `${last_viewed?.organisation} Overview` : organisation?.name ? `${organisation?.name} Overview`: 'Home'),
      link: "/dashboard/home",
      icon: IoHomeOutline,
    },
   
    {
      name: "AI Assistant (Beta)",
      link: "/dashboard/ai-beta/",  
      icon: PiHandCoins,
    },
    ...(organisation?.connected_apps && organisation?.connected_apps.length > 0 ? [
      {
        name: "Revenue",
        link: "/dashboard/revenue-and-costs/",  
        icon: PiHandCoins,
      },
      {
        name: "Cash Flow",
        link: "/dashboard/cash/",
        icon: TbCoins,
      },
      {
        name: "Profit & Loss",
        link: "/dashboard/profit-and-loss/",
        icon: VscGraphLine,
      },
      {
        name: "Balance Sheet",
        link: "/dashboard/balance-sheet/",
        icon: LiaFileInvoiceDollarSolid,
      },
      {
        name: "Inventory",
        link: "/dashboard/inventory/",
        icon: MdOutlineInventory,
      },
      {
        name: "Purchasing",
        link: "/dashboard/purchasing/",
        icon: BiPurchaseTagAlt,
      },
      {
        name: "Validation",
        link: "/dashboard/validation/",
        icon: BsShieldCheck,
      }
    ] : []),
    // {
    //   name: "Workflow (Beta)",
    //   link: "/dashboard/workflow-beta/",  
    //   icon: PiHandCoins,
    // },
  ];
  
  const menu2 = [
    // {
    //   name: "Organisations",
    //   link: "/owner/organisation",
    //   icon: HiOutlineOfficeBuilding,
    //   sub_menus: [
    //     {
    //       name: "Organisations",
    //       link: "/owner/organisation/single",
    //     },
    //     {
    //       name: "Consolidated Organisation",
    //       link: "/owner/organisation/consolidation",
    //     },
    //   ],
    // },
    {
      name: "Organisations",
      link: "/owner/organisation/single",
      icon: HiOutlineOfficeBuilding,
    },
    {
      name: "Connected Apps",
      link: "/connected-apps/apps/",
      icon: AiOutlineAppstoreAdd,
    },
    {
      name: "Settings",
      link: "/owner/settings/",
      icon: FiSettings,
    },
  ];
 const [mainMenuOpen, setMainMenuOpen] = useState(null);
 // Function to toggle the open/closed state of the main menu
 const toggleMainMenu = (menuName) => {
   if (mainMenuOpen === menuName) {
     setMainMenuOpen(null); 
   } else {
     setMainMenuOpen(menuName); 
   }
 };
 
  return (
    <div
      className={`bg-dark-purple text-white ${
        collapsed ? "w-16" : "w-64"
      } h-full    flex flex-col justify-between items-start gap-5 transition-all duration-500 ease-in-out overflow-y-auto`}
    >
      <div className="flex gap-5 flex-col w-full  ">
        <div
          className={` flex gap-4 mt-10 px-4`}
          style={{
            transitionDelay: `500ms`,
          }}
        >
          <img
            className={`${collapsed ? `h-10 w-10` : `h-8 w-8 `} `}
            src={qurk}
            alt="Qurk Logo"
          />
          <span
            className={`font-bold ${
              collapsed ? `hidden ` : ``
            } font-bold text-2xl`}
          >
            Qurk
          </span>
        </div>
        <div className="p-2  flex flex-row">
          <ul className="w-full text-sm">
            {menu1.map((menu, i) => (
              <li className="mb-2" key={i}>
                <NavLink
                  to={`${menu?.sub_menus ? "#" : menu.link}`}
                  className={`flex items-center justify-between p-2 space-x-2 ${
                    collapsed ? "justify-center" : "justify-center"
                  } ${
                    location.pathname.includes(menu.link) ? "bg-purple" : ""
                  }`}
                  onClick={() => {
                    handleMenuClick(menu.name);
                    toggleMainMenu(menu.name);
                  }}
                >
                  <div className="flex flex-row justify-center items-center px-1 gap-2">
                    <span className={` ${collapsed ? "w-6" : "w-6"} h-6`}>
                      <div>
                        {React.createElement(menu?.icon, { size: "20" })}
                      </div>
                    </span>
                    {!collapsed && <span>{menu.name}</span>}
                  </div>
                  {!collapsed && menu?.sub_menus && (
                    <>
                      {menu.name === mainMenuOpen ? (
                        <RiArrowUpSLine className="ml-auto" /> // Up arrow when submenu is open
                      ) : (
                        <RiArrowDownSLine className="ml-auto" /> // Down arrow when submenu is closed
                      )}
                    </>
                  )}
                </NavLink>
                <h2
                  className={`${
                    collapsed && "hidden"
                  } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
                >
                  {menu?.name}
                </h2>
                {!collapsed &&
                  (openSubMenu === menu.name || mainMenuOpen === menu.name) && // Check if submenu should be open
                  menu.sub_menus && (
                    <ul className="ml-4 mt-1">
                      {menu.sub_menus.map((submenu, i) => (
                        <li key={i}>
                          <NavLink
                            to={submenu.link}
                            className={`flex items-center p-2 space-x-2 ${
                              location.pathname === submenu.link
                                ? "border-l-2 border-purple"
                                : ""
                            }`}
                          >
                            <span className="w-4 h-4"></span>
                            {submenu.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="w-full">
        <div className="p-2  flex flex-row ">
          <ul className="w-full text-sm">
            {menu2.map((menu, i) => (
              <li className="mb-2" key={i}>
                <NavLink
                  to={`${menu?.sub_menus ? "#" : menu.link}`}
                  className={`flex items-center justify-between p-2 space-x-2 ${
                    collapsed ? "justify-center" : "justify-center"
                  } ${
                    location.pathname.includes(menu.link) ? "bg-purple" : ""
                  }`}
                  onClick={() => {
                    handleMenuClick(menu.name);
                    toggleMainMenu(menu.name);
                  }}
                >
                  <div className="flex flex-row justify-center items-center px-1 gap-2">
                    <span className={` ${collapsed ? "w-6" : "w-6"} h-6`}>
                      <div>
                        {React.createElement(menu?.icon, { size: "20" })}
                      </div>
                    </span>
                    {!collapsed && <span>{menu.name}</span>}
                  </div>
                  {!collapsed && menu?.sub_menus && (
                    <>
                      {menu.name === mainMenuOpen ? (
                        <RiArrowUpSLine className="ml-auto" /> // Up arrow when submenu is open
                      ) : (
                        <RiArrowDownSLine className="ml-auto" /> // Down arrow when submenu is closed
                      )}
                    </>
                  )}
                </NavLink>
                <h2
                  className={`${
                    collapsed && "hidden"
                  } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
                >
                  {menu?.name}
                </h2>
                {!collapsed &&
                  (openSubMenu === menu.name || mainMenuOpen === menu.name) && // Check if submenu should be open
                  menu.sub_menus && (
                    <ul className="ml-4 mt-1">
                      {menu.sub_menus.map((submenu, i) => (
                        <li key={i}>
                          <NavLink
                            to={submenu.link}
                            className={`flex items-center p-2 space-x-2 ${
                              location.pathname === submenu.link
                                ? "border-l-2 border-purple"
                                : ""
                            }`}
                          >
                            <span className="w-4 h-4"></span>
                            {submenu.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
              </li>
            ))}
          </ul>
        </div>
        <button
          onClick={toggleCollapse}
          className="py-2 w-full flex flex-row gap-3  mb-6 pl-5 text-white hover:bg-warning hover:text-dark-purple text-sm items-center justify-start focus:outline-none"
        >
          {collapsed ? (
            <BiArrowToRight size={22} />
          ) : (
            <BiArrowToLeft size={20} />
          )}

          {`${collapsed ? "" : "Collapse to side"} `}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
