import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BoxCard01 from "../../../components/ui-elements/cards/box-card-01";
import BoxCard03 from "../../../components/ui-elements/cards/box-card-03";
import LineChart from "../../../components/graphs/react-chartjs-two/line-chart";
import DashboardBanner from "../../../components/ui-elements/banner/dashbaord-banner";
import PieChart from "../../../components/graphs/react-chartjs-two/pie-chart";
import GraphData from "./graphs";
import KPISheetModal from "../../../components/ui-elements/modals/kpis-modals/kpi-sheet-modal";
import countryToSymbolMap from "../../../../constant/country-code-currency.json";
import ProfitAndLossTable from "../../../components/ui-elements/tables/kpis-tables/xero/profit-and-loss-table";
import { json } from "react-router-dom";

function DashboardProfitAndLossPage(props) {
  const {
    overheadCostsDirectCostsPie,
    profitAndLoss,
    xeroKPIFigures,
    isLoading,
    isLoadingKPI,
  } = useSelector((state) => state.kpi);
  const { organisation } = useSelector((state) => state.organisation);
  const countryCode =
    organisation && organisation.country_alpha3
      ? organisation.country_alpha3.replace(/"/g, "")
      : null;
  const currencySymbol = countryCode ? countryToSymbolMap[countryCode] : null;

  // Profit and Loss
  const {
    grossProfitLineData,
    netProfitLineData,
    netProfitMarginLineData,
    netProfitMarginLineGraphData,
    grossProfitLineGraphData,
    netProfitLineGraphData,
    totalCostOfSalesData,
    totalCostOfSalesLineGraphData,
    operatingCostPercentageRevenueData,
    operatingCostPercentageRevenueLineGraphData,
    totalOperatingExpensesData,
    totalOperatingExpensesLineGraphData,
    totalCostsLineGraphData,
    totalCostsData,
    grossProfitMarginData,
    grossProfitMarginLineGraphData,
  } = GraphData();
  // BOX KPIs
  const getKPIFigure = (kpiName) => {
    if (Array.isArray(xeroKPIFigures)) {
      const figure = xeroKPIFigures.find((item) => item.KPI === kpiName);
      return figure || null; // Return null if the figure is not found
    } else {
      return null; // Return null if xeroKPIFigures is not an array
    }
  };

  const operatingMarginFigureData = getKPIFigure("Operating Margin");
  const costOfSalesFigureData = getKPIFigure("Cost of Sales");
  const grossProfitFigureData = getKPIFigure("Gross Profit");
  const totalCostsFigureData = getKPIFigure("Total Costs");
  const netProfitMarginFigureData = getKPIFigure("Net Profit Margin");
  const netProfitFigureData = getKPIFigure("Net Profit");
  const operatingExpensesFigureData = getKPIFigure("Operating Expenses");
  const grossProfitMarginFigureData = getKPIFigure("Gross Profit Margin");

  // Initialize overheadCostsPieChartData
  let overheadCostsPieChartData = {
    labels: [],
    datasets: [
      {
        label: "Total Cost of Sales",
        data: [],
        backgroundColor: "#3490dc", // Use a specific color
        borderColor: "#3490dc",
        borderWidth: 1,
      },
      {
        label: "Total Operating Expenses",
        data: [],
        backgroundColor: "#e3342f", // Use another color
        borderColor: "#e3342f",
        borderWidth: 1,
      },
    ],
  };

  // Check if the data is available
  if (Array.isArray(overheadCostsDirectCostsPie)) {
    overheadCostsPieChartData = {
      labels: overheadCostsDirectCostsPie.map(
        (item) => item.profit_and_loss_type
      ),
      datasets: [
        {
          label: "Total Overhead Costs",
          backgroundColor: ["#58339F", "#FAC952"], // Use an array of colors
          data: overheadCostsDirectCostsPie.map(
            (item) => item.profit_and_loss_filtered_current
          ),
        },
      ],
    };
  }

  // Spark Lines
  function filterLastMonthsData(data, months = 6) {
    if (Array.isArray(data)) {
      return data.slice(0, months);
    } else {
      return [];
    }
  }

  const extractSparkLineData = (data, key) =>
    data.map((entry) => entry[key] || 0);
  function generateSparkLineData(rawData, key) {
    if (!Array.isArray(rawData) || rawData.length === 0) {
      return [];
    }
    // Sort the data by month in descending order
    const sortedData = rawData.slice().sort((a, b) => {
      if (a.month < b.month) return 1;
      if (a.month > b.month) return -1;
      return 0;
    });

    // Extract the last 6 months' data and reverse it
    const last6MonthsData = filterLastMonthsData(sortedData).reverse();
    const sparkLine = extractSparkLineData(last6MonthsData, key);
    return sparkLine; // Return sparkLine array directly without adding 0
  }

  const totalCostOfSalesSpark = totalCostOfSalesData?.total_cost_of_sales || [];
  const sparkLine01TotalCostOfSalesData = generateSparkLineData(
    totalCostOfSalesSpark,
    "total_cost_of_sales"
  );
  const sparkLine02TotalCostOfSalesData = generateSparkLineData(
    totalCostOfSalesSpark,
    "previous_year_total_cost_of_sales"
  );
  // Spark Lines for Gross Profit
  const grossProfitSpark = grossProfitLineData?.gross_profit || [];
  const sparkLine01GrossProfitData = generateSparkLineData(
    grossProfitSpark,
    "gross_profit"
  );
  const sparkLine02GrossProfitData = generateSparkLineData(
    grossProfitSpark,
    "previous_year_gross_profit"
  );
  // Spark Lines for Gross Profit Margin
  const grossProfitMarginSpark =
    grossProfitMarginData?.gross_profit_margin || [];
  const sparkLine01GrossProfitMarginData = generateSparkLineData(
    grossProfitMarginSpark,
    "gross_profit_margin"
  );
  const sparkLine02GrossProfitMarginData = generateSparkLineData(
    grossProfitMarginSpark,
    "previous_year"
  );

  // Spark Lines for Net Profit
  const netProfitSpark = netProfitLineData?.net_profit || [];
  const sparkLine01NetProfitData = generateSparkLineData(
    netProfitSpark,
    "net_profit"
  );
  const sparkLine02NetProfitData = generateSparkLineData(
    netProfitSpark,
    "previous_year_net_profit"
  );

  // Spark Lines for Net Profit Margin
  const netProfitMarginSpark = netProfitMarginLineData?.net_profit_margin || [];
  const sparkLine01NetProfitMarginData = generateSparkLineData(
    netProfitMarginSpark,
    "net_profit_margin"
  );
  const sparkLine02NetProfitMarginData = generateSparkLineData(
    netProfitMarginSpark,
    "previous_year_net_profit_margin"
  );

  // Spark Lines for Operating Cost Percentage to Revenue
  const operatingCostPercentageRevenueSpark =
    operatingCostPercentageRevenueData?.operating_cost_percentage_revenue || [];
  const sparkLine01OperatingCostPercentageRevenueData = generateSparkLineData(
    operatingCostPercentageRevenueSpark,
    "operating_cost_percentage_revenue"
  );
  const sparkLine02OperatingCostPercentageRevenueData = generateSparkLineData(
    operatingCostPercentageRevenueSpark,
    "previous_year_operating_cost_percentage_revenue"
  );


  // Spark Lines for Total Costs
  const totalCostsSpark =
    totalCostsData?.total_costs || [];
  const sparkLine01TotalCostsData = generateSparkLineData(
    totalCostsSpark,
    "total_costs"
  );
  const sparkLine02TotalCostsData = generateSparkLineData(
    totalCostsSpark,
    "previous_year_total_costs"
  );


  const extractFilters = (kpiTitle) => {
    const savedFilters = organisation?.saved_filters[organisation?.name];
    const filter = savedFilters?.find((filter) => filter.kpi === kpiTitle);
    if (filter) {
      return {
        firstFilter: filter.first_filter,
        comparedTo: filter.compared_to,
      };
    }
    return { firstFilter: "This Month", comparedTo: "Previous Period" };
  };

  const grossProfitFilters = extractFilters('Gross Profit');
  const totalCostsFilters = extractFilters('Total Costs');
  const grossProfitMarginFilters = extractFilters('Gross Profit Margin');
  const netProfitFilters = extractFilters('Net Profit');
  const netProfitMarginFilters = extractFilters('Net Profit Margin');
  const operatingExpensesFilters = extractFilters('Total Operating Expenses');
  const costOfSalesFilters = extractFilters('Cost of Sales');
  const operatingMarginFilters = extractFilters('Operating Margin');



  // Spark Lines for Total Operating Expenses
  const totalOperatingExpensesSpark =
    totalOperatingExpensesData?.total_operating_expenses || [];
  const sparkLine01TotalOperatingExpensesData = generateSparkLineData(
    totalOperatingExpensesSpark,
    "total_operating_expenses"
  );
  const sparkLine02TotalOperatingExpensesData = generateSparkLineData(
    totalOperatingExpensesSpark,
    "previous_year_total_operating_expenses"
  );


  // KPI Sheet Modal

  const [toggleKPISheet, setKPISheetToggle] = useState(false);
  const [kpiSheetTitle, setKPISheetTitle] = useState("");

  const toggleKPI = (title) => {
    setKPISheetTitle(title);
    setKPISheetToggle(!toggleKPISheet);
  };


  const xeroKPIComponent = (
    <div class="mx-0 ">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <div class=" rounded-md">
          <BoxCard01
            isCustomSymbol={false}
            toggleKPI={() => toggleKPI("Gross Profit")}
            title="Gross Profit"
            data={grossProfitFigureData}
            savedFilterA={grossProfitFilters.firstFilter}
            savedFilterB={grossProfitFilters.comparedTo}
            sparkLine01Data={sparkLine01GrossProfitData}
            sparkLine02Data={sparkLine02GrossProfitData}
          />
        </div>
        <div class=" rounded-md">
          <BoxCard01
            customSymbol={"% "}
            isCustomSymbol={true}
            toggleKPI={() => toggleKPI("Gross Profit Margin")}
            title="Gross Profit Margin"
            savedFilterA={grossProfitMarginFilters.firstFilter}
            savedFilterB={grossProfitMarginFilters.comparedTo}
            data={grossProfitMarginFigureData}
            sparkLine01Data={sparkLine01GrossProfitMarginData}
            sparkLine02Data={sparkLine02GrossProfitMarginData}
          />
        </div>
        <br />
        <div class=" rounded-md">
          <BoxCard01
            toggleKPI={() => toggleKPI("Net Profit")}
            title="Net Profit"
            isCustomSymbol={false}
            data={netProfitFigureData}
            savedFilterA={netProfitFilters.firstFilter}
            savedFilterB={netProfitFilters.comparedTo}
            sparkLine01Data={sparkLine01NetProfitData}
            sparkLine02Data={sparkLine02NetProfitData}
          />
        </div>
        <div class=" rounded-md">
          <BoxCard01
            toggleKPI={() => toggleKPI("Net Profit Margin")}
            customSymbol={"% "}
            isCustomSymbol={true}
            title="Net Profit Margin"
            data={netProfitMarginFigureData}
            savedFilterA={netProfitMarginFilters.firstFilter}
            savedFilterB={netProfitMarginFilters.comparedTo}
            sparkLine01Data={sparkLine01NetProfitMarginData}
            sparkLine02Data={sparkLine02NetProfitMarginData}
          />
        </div>
        <br />
        <div class=" rounded-md">
          <BoxCard01
            toggleKPI={() =>
              toggleKPI("Total Operating Expenses")
            }
            isCustomSymbol={false}
            title="Operating Expenses"
            data={operatingExpensesFigureData}
            savedFilterA={operatingExpensesFilters.firstFilter}
            savedFilterB={operatingExpensesFilters.comparedTo}
            sparkLine01Data={sparkLine01TotalOperatingExpensesData}
            sparkLine02Data={sparkLine02TotalOperatingExpensesData}
          />
        </div>
        <div class=" rounded-md">
          <BoxCard01
            toggleKPI={() => toggleKPI("Operating Margin")}
            title="Operating Margin"
            customSymbol={"% "}
            isCustomSymbol={true}
            data={operatingMarginFigureData}
            savedFilterA={operatingMarginFilters.firstFilter}
            savedFilterB={operatingMarginFilters.comparedTo}
            sparkLine01Data={sparkLine01OperatingCostPercentageRevenueData}
            sparkLine02Data={sparkLine02OperatingCostPercentageRevenueData}
          />
        </div>
        <br />
        <div class=" rounded-md">
          <BoxCard01
            isCustomSymbol={false}
            toggleKPI={() => toggleKPI(totalCostsFigureData?.KPI || 'Total Costs')}
            title={totalCostsFigureData?.KPI || 'Total Costs'}
            data={totalCostsFigureData}
            savedFilterA={totalCostsFilters.firstFilter}
            savedFilterB={totalCostsFilters.comparedTo}
            sparkLine01Data={sparkLine01TotalCostsData}
            sparkLine02Data={sparkLine02TotalCostsData}
          />
        </div>
        <div class=" rounded-md">
          <BoxCard01
            toggleKPI={() => toggleKPI("Cost of Sales")}
            title="Cost of Sales"
            isCustomSymbol={false}
            savedFilterA={costOfSalesFilters.firstFilter}
            savedFilterB={costOfSalesFilters.comparedTo}
            data={costOfSalesFigureData}
            sparkLine01Data={sparkLine01TotalCostOfSalesData}
            sparkLine02Data={sparkLine02TotalCostOfSalesData}
          />
        </div>
      </div>
      <div class="w-full">
        <ProfitAndLossTable
          title={
            profitAndLoss?.profitAndLoss?.[0]?.kpi ||
            "Profit & Loss"
          }
          tooltip={
            profitAndLoss?.profitAndLoss?.[0]?.description ||
            "Figures of profit & loss accounts in Xero"
          }
          isLoading={isLoadingKPI}
          tableData={profitAndLoss?.profitAndLoss?.[0]?.data}
          isWidthFull={true}
          opened={false}
        />
      </div>
    </div>
  );


  const dearKPIComponent = (
    <p className="mt-5 text-secondary text-sm"></p>
  )
  const displayKPIComponent = (item) => {
    switch (item) {
      case "XERO":
        return xeroKPIComponent;
      case "DEAR":
        return dearKPIComponent;
      default:
        return 'Unknown';
    }
  };

  return (
    <div className="w-full h-full px-5 py-5 bg-light-white flex flex-col">
      <div className="bg-white flex-1 p-5 border-2 border-light-grey mt-5 justify-center items-start">
        <DashboardBanner
          title="Profit and Loss"
          isGlobalFilterRequired={false}
        />
        <KPISheetModal
          toggle={toggleKPISheet}
          toggleModal={toggleKPI}
          kpiName={kpiSheetTitle}
        >
          <p className="font-bold text-lg">{kpiSheetTitle}</p>

          {kpiSheetTitle === "Cost of Sales" && (
            <LineChart
              currencySymbol={currencySymbol}
              companyData={totalCostOfSalesLineGraphData}
              company={organisation}
              title={"Total Cost of Sales"}
            />
          )}
          {kpiSheetTitle === "Gross Profit" && (
            <LineChart
              currencySymbol={currencySymbol}

              companyData={grossProfitLineGraphData}
              company={organisation}
              title={"Gross Profit and Previous Year"}
            />
          )}
          {kpiSheetTitle === "Gross Profit Margin" && (
            <LineChart
              currencySymbol={'%'}
              companyData={grossProfitMarginLineGraphData}
              company={organisation}
              title={"Gross Profit Margin of"}
            />
          )}
          {kpiSheetTitle === "Net Profit" && (
            <LineChart
              currencySymbol={currencySymbol}
              companyData={netProfitLineGraphData}
              company={organisation}
              title={"Net Profit of"}
            />
          )}
          {kpiSheetTitle === "Net Profit Margin" && (
            <LineChart
              currencySymbol={'%'}
              companyData={netProfitMarginLineGraphData}
              company={organisation}
              title={"Net Profit Margin of"}
            />
          )}
          {kpiSheetTitle === "Operating Margin" && (
            <LineChart
              currencySymbol={'%'}
              companyData={operatingCostPercentageRevenueLineGraphData}
              company={organisation}
              title={"Operating Cost Percentage Revenue Data of "}
            />
          )}
          {kpiSheetTitle === "Total Operating Expenses" && (
            <LineChart
              currencySymbol={currencySymbol}
              companyData={totalOperatingExpensesLineGraphData}
              company={organisation}
              title={"Total Operating Expenses of "}
            />
          )}
          {kpiSheetTitle === "Total Costs" && (
            <LineChart
              currencySymbol={currencySymbol}
              companyData={totalCostsLineGraphData}
              company={organisation}
              title={"Total Costs"}
            />
          )}
        </KPISheetModal>
        {organisation?.connected_apps && organisation.connected_apps.map((item, index) => (
          <div key={index}>
            {displayKPIComponent(item)}
          </div>
        ))}
        {/* <p className="font-semibold mt-5">Recent Graphs</p>
        <div className="w-full gap-2 flex flex-row">
        <div className="w-1/2">
        <PieChart
          chartData={overheadCostsPieChartData}
          company={organisation}
          title="Overhead Costs Distribution"
        />
  </div>
        </div>
        */}

        {/* <div className="w-full gap-2 flex flex-row mt-5">
          <div className="w-1/2">
            <LineChart
              companyData={grossProfitLineGraphData}
              company={organisation}
              title={"Gross Profit and Previous Year"}
            />
          </div>
          <div className="w-1/2">
            <LineChart
              companyData={netProfitMarginLineGraphData}
              company={organisation}
              title={"Net Profit Margin of"}
            />
          </div>
        </div> */}
        {/* <div className="w-full gap-2 flex flex-row mt-5">
          <div className="w-1/2">
            <LineChart
              companyData={totalCostOfSalesLineGraphData}
              company={organisation}
              title={"Total Cost of Sales"}
            />
          </div>
          
        </div> */}
      </div>
    </div>
  );
}

export default DashboardProfitAndLossPage;
