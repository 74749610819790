import React, { useEffect, useRef, useState } from "react";
import { BiCommentDetail } from "react-icons/bi";
import { BsArrowDownCircleFill, BsFillArrowUpCircleFill } from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import SpinnerKPI from "../utilis/spinner-kpi";
import SimpleSparkline from "../../graphs/recharts/spark-line";
import dear from "../../../../assets/logos/cin7-core-teal-1.png";
import Tippy from "@tippyjs/react";
import { IoIosInformationCircle } from "react-icons/io";
import countryToSymbolMap from "../../../../constant/country-code-currency.json";
import { BsFillFilterSquareFill } from "react-icons/bs";
import MultiSelect from "../utilis/multi-select";
import {
  getDearPercentageCustomer80SalesKPIFilteredData,
  getDearPercentageOrderShippedLateKPIFilteredData,
  getDearPercentageProduct80SalesKPIFilteredData,
  getDearProductSalesKPIFilteredData,
  getDearProfitDrilldown01TableByCustomer,
  getDearProfitDrilldown01TableByProduct,
  getDearProfitDrilldown02TableByCustomer,
  getDearProfitDrilldown02TableByProduct,
  getDearProfitKPIFilteredData,
  getDearSalesDrilldown01TableByCustomer,
  getDearSalesDrilldown01TableByProduct,
  getDearSalesDrilldown02TableByCustomer,
  getDearSalesDrilldown02TableByProduct,
  getDearUnitsShippedDrilldown01TableByCustomer,
  getDearUnitsShippedDrilldown01TableByProduct,
  getDearUnitsShippedDrilldown02TableByCustomer,
  getDearUnitsShippedDrilldown02TableByProduct,
  getDearUnitsShippedKPIFilteredData,
  getDearUnitsSoldDrilldown01TableByCustomer,
  getDearUnitsSoldDrilldown01TableByProduct,
  getDearUnitsSoldDrilldown02TableByProduct,
  getDearUnitsSoldKPIFilteredData,
  getDearSalesByProductByMonthFilteredData,
  getDearIncomingStockKPIFilteredData,
  getDearPercentageCustomer80SalesDrilldownFilteredData,
  getDearPercentageProduct80SalesDrilldownFilteredData,
  getDearInventoryValueDrilldownFilteredData,
} from "../../../../redux-features/slices/kpi/kpiSlice";
const AdvancedDearFilters = (props) => {
  const { isLoadingDearKPI } = useSelector((state) => state.kpi);
  const {
    title,
    isSubDrilldown,
    skuCode,
    contactName,
    handleAdvanceDearFilters,
    showBrandFilter,
    showProductFilter,
    showProductTagsFilter,
    showCustomerFilter,
    showCustomerTagsFilter,
    showCategoryFilter,
    showTypeFilter,
  } = props;
  const dispatch = useDispatch();
  const [togglePaySubscription, setPaySubscriptionToggle] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { organisation } = useSelector((state) => state.organisation);
  const {
    dearBrandFilters,
    dearCategoryFilters,
    dearContactFilters,
    dearCustomerTagsFilters,
    dearProductFilters,
    dearProductTagsFilters,
    dearProductTypeFilters,
  } = useSelector((state) => state.kpi);

  const token = user?.data?.api_token;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isDropdownFilterOpen, setIsDropdownFilterOpen] = useState(false);

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!dropdownRef?.current?.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  const brandFilterOptions = Array.isArray(dearBrandFilters)
    ? dearBrandFilters.map((filter) => ({
      label: filter?.brand
        ? filter.brand
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.brand || "",
    }))
    : [];

  const contactFilterOptions = Array.isArray(dearContactFilters)
    ? dearContactFilters.map((filter) => ({
      label: filter?.contact_name
        ? filter.contact_name
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.contact_name || "",
    }))
    : [];

  const categoryFilterOptions = Array.isArray(dearCategoryFilters)
    ? dearCategoryFilters.map((filter) => ({
      label: filter?.category
        ? filter.category
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.category || "",
    }))
    : [];

  const customerTagsFilterOptions = Array.isArray(dearCustomerTagsFilters)
    ? dearCustomerTagsFilters.map((filter) => ({
      label: filter?.customer_tag
        ? filter.customer_tag
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.customer_tag || "",
    }))
    : [];

  const productFilterOptions = Array.isArray(dearProductFilters)
    ? dearProductFilters.map((filter) => ({
      label: filter?.product_name
        ? filter.product_name
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.product_name || "",
    }))
    : [];

  const productTagsFilterOptions = Array.isArray(dearProductTagsFilters)
    ? dearProductTagsFilters.map((filter) => ({
      label: filter?.product_tag
        ? filter.product_tag
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.product_tag || "",
    }))
    : [];

  const productTypeFilterOptions = Array.isArray(dearProductTypeFilters)
    ? dearProductTypeFilters.map((filter) => ({
      label: filter?.product_type
        ? filter.product_type
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
        : "",
      value: filter?.product_type || "",
    }))
    : [];

  const [brandFilterSelected, setBrandFilterSelected] = useState(null);
  const [categoryFilterSelected, setCategoryFilterSelected] = useState(null);
  const [contactFilterSelected, setContactFilterSelected] = useState(null);
  const [customerTagsFilterSelected, setCustomerTagsFilterSelected] =
    useState(null);
  const [productFilterSelected, setProductFilterSelected] = useState(null);
  const [productTagsFilterSelected, setProductTagsFilterSelected] =
    useState(null);
  const [productTypeFilterSelected, setProductTypeFilterSelected] =
    useState(null);

  const handleContactChange = (selected) => {
    setContactFilterSelected(selected);
  };

  const handleCustomerTagsChange = (selected) => {
    setCustomerTagsFilterSelected(selected);
  };
  const handleBrandChange = (selected) => {
    setBrandFilterSelected(selected);
  };
  const handleProductChange = (selected) => {
    setProductFilterSelected(selected);
  };

  const handleProductTagsChange = (selected) => {
    setProductTagsFilterSelected(selected);
  };

  const handleProductTypeChange = (selected) => {
    setProductTypeFilterSelected(selected);
  };

  const handleCategoryChange = (selected) => {
    setCategoryFilterSelected(selected);
  };

  const handleFilterAction = async (actionType) => {
    const selectedBrands =
      brandFilterSelected?.map((selected) => selected.value) || [];
    const selectedCategories =
      categoryFilterSelected?.map((selected) => selected.value) || [];
    const selectedContacts =
      contactFilterSelected?.map((selected) => selected.value) || [];
    const selectedProducts =
      productFilterSelected?.map((selected) => selected.value) || [];
    const selectedProductTypes =
      productTypeFilterSelected?.map((selected) => selected.value) || [];
    const selectedProductTags =
      productTagsFilterSelected?.map((selected) => selected.value) || [];
    const selectedCustomerTags =
      customerTagsFilterSelected?.map((selected) => selected.value) || [];

    const dataset_id = organisation?.dataset_ids.dear;

    const params =
      actionType === "save"
        ? {
          brand: selectedBrands.join(", "),
          category: selectedCategories.join(", "),
          contact: selectedContacts.join(", "),
          product: selectedProducts.join(", "),
          productType: selectedProductTypes.join(", "),
          productTags: selectedProductTags.join(", "),
          customerTags: selectedCustomerTags.join(", "),
          ...(skuCode ? { sku_code: skuCode } : {}),
          ...(contactName ? { contact_name: contactName } : {}),
        }
        : {
          brand: "",
          category: "",
          contact: "",
          product: "",
          productType: "",
          productTags: "",
          customerTags: "",
          ...(skuCode ? { sku_code: skuCode } : {}),
          ...(contactName ? { contact_name: contactName } : {}),
        };


    if (title === "Units Sold - Customers") {
      await dispatch(
        getDearUnitsSoldDrilldown01TableByCustomer({
          dataset_id,
          token,
          params,
        })
      );
    }
    if (title === "Units Sold - Products") {
      await dispatch(
        getDearUnitsSoldDrilldown01TableByProduct({ dataset_id, token, params })
      );
    }

    if (title === "Net Sales - Products") {
      await dispatch(
        getDearSalesDrilldown01TableByProduct({ dataset_id, token, params })
      );
    }
    if (title === "Net Sales - Customers") {
      await dispatch(
        getDearSalesDrilldown01TableByCustomer({ dataset_id, token, params })
      );
    }
    if (title === "Units Shipped - Products") {
      await dispatch(
        getDearUnitsShippedDrilldown01TableByProduct({
          dataset_id,
          token,
          params,
        })
      );
    }
    if (title === "Units Shipped - Customers") {
      await dispatch(
        getDearUnitsShippedDrilldown01TableByCustomer({
          dataset_id,
          token,
          params,
        })
      );
    }
    if (title === "Gross Profit - Products") {
      await dispatch(
        getDearProfitDrilldown01TableByProduct({ dataset_id, token, params })
      );
    }
    if (title === "Gross Profit - Customers") {
      await dispatch(
        getDearProfitDrilldown01TableByCustomer({ dataset_id, token, params })
      );
    }


    // Sub drilldowns
    if (title === "Units Sold - Customers" && isSubDrilldown) {
      await dispatch(
        getDearUnitsSoldDrilldown01TableByCustomer({
          dataset_id,
          token,
          params,
        })
      );
    }
    if (title === "Units Sold - Products" && isSubDrilldown) {
      await dispatch(
        getDearUnitsSoldDrilldown02TableByProduct({ dataset_id, token, params })
      );
    }

    if (title === "Net Sales - Products" && isSubDrilldown) {
      await dispatch(
        getDearSalesDrilldown02TableByProduct({ dataset_id, token, params })
      );
    }
    if (title === "Net Sales - Customers" && isSubDrilldown) {
      await dispatch(
        getDearSalesDrilldown02TableByCustomer({ dataset_id, token, params })
      );
    }
    if (title === "Units Shipped - Products" && isSubDrilldown) {
      await dispatch(
        getDearUnitsShippedDrilldown02TableByProduct({
          dataset_id,
          token,
          params,
        })
      );
    }
    if (title === "Units Shipped - Customers" && isSubDrilldown) {
      await dispatch(
        getDearUnitsShippedDrilldown02TableByCustomer({
          dataset_id,
          token,
          params,
        })
      );
    }
    if (title === "Gross Profit - Products" && isSubDrilldown) {
      await dispatch(
        getDearProfitDrilldown02TableByProduct({ dataset_id, token, params })
      );
    }
    if (title === "Gross Profit - Customers" && isSubDrilldown) {
      await dispatch(
        getDearProfitDrilldown02TableByCustomer({ dataset_id, token, params })
      );
    }
    if (title === "Sales By Product By Month") {
      await dispatch(
        getDearSalesByProductByMonthFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Incoming Stock") {
      await dispatch(
        getDearIncomingStockKPIFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "% of Customers Who Make Up 80% of Sales Drilldown") {
      await dispatch(
        getDearPercentageCustomer80SalesDrilldownFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "% of Products That Make Up 80% of Sales Drilldown") {
      await dispatch(
        getDearPercentageProduct80SalesDrilldownFilteredData({ dataset_id, token, params })
      );
    }
    if (title === "Inventory Value Drilldown") {
      await dispatch(
        getDearInventoryValueDrilldownFilteredData({ dataset_id, token, params })
      );
    }
    await handleAdvanceDearFilters(); // Close the filters
  };
  return (
    <div className="flex-shrink-0 w-full">
      <div className="flex flex-col min-h-[130px]">
        <div className="flex-grow h-full border-[0.5px] rounded-lg  bg-white shadow-sm border-zinc-200">
          <div className="px-5 py-3 w-full flex flex-col items-start gap-2 justify-between  h-full relative">
            <div className="w-full mt-2 ">
{title}
              <div className="w-full flex flex-col gap-2">
                {showBrandFilter && (
                  <div className="flex items-center flex-row">
                    <p className="w-1/4 text-sm">Brands: 
                    
                     </p>
                    <MultiSelect
                      color="#fdba74"
                      className="w-full"
                      key="brand_id"
                      options={brandFilterOptions}
                      onChange={handleBrandChange}
                      value={brandFilterSelected}
                      isSelectAll={true}
                      menuPlacement={"bottom"}
                    />
                  </div>
                )}
                {showProductFilter && (
                  <div className="flex items-center flex-row">
                    <p className="w-1/4 text-sm">Product: </p>
                    <MultiSelect
                      className="w-full"
                      key="product_tags_id"
                      // color="#69b6ff"
                      color="#addff7"
                      options={productFilterOptions}
                      onChange={handleProductChange}
                      value={productFilterSelected}
                      isSelectAll={true}
                      menuPlacement={"bottom"}
                    />
                  </div>
                )}
                {showProductTagsFilter && (
                  <div className="flex items-center flex-row">
                    <p className="w-1/4 text-sm">Product Tag: </p>
                    <MultiSelect
                      className="w-full"
                      key="product_tags_id"
                      // color="#69b6ff"
                      color="#7dd3fc"
                      options={productTagsFilterOptions}
                      onChange={handleProductTagsChange}
                      value={productTagsFilterSelected}
                      isSelectAll={true}
                      menuPlacement={"bottom"}
                    />
                  </div>
                )}
                {showCustomerFilter && (
                  <div className="flex items-center flex-row">
                    <p className="w-1/4 text-sm">Customer: </p>
                    <MultiSelect
                      className="w-full"
                      key="customer_tag_id"
                      color="#9ae6c7"
                      options={contactFilterOptions}
                      onChange={handleContactChange}
                      value={contactFilterSelected}
                      isSelectAll={true}
                      menuPlacement={"bottom"}
                    />
                  </div>
                )}
                {showCustomerTagsFilter && (
                  <div className="flex items-center flex-row">
                    <p className="w-1/4 text-sm">Customer Tag: </p>
                    <MultiSelect
                      className="w-full"
                      key="customer_tag_id"
                      color="#6ee7b7"
                      options={customerTagsFilterOptions}
                      onChange={handleCustomerTagsChange}
                      value={customerTagsFilterSelected}
                      isSelectAll={true}
                      menuPlacement={"bottom"}
                    />
                  </div>
                )}
                {showCategoryFilter && (
                  <div className="flex items-center flex-row">
                    <p className="w-1/4 text-sm">Category: </p>
                    <MultiSelect
                      className="w-full"
                      key="category_id"
                      color="#d4d4d4"
                      options={categoryFilterOptions}
                      onChange={handleCategoryChange}
                      value={categoryFilterSelected}
                      isSelectAll={true}
                      menuPlacement={"bottom"}
                    />
                  </div>
                )}

                {showTypeFilter && (
                  <div className="flex items-center flex-row">
                    <p className="w-1/4 text-sm">Type: </p>
                    <MultiSelect
                      className="w-full"
                      key="product_type_id"
                      color="#d8b4fe"
                      options={productTypeFilterOptions}
                      onChange={handleProductTypeChange}
                      value={productTypeFilterSelected}
                      isSelectAll={true}
                      menuPlacement={"bottom"}
                    />
                  </div>
                )}
                <div className="flex items-center mt-3 flex-row gap-2 ">
                  <button
                    onClick={() => handleFilterAction("save")}
                    className="w-full inline-flex justify-center rounded-md border border-transparent text-xs shadow-sm py-2 bg-purple font-medium text-white hover:bg-purple/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save Filters
                  </button>

                  <button
                    onClick={() => handleFilterAction("remove")}
                    className="w-full inline-flex justify-center rounded-md border border-transparent text-xs shadow-sm py-2 bg-warning font-medium text-white hover:bg-warning/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Remove Filters
                  </button>

                  <button
                    type="button"
                    onClick={handleAdvanceDearFilters}
                    className="w-full inline-flex justify-center rounded-md border border-slate-200 text-xs shadow-sm py-2 font-medium text-grey hover:bg-dark-purple hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedDearFilters;
