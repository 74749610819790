import axios from "axios";

import {
  ADD_KPI_FAVORITE,
  APPLY_XERO_KPI_FILTERS,
  ENV_URL,
  GET_ALL_XERO_KPIS,
  GET_CUSTOMERS_WITHOUT_TAGS,
  GET_DATASET_ID,
  GET_DEAR_BRAND_FILTERS,
  GET_DEAR_CATEGORY_FILTERS,
  GET_DEAR_CONTACT_FILTERS,
  GET_DEAR_COST_OF_GOODS_SOLD,
  GET_DEAR_CUSTOMER_TAGS_FILTERS,
  GET_DEAR_GROSS_PROFIT_MARGIN,
  GET_DEAR_INVENTORY_VALUE,
  GET_DEAR_LOCATION_FILTERS,
  GET_DEAR_NO_SALES_ORDERS_BY_CARRIERS,
  GET_DEAR_PRODUCT_FILTERS,
  GET_DEAR_PRODUCT_SALES,
  GET_DEAR_PRODUCT_SALES_BY_BRAND,
  GET_DEAR_PRODUCT_SALES_BY_CATEGORY,
  GET_DEAR_PRODUCT_SALES_BY_CUSTOMER,
  GET_DEAR_PRODUCT_SALES_BY_CUSTOMER_TAG,
  GET_DEAR_PRODUCT_SALES_BY_PRODUCT,
  GET_DEAR_PRODUCT_SALES_BY_PRODUCT_TAG,
  GET_DEAR_PRODUCT_SALES_BY_PRODUCT_TYPE,
  GET_DEAR_PRODUCT_SALES_LINE_GRAPH,
  GET_DEAR_PRODUCT_TAGS_FILTERS,
  GET_DEAR_PRODUCT_TYPE_FILTERS,
  GET_DEAR_PROFIT,
  GET_DEAR_PROFIT_BY_BRAND,
  GET_DEAR_PROFIT_BY_CATEGORY,
  GET_DEAR_PROFIT_BY_CUSTOMER,
  GET_DEAR_PROFIT_BY_CUSTOMER_TAG,
  GET_DEAR_PROFIT_BY_PRODUCT,
  GET_DEAR_PROFIT_BY_PRODUCT_TAG,
  GET_DEAR_PROFIT_BY_PRODUCT_TYPE,
  GET_DEAR_PROFIT_LINE_GRAPH,
  GET_DEAR_UNITS_SOLD,
  GET_DEAR_UNITS_SOLD_BY_BRAND,
  GET_DEAR_UNITS_SOLD_BY_CATEGORY,
  GET_DEAR_UNITS_SOLD_BY_CUSTOMER,
  GET_DEAR_UNITS_SOLD_BY_CUSTOMER_TAG,
  GET_DEAR_UNITS_SOLD_BY_PRODUCT,
  GET_DEAR_UNITS_SOLD_BY_PRODUCT_TAG,
  GET_DEAR_UNITS_SOLD_BY_PRODUCT_TYPE,
  GET_DEAR_UNITS_SOLD_LINE_GRAPH,
  GET_LAST_UPDATED_DEAR,
  GET_LAST_UPDATED_XERO,
  GET_LINE_GRAPHS,
  GET_LIST_ORGANISATION_KPIS,
  GET_ON_DEMAND_EXTRACTION,
  GET_PERCENTAGE_CUSTOMER_80_SALES,
  GET_PERCENTAGE_ORDER_SHIPPED_LATE,
  GET_PERCENTAGE_PRODUCT_80_SALES,
  GET_PROFIT_DRILLDOWN_CUSTOMER,
  GET_PROFIT_DRILLDOWN_CUSTOMER_2,
  GET_PROFIT_DRILLDOWN_PRODUCT,
  GET_PROFIT_DRILLDOWN_PRODUCT_2,
  GET_SALES_DRILLDOWN_CUSTOMER,
  GET_SALES_DRILLDOWN_CUSTOMER_2,
  GET_SALES_DRILLDOWN_PRODUCT,
  GET_SALES_DRILLDOWN_PRODUCT_2,
  GET_TOP_LATE_SUPPLIERS,
  GET_TOP_TEN_SUPPLIERS_BY_COST,
  GET_SAMPLE_CONVERSION_RATE,
  GET_TOP_TEN_SUPPLIERS_BY_VOLUME,
  GET_TRACKING_CATEGORY_01,
  GET_TRACKING_CATEGORY_02,
  GET_UNITS_SHIPPED,
  GET_UNITS_SHIPPED_BY_BRAND,
  GET_UNITS_SHIPPED_BY_CATEGORY,
  GET_UNITS_SHIPPED_BY_CUSTOMER,
  GET_UNITS_SHIPPED_BY_CUSTOMER_TAG,
  GET_UNITS_SHIPPED_BY_PRODUCT,
  GET_UNITS_SHIPPED_BY_PRODUCT_TAG,
  GET_UNITS_SHIPPED_BY_PRODUCT_TYPE,
  GET_UNITS_SHIPPED_DRILLDOWN_CUSTOMER,
  GET_UNITS_SHIPPED_DRILLDOWN_CUSTOMER_2,
  GET_UNITS_SHIPPED_DRILLDOWN_PRODUCT,
  GET_UNITS_SHIPPED_DRILLDOWN_PRODUCT_2,
  GET_UNITS_SHIPPED_LINE_GRAPH,
  GET_UNITS_SOLD_DRILLDOWN_CUSTOMER,
  GET_UNITS_SOLD_DRILLDOWN_CUSTOMER_2,
  GET_UNITS_SOLD_DRILLDOWN_PRODUCT,
  GET_UNITS_SOLD_DRILLDOWN_PRODUCT_2,
  GET_XERO_BANK_TABLE,
  GET_PERCENTAGE_CUSTOMER_80_SALES_DRILLDOWN,
  GET_PERCENTAGE_PRODUCT_80_SALES_DRILLDOWN,
  GET_INVENTORY_VALUE_DRILLDOWN,
  GET_INCOMING_INVENTORY_DRILLDOWN,
  GET_OUT_OF_STOCK_RATE,
  GET_DEAR_SALES_BY_MONTH_BY_PRODUCT,
  GET_SAMPLE_SHIPPED,
  GET_PRODUCTS_WITH_ZERO_SALES,
  GET_INCOMING_INVENTORY,
  GET_TOP_TEN_SUPPLIERS_BY_SHIPMENT,
  GET_PRODUCT_WITHOUT_TAGS,
  GET_XERO_PROFIT_AND_LOSS_TABLE,
  GET_XERO_BALANCE_SHEET_TABLE,
} from "../../../constant";

// Grouped Endpoints
const getLineGraphs = async (dataset_id, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_LINE_GRAPHS}${dataset_id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const getTrackingCategory01 = async (dataset_id, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_TRACKING_CATEGORY_01}${dataset_id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const getTrackingCategory02 = async (dataset_id, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_TRACKING_CATEGORY_02}${dataset_id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const getXeroBankTable = async (dataset_id, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_XERO_BANK_TABLE}${dataset_id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const getXeroProfitAndLoss = async (dataset_id, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_XERO_PROFIT_AND_LOSS_TABLE}${dataset_id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const getXeroBalanceSheet = async (dataset_id, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_XERO_BALANCE_SHEET_TABLE}${dataset_id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const getAllXeroKPIs = async (dataset_id, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_ALL_XERO_KPIS}${dataset_id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// Dear KPIs

const getDearBrandFilters = async (dataset_id, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_DEAR_BRAND_FILTERS}${dataset_id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
const getDearCategoryFilters = async (dataset_id, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_DEAR_CATEGORY_FILTERS}${dataset_id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
const getDearContactFilters = async (dataset_id, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_DEAR_CONTACT_FILTERS}${dataset_id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
const getDearCustomerTagsFilters = async (dataset_id, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_DEAR_CUSTOMER_TAGS_FILTERS}${dataset_id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
const getDearProductFilters = async (dataset_id, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_DEAR_PRODUCT_FILTERS}${dataset_id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
const getDearProductTagsFilters = async (dataset_id, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_DEAR_PRODUCT_TAGS_FILTERS}${dataset_id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
const getDearProductTypeFilters = async (dataset_id, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_DEAR_PRODUCT_TYPE_FILTERS}${dataset_id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
const getDearLocationFilters = async (dataset_id, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_DEAR_LOCATION_FILTERS}${dataset_id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
// Product Sales
const getDearProductSalesKPI = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PRODUCT_SALES}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Product Sales KPIs

export const getDearProductSalesLineGraph = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PRODUCT_SALES_LINE_GRAPH}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProductSalesByBrand = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PRODUCT_SALES_BY_BRAND}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProductSalesByCategory = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PRODUCT_SALES_BY_CATEGORY}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProductSalesByCustomer = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PRODUCT_SALES_BY_CUSTOMER}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProductSalesByCustomerTag = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PRODUCT_SALES_BY_CUSTOMER_TAG}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProductSalesByProduct = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PRODUCT_SALES_BY_PRODUCT}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProductSalesByProductTag = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PRODUCT_SALES_BY_PRODUCT_TAG}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProductSalesByProductType = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PRODUCT_SALES_BY_PRODUCT_TYPE}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Profit KPIs

export const getDearProfit = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PROFIT}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProfitLineGraph = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PROFIT_LINE_GRAPH}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProfitByBrand = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PROFIT_BY_BRAND}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProfitByCategory = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PROFIT_BY_CATEGORY}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProfitByCustomer = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PROFIT_BY_CUSTOMER}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProfitByCustomerTag = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PROFIT_BY_CUSTOMER_TAG}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProfitByProduct = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PROFIT_BY_PRODUCT}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProfitByProductTag = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PROFIT_BY_PRODUCT_TAG}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProfitByProductType = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_PROFIT_BY_PRODUCT_TYPE}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Dear Units Sold KPIs

export const getDearUnitsSold = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_UNITS_SOLD}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearUnitsSoldLineGraph = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_UNITS_SOLD_LINE_GRAPH}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearUnitsSoldByBrand = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_UNITS_SOLD_BY_BRAND}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearUnitsSoldByCategory = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_UNITS_SOLD_BY_CATEGORY}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearUnitsSoldByCustomer = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_UNITS_SOLD_BY_CUSTOMER}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearUnitsSoldByCustomerTag = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_UNITS_SOLD_BY_CUSTOMER_TAG}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearUnitsSoldByProduct = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_UNITS_SOLD_BY_PRODUCT}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearUnitsSoldByProductTag = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_UNITS_SOLD_BY_PRODUCT_TAG}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearUnitsSoldByProductType = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_UNITS_SOLD_BY_PRODUCT_TYPE}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Dear KPIs Units Shipped By
export const getUnitsShipped = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SHIPPED}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUnitsShippedLineGraph = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SHIPPED_LINE_GRAPH}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUnitsShippedByBrand = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SHIPPED_BY_BRAND}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUnitsShippedByCategory = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SHIPPED_BY_CATEGORY}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUnitsShippedByCustomer = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SHIPPED_BY_CUSTOMER}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUnitsShippedByCustomerTag = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SHIPPED_BY_CUSTOMER_TAG}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUnitsShippedByProduct = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SHIPPED_BY_PRODUCT}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUnitsShippedByProductTag = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SHIPPED_BY_PRODUCT_TAG}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUnitsShippedByProductType = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SHIPPED_BY_PRODUCT_TYPE}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
// Last Updated of All KPIs
export const getXeroLastUpdated = async (dataset_id, token) => {
  const url = `${ENV_URL}${GET_LAST_UPDATED_XERO}${dataset_id}/`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const getDearLastUpdated = async (dataset_id, token) => {
  const url = `${ENV_URL}${GET_LAST_UPDATED_DEAR}${dataset_id}/`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getPercentageCustomer80Sales = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_PERCENTAGE_CUSTOMER_80_SALES}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getPercentageOrderShippedLate = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_PERCENTAGE_ORDER_SHIPPED_LATE}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getPercentageProduct80Sales = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_PERCENTAGE_PRODUCT_80_SALES}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearSampleShipped = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_SAMPLE_SHIPPED}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getProfitDrilldownCustomer = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_PROFIT_DRILLDOWN_CUSTOMER}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getSalesDrilldownCustomer = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_SALES_DRILLDOWN_CUSTOMER}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUnitsSoldDrilldownCustomer = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SOLD_DRILLDOWN_CUSTOMER}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getProfitDrilldownProduct = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_PROFIT_DRILLDOWN_PRODUCT}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getSalesDrilldownProduct = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_SALES_DRILLDOWN_PRODUCT}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUnitsSoldDrilldownProduct = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SOLD_DRILLDOWN_PRODUCT}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const getUnitsShippedDrilldownProduct = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SHIPPED_DRILLDOWN_PRODUCT}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUnitsShippedDrilldownCustomer = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SHIPPED_DRILLDOWN_CUSTOMER}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getPercentageCustomer80SalesDrilldown = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_PERCENTAGE_CUSTOMER_80_SALES_DRILLDOWN}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getPercentageProduct80SalesDrilldown = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_PERCENTAGE_PRODUCT_80_SALES_DRILLDOWN}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getInventoryValueDrilldown = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_INVENTORY_VALUE_DRILLDOWN}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};


export const getIncomingInventoryDrilldown= async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_INCOMING_INVENTORY_DRILLDOWN}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};


// Dear Tables

export const getDearProductWithoutTags = async (dataset_id, token) => {
  const url = `${ENV_URL}${GET_PRODUCT_WITHOUT_TAGS}${dataset_id}/`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};


// Drilldown 2 (sub-level)
export const getProfitDrilldownCustomer2 = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_PROFIT_DRILLDOWN_CUSTOMER_2}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getSalesDrilldownCustomer2 = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_SALES_DRILLDOWN_CUSTOMER_2}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUnitsSoldDrilldownCustomer2 = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SOLD_DRILLDOWN_CUSTOMER_2}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getProfitDrilldownProduct2 = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_PROFIT_DRILLDOWN_PRODUCT_2}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getSalesDrilldownProduct2 = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_SALES_DRILLDOWN_PRODUCT_2}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUnitsSoldDrilldownProduct2 = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SOLD_DRILLDOWN_PRODUCT_2}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUnitsShippedDrilldownProduct2 = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SHIPPED_DRILLDOWN_PRODUCT_2}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUnitsShippedDrilldownCustomer2 = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_UNITS_SHIPPED_DRILLDOWN_CUSTOMER_2}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearCustomerWithoutTags = async (dataset_id, token) => {
  // const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_CUSTOMERS_WITHOUT_TAGS}${dataset_id}/`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearTopLateSuppliers = async (dataset_id, token) => {
  // const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_TOP_LATE_SUPPLIERS}${dataset_id}/`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearProductsWithZeroSales = async (dataset_id, token) => {
  const url = `${ENV_URL}${GET_PRODUCTS_WITH_ZERO_SALES}${dataset_id}/`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearSampleConversionRate = async (dataset_id, token) => {
  const url = `${ENV_URL}${GET_SAMPLE_CONVERSION_RATE}${dataset_id}/`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearIncomingInventory = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_INCOMING_INVENTORY}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearOutOfStockRate = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_OUT_OF_STOCK_RATE}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearTopTenSuppliersByCost = async (dataset_id, token) => {
  const url = `${ENV_URL}${GET_TOP_TEN_SUPPLIERS_BY_COST}${dataset_id}/`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const getDearTopTenSuppliersByVolume = async (dataset_id, token) => {
  const url = `${ENV_URL}${GET_TOP_TEN_SUPPLIERS_BY_VOLUME}${dataset_id}/`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const getDearTopTenSuppliersByShipment = async (dataset_id, token) => {
  const url = `${ENV_URL}${GET_TOP_TEN_SUPPLIERS_BY_SHIPMENT}${dataset_id}/`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const getOnDemandDearDataExtraction = async (slug, refresh_payload, token) => {
  const response = await axios.post(
    `${ENV_URL}${GET_ON_DEMAND_EXTRACTION}${slug}/`,
    refresh_payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const getDearGrossProfitMarginKPI = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_GROSS_PROFIT_MARGIN}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearInventoryValue = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_INVENTORY_VALUE}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const getDearNoSalesOrderByCarriers = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_NO_SALES_ORDERS_BY_CARRIERS}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const getDearSalesByMonthByProduct = async (
  dataset_id,
  token,
  params
) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_SALES_BY_MONTH_BY_PRODUCT}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDearCostOfGoodsSold = async (dataset_id, token, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `${ENV_URL}${GET_DEAR_COST_OF_GOODS_SOLD}${dataset_id}/?${queryString}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const saveXeroKPIFilters = async (savedXeroFilters, token) => {
  const response = await axios.post(
    `${ENV_URL}${APPLY_XERO_KPI_FILTERS}`,
    savedXeroFilters,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const getDatasetId = async (slug, token) => {
  const response = await axios.get(`${ENV_URL}${GET_DATASET_ID}${slug}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
const getListOrganisationKPIs = async (slug, token) => {
  const response = await axios.get(
    `${ENV_URL}${GET_LIST_ORGANISATION_KPIS}${slug}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
const addKPIFavorite = async (slug, token) => {
  const response = await axios.get(`${ENV_URL}${ADD_KPI_FAVORITE}${slug}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const KPIService = {
  getLineGraphs,
  getDatasetId,
  getTrackingCategory01,
  getTrackingCategory02,
  getAllXeroKPIs,
  getXeroProfitAndLoss,
  getXeroBalanceSheet,
  getDearBrandFilters,
  getDearCategoryFilters,
  getDearCustomerTagsFilters,
  getDearContactFilters,
  getDearProductFilters,
  getDearProductTagsFilters,
  getDearProductTypeFilters,
  getDearLocationFilters,
  getDearProductSalesKPI,
  // Product Sales KPIs
  getDearProductSalesLineGraph,
  getDearProductSalesByBrand,
  getDearProductSalesByCategory,
  getDearProductSalesByCustomer,
  getDearProductSalesByCustomerTag,
  getDearProductSalesByProduct,
  getDearProductSalesByProductTag,
  getDearProductSalesByProductType,
  // Profit KPIs
  getDearProfit,
  getDearProfitLineGraph,
  getDearProfitByBrand,
  getDearProfitByCategory,
  getDearProfitByCustomer,
  getDearProfitByCustomerTag,
  getDearProfitByProduct,
  getDearProfitByProductTag,
  getDearProfitByProductType,
  // Units Sold By
  getDearUnitsSold,
  getDearUnitsSoldByBrand,
  getDearUnitsSoldByCategory,
  getDearUnitsSoldByCustomer,
  getDearUnitsSoldByCustomerTag,
  getDearUnitsSoldByProduct,
  getDearUnitsSoldByProductTag,
  getDearUnitsSoldByProductType,
  getDearUnitsSoldLineGraph,
  getUnitsShipped,
  getUnitsShippedByBrand,
  getUnitsShippedByCategory,
  getUnitsShippedByCustomer,
  getUnitsShippedByCustomerTag,
  getUnitsShippedByProduct,
  getUnitsShippedByProductTag,
  getUnitsShippedByProductType,
  getUnitsShippedLineGraph,
  saveXeroKPIFilters,
  getUnitsShipped,
  getDearLastUpdated,
  getXeroLastUpdated,
  getPercentageCustomer80Sales,
  getPercentageOrderShippedLate,
  getPercentageProduct80Sales,
  getProfitDrilldownCustomer,
  getSalesDrilldownCustomer,
  getUnitsSoldDrilldownCustomer,
  getProfitDrilldownProduct,
  getSalesDrilldownProduct,
  getUnitsSoldDrilldownProduct,
  getUnitsShippedDrilldownProduct,
  getUnitsShippedDrilldownCustomer,
  getProfitDrilldownCustomer2,
getSalesDrilldownCustomer2,
getUnitsSoldDrilldownCustomer2,
getProfitDrilldownProduct2,
getSalesDrilldownProduct2,
getUnitsSoldDrilldownProduct2,
getUnitsShippedDrilldownProduct2,
getUnitsShippedDrilldownCustomer2,
getDearTopLateSuppliers,
getDearCustomerWithoutTags,
getOnDemandDearDataExtraction,
getDearTopTenSuppliersByCost,
getDearTopTenSuppliersByVolume,
getDearTopTenSuppliersByShipment,
getDearInventoryValue,
getDearGrossProfitMarginKPI,
getXeroBankTable,
getDearCostOfGoodsSold,
getDearNoSalesOrderByCarriers,
getDearSampleConversionRate,
getDearProductsWithZeroSales,
getPercentageCustomer80SalesDrilldown,
getPercentageProduct80SalesDrilldown,
getInventoryValueDrilldown,
getIncomingInventoryDrilldown,
getDearOutOfStockRate,
getDearSalesByMonthByProduct,
getDearSampleShipped,
getDearIncomingInventory,
getDearProductWithoutTags

};

export default KPIService;
